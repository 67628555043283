import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserState } from '../../helpers/GlobalUserState';
import { PREDICATE_CONTAINS, PREDICATE_EQUALS, PREDICATE_GE, PREDICATE_LE, getFilterPredicates } from '../../helpers/Predicates';
import { Accordion, Button, Divider, Group, NumberInput, Select, Stack, Title } from '@mantine/core';
import { CREATE, PAYMENT } from '../../helpers/Constants';
import { Filter, Plus, Reload, ReportMoney } from 'tabler-icons-react';
import { ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_DASHBOARD, ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT, ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT } from '../../helpers/Routes';
import { animalPaymentsDataStructure } from '../../components/ListingDataStructures';
import { Animals, Donations, PersonGroupIndividual, SinglePersonEntity } from '../../models';
import { DataStore, SortDirection } from 'aws-amplify';
import { useForm } from '../../components/Form';
import SortFieldInput from '../../components/SortFieldInput';
import SortDirectionInput from '../../components/SortDirectionInput';
import List from '../../components/List';
import SelectEntity from '../../components/SelectEntity';
import SelectAnimal from '../../components/SelectAnimal';

export default function PageAnimalHomePayments() {

    const navigate = useNavigate();
    const user = useUserState();
    const [entity, setEntity] = useState(null);
    const [animals, setAnimals] = useState(null);

    const form = useForm({
        initialValues: {
            entityId: "",
            animalId: "",
            amountMin: "",
            amountMax: "",
            expenseType: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    const getFilter = () => {
        return getFilterPredicates([
            { key: "owner", value: user.animalHomeId, type: PREDICATE_EQUALS },
            { key: "entityId", value: form.values.entityId, type: PREDICATE_CONTAINS },
            { key: "animalId", value: form.values.animalId, type: PREDICATE_CONTAINS },
            { key: "amount", value: form.values.amountMin, type: PREDICATE_GE },
            { key: "amount", value: form.values.amountMax, type: PREDICATE_LE },
            { key: "expenseType", value: form.values.expenseType, type: PREDICATE_CONTAINS },
        ]);
    }

    useEffect(() => {
        async function prepareFilters(){
            const singlePersonEntities = await DataStore.query(SinglePersonEntity, (c) => c.owner.eq(user.animalHomeId));
            const personGroupIndividuals = await DataStore.query(PersonGroupIndividual, (c) => c.owner.eq(user.animalHomeId));
            const entities = singlePersonEntities.concat(personGroupIndividuals);
            setEntity(entities.map( entity => ({
                value: entity.id,
                label: entity.lastName+" "+entity.firstName
            })));

            const animals = await DataStore.query(Animals, (c) => c.owner.eq(user.animalHomeId));
            setAnimals(animals.map( animal => ({
                value: animal.id,
                label: animal.name
            })));
        }

        prepareFilters();
    },
        [user.animalHomeId]
    );

    return (
        entity && animals &&
        <Stack>
            <Group position="apart">
                <Title>Zahlungen</Title>
                <Group>
                    <Button
                        color="green"
                        leftIcon={<Plus />}
                        onClick={() => navigate(ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_DASHBOARD)}
                    >
                        {PAYMENT} {CREATE}
                    </Button>
                </Group>
            </Group>
            <Divider />

            <Accordion variant="separated" defaultValue="forms">
                <Accordion.Item value="forms">
                    <Accordion.Control icon={<ReportMoney />}>Zahlungen</Accordion.Control>
                    <Accordion.Panel>
                        <Stack>
                            <Accordion variant="separated">
                                <Accordion.Item value="filter">
                                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                                    <Accordion.Panel>
                                        <form
                                            onReset={form.onReset}
                                        >
                                            <Stack>
                                                <SelectEntity form={form} animalhomeId={user.animalHomeId} data={entity}/>

                                                <SelectAnimal form={form} animalhomeId={user.animalHomeId} data={animals}/>

                                                <NumberInput
                                                    label="Betrag ab"
                                                    placeholder="Betrag ab"
                                                    {...form.getInputProps('amountMin')}
                                                />

                                                <NumberInput
                                                    label="Betrag bis"
                                                    placeholder="Betrag bis"
                                                    {...form.getInputProps('amountMax')}
                                                />

                                                <Select
                                                    label="Art"
                                                    placeholder="Art"
                                                    data={[
                                                        { value: "donationOnce", label: "Einmalige Spende" },
                                                        { value: "donationRecurent", label: "Wiederkehrende Spende" },
                                                        { value: "membershipFee", label: "Mitgliedsbeitrag" },
                                                        { value: "sponsorshipOnce", label: "Einmalige Patenschaft" },
                                                        { value: "sponsorshipRecurent", label: "Wiederkehrendes Sponsoring" },
                                                    ]}
                                                    {...form.getInputProps("expenseType")}
                                                    pt="xs"
                                                />

                                                <SortFieldInput
                                                    data={[
                                                        { value: 'entityId', label: 'Entität' },
                                                        { value: 'animalId', label: 'Tier' },
                                                        { value: 'amount', label: 'Betrag' },
                                                        { value: 'expenseType', label: 'Typ' },
                                                        { value: 'createdAt', label: 'Erstellzeit' },
                                                        { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                                    ]}
                                                    {...form.getInputProps('sortField')}
                                                />

                                                <SortDirectionInput
                                                    {...form.getInputProps('sortDirection')}
                                                />

                                                <Group position='apart'>
                                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                                </Group>
                                            </Stack>
                                        </form>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                            <List
                                type={Donations}
                                id="id"
                                dataStructure={animalPaymentsDataStructure}
                                filter={getFilter()}
                                sort={s => s[form.values.sortField](form.values.sortDirection)}
                                editRoute={ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT}
                                showRoute={ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT}
                                withDelete
                                isPaymentsList
                                displayPaymentsDetails
                            />
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Stack>
    )
}
