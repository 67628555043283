import { useParams } from "react-router-dom";
import * as Routes from "../../helpers/Routes";
import { PersonGroupIndividual } from "../../models";
import { PERSON_GROUP_INDIVIDUAL } from "../../helpers/Constants";
import EntityViewPageTemplate from "../../components/EntityViewPageTemplate";
import { titleSinglePersonEntity } from "../../helpers/Functions";
import { fieldsEntity } from "../../helpers/FormFieldsProcessor";

/**
 * animal home details page to create new animal home user or admin user
 * @returns JSX
 */
export default function PageAnimalHomeEntitiesViewPersonGroupIndividual() {
    const { id } = useParams();

    const personGroupIndividualFields = fieldsEntity(PersonGroupIndividual);

    const routes = {
        entityEdit: Routes.ROUTE_ANIMALHOME_PERSON_GROUP_INDIVIDUAL_EDIT,
        animalEdit: Routes.ROUTE_ANIMALHOME_ANIMALS_EDIT_ANIMAL,
        animalView: Routes.ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL,
        paymentCreate: Routes.ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_PERSON_GROUP_INDIVIDUAL,
        FORM_CHECK_ANIMALCREATE: Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_PERSON_GROUP_INDIVIDUAL,
        FORM_ADOPT_TEMPORARILYCREATE: Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL,
        FORM_ADOPT_PERMANENTLYCREATE: Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL,
        FORM_BRING_IN_ANIMALCREATE: Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_PERSON_GROUP_INDIVIDUAL,
        FORM_CHECK_ANIMALVIEW: Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYVIEW: Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYVIEW: Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALVIEW: Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL,
        FORM_CHECK_ANIMALEDIT: Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYEDIT: Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYEDIT: Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALEDIT: Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_BRING_IN_ANIMAL,
    };

    return (
        <EntityViewPageTemplate
            id={id}
            EntityType={PersonGroupIndividual}
            entityFields={personGroupIndividualFields}
            filterKey='entityId'
            routes={routes}
            title={PERSON_GROUP_INDIVIDUAL}
            getEntityTitle={titleSinglePersonEntity}
        />
    )
}