import { Accordion, Button, Group, Modal, Stack, TextInput } from '@mantine/core'
import React, { useState } from 'react'
import { ApiToken } from '../../models'
import { ApiTokenDataStructure } from '../../components/AdminDatastructure';
import { Api, Filter, Plus, Reload } from 'tabler-icons-react';
import { useForm } from '../../components/Form';
import { DataStore, SortDirection } from 'aws-amplify';
import SortFieldInput from '../../components/SortFieldInput';
import SortDirectionInput from '../../components/SortDirectionInput';
import List from '../../components/List';
import { getFilterPredicates } from '../../helpers/Predicates';
import { PREDICATE_CONTAINS } from '../../helpers/Constants';
import { ROUTE_API_EDIT } from '../../helpers/Routes';
import { nanoid } from 'nanoid'

export default function PageAdminApi() {

    const [isCreateApiModalOpen, setCreateApiModalOpen] = useState(false);

    const submitCallback = async (values) => {
        console.log(values)
        try {
            await DataStore.save(new ApiToken({ ...values}));
        } catch (error) {
            console.error('Error saving new API entry:', error);
        }

        handleCloseModal()
    }

    // form hook
    const form = useForm({
        initialValues: {
            token: "",
            animalHomeId: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    const createForm = useForm({
        initialValues: {
            token: "",
            animalHomeId: ""
        },
        submitCallback: submitCallback,
    });

    const getFilter = () => {
        return getFilterPredicates([
            { key: "token", value: form.values.token, type: PREDICATE_CONTAINS },
            { key: "animalHomeId", value: form.values.animalHomeId, type: PREDICATE_CONTAINS },
        ]);
    }

    const handleOpenModal = () => {
        setCreateApiModalOpen(true);
        createForm.setValues({
            token: nanoid(100),
            animalHomeId: ""
        });
    }

    const handleCloseModal = () => {
        createForm.reset();
        setCreateApiModalOpen(false);
    }

    return (
        <>
            <Accordion variant="separated" defaultValue="forms">
                <Accordion.Item value="forms">
                    <Accordion.Control icon={<Api />}>API Tokens</Accordion.Control>
                    <Accordion.Panel>
                        <Group mb={20}>
                            <Button color="green" leftIcon={<Plus />} onClick={() => handleOpenModal()}>Add new API Token</Button>
                        </Group>

                        <Stack>
                            <Accordion variant="separated">
                                <Accordion.Item value="filter">
                                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                                    <Accordion.Panel>
                                        <form
                                            onReset={form.onReset}
                                        >
                                            <Stack>
                                                <TextInput
                                                    label="Token"
                                                    placeholder="Token"
                                                    {...form.getInputProps('token')}
                                                />

                                                <TextInput
                                                    label="Animal Home ID"
                                                    placeholder="Animal Home ID"
                                                    {...form.getInputProps('animalHomeId')}
                                                />

                                                <SortFieldInput
                                                    data={[
                                                        { value: 'token', label: 'Token' },
                                                        { value: 'animalHomeId', label: 'Animal Home ID' },
                                                        { value: 'createdAt', label: 'Erstellzeit' },
                                                        { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                                    ]}
                                                    {...form.getInputProps('sortField')}
                                                />

                                                <SortDirectionInput
                                                    {...form.getInputProps('sortDirection')}
                                                />

                                                <Group position='apart'>
                                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                                </Group>
                                            </Stack>
                                        </form>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                            <List
                                type={ApiToken}
                                id="id"
                                dataStructure={ApiTokenDataStructure}
                                filter={getFilter()}
                                sort={s => s[form.values.sortField](form.values.sortDirection)}
                                editRoute={ROUTE_API_EDIT}
                                withDelete
                            />
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Modal opened={isCreateApiModalOpen} onClose={handleCloseModal} title="Add New API Token">
                <form onSubmit={createForm.onSubmit()}>
                    <TextInput
                        label="Token"
                        placeholder="Token"
                        {...createForm.getInputProps('token')}
                    />

                    <TextInput
                        label="Animal Home ID"
                        placeholder="Animal Home ID"
                        {...createForm.getInputProps('animalHomeId')}
                    />

                    <Group position="right" mt="md">
                        <Button type="submit">Create</Button>
                    </Group>
                </form>
            </Modal>
        </>
    )
}