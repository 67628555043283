/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hz2qvmz32k.execute-api.eu-central-1.amazonaws.com/test",
            "region": "eu-central-1"
        },
        {
            "name": "animals",
            "endpoint": "https://8ebzjfy0rj.execute-api.eu-central-1.amazonaws.com/test",
            "region": "eu-central-1"
        },
        {
            "name": "fileHandler",
            "endpoint": "https://lv0vx9ugb7.execute-api.eu-central-1.amazonaws.com/test",
            "region": "eu-central-1"
        },
        {
            "name": "pdfHandler",
            "endpoint": "https://8ttt07xdmb.execute-api.eu-central-1.amazonaws.com/test",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4ksu3qo3wjh27ltfhjp2njnp2y.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:36cc3485-37f1-4719-8def-fb98838e96ce",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_WuI1QFRVi",
    "aws_user_pools_web_client_id": "6lkst4q171eif73dnbjibmv2vg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tieverwebappb093be3a09814245b24992baf8d7f37d153220-test",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
