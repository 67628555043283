import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { ROUTE_NOT_FOUND } from "../helpers/Routes";
import { DataStore } from "aws-amplify";
import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import AnimalDocuments from "./AnimalDocuments";
import AnimalDetails from "./AnimalDetails";
import AnimalDocumentsListing from "./AnimalDocumentsListing";
import { DOCUMENTS, FINANCIAL_EARNINGS } from "../helpers/Constants";
import AnimalPaymentsListing from "./AnimalPaymentsListing";
import AnimalViewForms from "./AnimalViewForms";

export default function AnimalViewTemplate({ id, animalModel, title, animalFields, editRoute, isAdmin }) {

    // globals
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const [animal, setAnimal] = useState(null);

    const subscriptionRef = useRef(null);

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);

                subscriptionRef.current = DataStore.observeQuery(animalModel, p => p.id.eq(id)).subscribe(({ items }) => {
                    if (items.length === 0) {
                        navigate(ROUTE_NOT_FOUND);
                        return;
                    }

                    setAnimal(items[0]);
                });
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();

        return () => {
            // unsubscribe from the observeQuery on unmount
            if (subscriptionRef.current) {
                subscriptionRef.current.unsubscribe();
            }
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    return (
        animal &&
        <Stack>
            <Group position="apart">
                <Title>{title} {animal.name}</Title>
                <Group>
                    <Button
                        leftIcon={<ArrowLeft size={14} />}
                        onClick={() => navigate(-1, { replace: true })}
                        color="yellow"
                    >
                        Zurück
                    </Button>
                </Group>
            </Group>
            <Divider />

            <AnimalDetails
                animal={animal}
                animalFields={animalFields}
                editRoute={editRoute}
                isAdmin={isAdmin}
            />

            {/* payments */}
            <h4>{FINANCIAL_EARNINGS}</h4>

            <AnimalPaymentsListing 
                animalhomeId={animal.owner}
                animalId={animal.id}
                isAdmin={isAdmin}
            />

            {/* formulars */}
            <h4>Formulare</h4>

            <AnimalViewForms 
                animalhomeId={animal.owner}
                animalId={animal.id}
                isAdmin={isAdmin}
            />

            {/* documents */}
            <h4>Weitere {DOCUMENTS}</h4>

            <AnimalDocumentsListing animalId={animal.id} />

            <AnimalDocuments animal={animal} />
        </Stack>
    )
}
