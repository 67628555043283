import React from "react";
import { TextInput, Checkbox, Group, Button, Textarea, Stack, Radio } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import FileInput from "../components/FileInput";
import DatePickerInput from "../components/DatePickerInput";

import { FILE_TYPE_IMAGE, FILE_TYPE_PDF } from "../helpers/Constants";
import { PRIVATE_PROTECTION_LEVEL } from "../helpers/APIHelper";
import SelectAnimal from "../components/SelectAnimal";
import SelectEntity from "../components/SelectEntity";

export default function FormCheckAnimal({ form, animalhomeId, animals, defaultAnimalSelectValue, defaultEntitySelectValue, animalId, entityId, entities }) {

    if(animalId){
        form.values.animalId = animalId;
    }

    return (
        <Stack>
            <form onSubmit={form.onSubmit()}>
                {!animalId &&
                    <SelectAnimal form={form} animalhomeId={animalhomeId} data={animals} defaultValue={defaultAnimalSelectValue} withAsterisk={true}/>
                }

                {!entityId &&
                    <SelectEntity form={form} animalhomeId={animalhomeId} data={entities} defaultValue={defaultEntitySelectValue} withAsterisk={true}/>
                }

                {/* controll number*/}
                <TextInput label="Kontrollberichtsnummer" placeholder="Kontrollberichtsnummer" {...form.getInputProps("controllNumber")} pt="xs" />

                {/* Add the Vermittelt am (delivered on) date input */}
                <DatePickerInput label="Vermittelt am" placeholder="Vermittelt am" form={form} inputKey="deliveredOn" pt="xs" withAsterisk/>

                {/* Add the Vertragsnummer (contract number) text input */}
                <TextInput label="Vertragsnummer" placeholder="Vertragsnummer" {...form.getInputProps("contractNumber")} pt="xs" />

                <Radio.Group label="Kontrolltyp" {...form.getInputProps("inspectionType")} pt="xs" direction="column" withAsterisk>
                    <Radio value="initialInspection" label="Erstkontrolle" />
                    <Radio value="followUpInspection" label="Folgekontrolle" pt="xs"/>
                </Radio.Group>

                {/* other comments */}
                <Textarea placeholder="Sonstige Bemerkungen" label="Sonstige Bemerkungen" {...form.getInputProps("petOtherComments")} pt="xs" />
                <hr></hr>

                {/* notes */}
                <h3>Notizen</h3>

                {/* type of keeping */}
                <Checkbox.Group label="Art der Haltung" {...form.getInputProps("typeOfKeeping")} direction="column" withAsterisk>
                    <Checkbox value="houseApartment" label="Im Haus/In der wohnung" />
                    <Checkbox value="houseApartmentOutside" label="Im Haus/In der Wohnung/Im Freien " pt="xs" />
                    <Checkbox value="freeRun" label="Freilauf, Zwinger" pt="xs" />
                    <Checkbox value="water" label="Wasser" pt="xs" />
                    <Checkbox value="feed" label="Futter" pt="xs" />
                    <Checkbox value="litterbox" label="Katzentoilette" pt="xs" />
                </Checkbox.Group>

                {/* looking impression */}
                <Radio.Group label="Optischer Eindruck" {...form.getInputProps("lookingImpressions")} pt="xs" direction="column" withAsterisk>
                    <Radio value="noComplaint" label="Keine Beanstandung" />
                    <Radio value="complaint" label="Beanstandung" pt="xs"/>
                </Radio.Group>

                {/* environment */}
                <Radio.Group label="Umfeld" {...form.getInputProps("environment")} pt="xs" direction="column" withAsterisk>
                    <Radio value="noComplaint" label="Keine Beanstandung" />
                    <Radio value="complaint" label="Beanstandung" pt="xs"/>
                </Radio.Group>

                {/* the animal lives togeter with */}
                <Checkbox.Group label="Das Tier lebt zusammen mit" {...form.getInputProps("animalPartners")} pt="xs" direction="column" withAsterisk>
                    <Checkbox value="dog" label="Hund" />
                    <Checkbox value="cat" label="Katze" pt="xs"/>
                    <Checkbox value="otherAnimal" label="Sonstige Tiere" pt="xs"/>
                    <Checkbox value="noAnimals" label="Kein weiteres Tier" pt="xs"/>
                    <Checkbox value="children" label="Kinder" pt="xs"/>
                </Checkbox.Group>

                {/* behavior of the animal towords owner */}
                <Radio.Group label="Verhalten des Tieres gegenüber dem Halter" {...form.getInputProps("petBehaviorOwner")} pt="xs" direction="column" withAsterisk>
                    <Radio value="trustingFamiliar" label="Zutraulich/Vertraut" />
                    <Radio value="afraid" label="Ängstlich/Eingeschüchtert" pt="xs"/>
                </Radio.Group>

                {/* behavior of the animal towords strangers */}
                <Checkbox.Group label="Verhalten des tieres gegenüber Fremden" {...form.getInputProps("petBehaviorStrangers")} pt="xs" direction="column" withAsterisk>
                    <Checkbox value="approachesStrangers" label="Geht offen und ohne Scheu auf Fremde zu" />
                    <Checkbox value="reserved" label="Zuückhaltend/abwartend, aber nicht ängstlich" pt="xs"/>
                    <Checkbox value="fearfullyIntimidated" label="Ängstlich/eingeschüchtert" pt="xs"/>
                    <Checkbox value="aggresive" label="Agressiv/Bissig" pt="xs"/>
                </Checkbox.Group>

                {/* overall impression */}
                <Radio.Group label="Gesamteindruck" {...form.getInputProps("overallImpression")} pt="xs" direction="column" withAsterisk>
                    <Radio value="veryGood" label="Sehr gut" />
                    <Radio value="good" label="Gut" pt="xs"/>
                    <Radio value="satisfactory" label="Befriedigend" pt="xs"/>
                    <Radio value="unsatisfactory" label="Unbefriedigend" pt="xs"/>
                </Radio.Group>

                {/* needs another check */}
                <Radio.Group label="Weitere Kontrollen erforderlich" {...form.getInputProps("needsAnotherCheck")} pt="xs" direction="column" withAsterisk>
                    <Radio value="true" label="Ja" />
                    <Radio value="false" label="Nein" pt="xs"/>
                </Radio.Group>

                {/* control carried out by */}
                <TextInput
                    label="Kontrolle durchgeführt durch"
                    placeholder="Kontrolle durchgeführt durch"
                    {...form.getInputProps("controlMadeBy")}
                    pt="xs"
                    withAsterisk
                />

                {/* control carried out on date */}
                <DatePickerInput
                    label="Kontrolle durchgeführt am"
                    placeholder="Kontrolle durchgeführt am"
                    form={form}
                    inputKey="dateOfControl"
                    pt="xs"
                    withAsterisk
                />

                {/* time */}
                <TimeInput label="Uhrzeit" {...form.getInputProps("timeOfControl")} pt="xs" />

                {/* photo uploader */}
                <FileInput
                    initFiles={form.values.petImages || []}
                    multiple={true}
                    label="Bilder"
                    description="Wählen Sie Bilder zum Hochladen aus."
                    path="petImages"
                    form={form}
                    withAsterisk={false}
                    fileType={FILE_TYPE_IMAGE}
                    storagePrefix="media"
                    fileProtectionLevel={PRIVATE_PROTECTION_LEVEL}
                    identityId={animalhomeId}
                    pt="xs"
                />

                {/* scanned document uploader */}
                <FileInput
                    initFiles={form.values.scannedDocuments || []}
                    multiple={true}
                    label="Weitere Dokumente"
                    description="Wählen Sie Dokumente zum Hochladen aus."
                    path="scannedDocuments"
                    form={form}
                    withAsterisk={false}
                    fileType={FILE_TYPE_PDF}
                    storagePrefix="pdfs"
                    fileProtectionLevel={PRIVATE_PROTECTION_LEVEL}
                    identityId={animalhomeId}
                    pt="xs"
                />

                {/* pdf name*/}
                <TextInput label="Name des Dokuments" placeholder="Name des Dokuments" {...form.getInputProps("customPdfName")} pt="xs" withAsterisk/>

                <Group position="right" mt="md">
                    <Button type="submit">Speichern</Button>
                </Group>
            </form>
        </Stack>
    );
}
