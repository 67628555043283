import { useNavigate } from "react-router-dom";
import { CREATE, FORM_ADOPT_PERMANENTLY, FORM_ADOPT_TEMPORARILY, FORM_BRING_IN_ANIMAL, FORM_CHECK_ANIMAL, PAYMENT } from "../helpers/Constants";
import { ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_DASHBOARD, ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_DASHBOARD, ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_DASHBOARD, ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_DASHBOARD, ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_DASHBOARD } from "../helpers/Routes";
import { Button, Card, Container, Group, Image, SimpleGrid, Text, Title } from "@mantine/core";

export default function ActionsShortcuts() {

    const navigate = useNavigate();

    const cardStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    };

    return (
        <Container sx={{ marginLeft: 0, marginRight: 0, maxWidth: "100%" }}>
            <Group>
                <Title order={3} mb='md'>Abkürzungen</Title>
            </Group>
            <SimpleGrid
                cols={5}
                spacing="lg"
                breakpoints={[
                    { maxWidth: 'lg', cols: 3, spacing: 'md' },
                    { maxWidth: 'md', cols: 2, spacing: 'md' },
                    { maxWidth: 'sm', cols: 1, spacing: 'sm' },
                ]}
            >
                {/* followup check */}
                <Card style={cardStyle} shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section>
                        <Image
                            src="/media/images/followupCheckThumbnail.png"
                            height={160}
                            alt="followUpCheck"
                        />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                        <Text weight={500}>{FORM_CHECK_ANIMAL} {CREATE}</Text>
                    </Group>

                    <Group>
                        <Text size="sm" color="dimmed" style={{ wordWrap: 'break-word' }}>
                            Kontrollbericht erstellen
                        </Text>
                    </Group>

                    <Button 
                        variant="light"
                        color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        onClick={() => navigate(`${ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_DASHBOARD}/`)}
                    >
                        {FORM_CHECK_ANIMAL}
                    </Button>
                </Card>

                {/* adopt temporarily */}
                <Card style={cardStyle} shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section>
                        <Image
                            src="/media/images/adoptTemporarilyThumbnail.png"
                            height={160}
                            alt="adoptTemporarily"
                        />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                        <Text weight={500}>{FORM_ADOPT_TEMPORARILY} {CREATE}</Text>
                    </Group>

                    <Group>
                        <Text size="sm" color="dimmed" style={{ wordWrap: 'break-word' }}>
                            Bericht für temporäre Übernahme erstellen
                        </Text>
                    </Group>

                    <Button 
                        variant="light"
                        color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        onClick={() => navigate(`${ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_DASHBOARD}/`)}
                    >
                        {FORM_ADOPT_TEMPORARILY}
                    </Button>
                </Card>

                {/* adopt permanently */}
                <Card style={cardStyle} shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section>
                        <Image
                            src="/media/images/adoptPermanentlyThumbnail.png"
                            height={160}
                            alt="adoptPermanently"
                        />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                        <Text weight={500}>{FORM_ADOPT_PERMANENTLY} {CREATE}</Text>
                    </Group>

                    <Group>
                        <Text size="sm" color="dimmed" style={{ wordWrap: 'break-word' }}>
                            Bericht für unbefristete Adoption erstellen
                        </Text>
                    </Group>

                    <Button 
                        variant="light"
                        color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        onClick={() => navigate(`${ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_DASHBOARD}/`)}
                    >
                        {FORM_ADOPT_PERMANENTLY}
                    </Button>
                </Card>

                {/* bring in animal */}
                <Card style={cardStyle} shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section>
                        <Image
                            src="/media/images/bringInAnimalThumbnail.png"
                            height={160}
                            alt="bringInAnimal"
                        />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                        <Text weight={500}>{FORM_BRING_IN_ANIMAL} {CREATE}</Text>
                    </Group>

                    <Group>
                        <Text size="sm" color="dimmed" style={{ wordWrap: 'break-word' }}>
                            Bericht für Übernahme durch Tierheim erstellen
                        </Text>
                    </Group>

                    <Button 
                        variant="light"
                        color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        onClick={() => navigate(`${ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_DASHBOARD}/`)}
                    >
                        {FORM_BRING_IN_ANIMAL}
                    </Button>
                </Card>

                {/* donations */}
                <Card style={cardStyle} shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section>
                        <Image
                            src="/media/images/donationsThumbnail.png"
                            height={160}
                            alt="bringInAnimal"
                        />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                        <Text weight={500}>{PAYMENT} {CREATE}</Text>
                    </Group>

                    <Group>
                        <Text size="sm" color="dimmed" style={{ wordWrap: 'break-word' }}>
                            Zahlung erfassen
                        </Text>
                    </Group>

                    <Button 
                        variant="light"
                        color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        onClick={() => navigate(`${ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_DASHBOARD}/`)}
                    >
                        {PAYMENT}
                    </Button>
                </Card>
            </SimpleGrid>
        </Container>
    )
}
