import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { CREATE } from "../helpers/Constants";
import AnimalCreateTemplate from "./AnimalCreateTemplate";

/**
 * Create Form Template Page to create a new animal home form
 * @returns JSX
 */
export default function AnimalCreatePageTemplate({
    animalHomeId,
    formTitle,
    initialValues,
    viewRoute,
    FormComponent,
    validationSchema
}) {
    // globals
    const navigate = useNavigate();

    return (
        <Stack>
            <Group position="apart">
                <Title>
                    {formTitle} {CREATE}
                </Title>
                <Group>
                    <Button
                        leftIcon={<ArrowLeft size={14} />}
                        onClick={() => navigate(-1, { replace: true })}
                        color="yellow"
                    >
                        Zurück
                    </Button>
                </Group>
            </Group>
            <Divider />

            <AnimalCreateTemplate
                animalHomeId={animalHomeId}
                FormComponent={FormComponent}
                initialValues={initialValues}
                viewRoute={viewRoute}
                validationSchema={validationSchema}
            />
        </Stack>
    );
}
