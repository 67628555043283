import { useEffect } from 'react';
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_ANIMALHOME_NAME, VALIDATION_SCHEMA_PHONE, VALIDATION_SCHEMA_EMAIL } from '../../helpers/Validation';
import { Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { At, Check, File, List, Location, Phone, Reload } from "tabler-icons-react";
import { ROUTE_ANIMALHOME_DETAILS, ROUTE_NOT_FOUND, ROUTE_SUPPORT_ANIMALHOME_LISTING } from "../../helpers/Routes";
import { useForm } from "../../components/Form";
import { DataStore } from 'aws-amplify';
import { AnimalHome } from '../../models';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../../helpers/GlobalLoadingState';
import { ERROR_SHOW, useErrorDispatch } from '../../helpers/GlobalErrorState';
import { showNotification } from '@mantine/notifications';
import { useUserState } from '../../helpers/GlobalUserState';
import { USERGROUP_ADMIN, USERGROUP_SUPPORT } from '../../helpers/Constants';

// validation schema with yup
const validationSchema = Yup.object().shape({
  name: VALIDATION_SCHEMA_ANIMALHOME_NAME,
  phone: VALIDATION_SCHEMA_PHONE,
  email: VALIDATION_SCHEMA_EMAIL,
});

/**
 * implementation of support animal home new page
 * @returns JSX
 */
export default function PageSupportAnimalHomeForm() {

  // globals
  const navigate = useNavigate();
  const { id } = useParams();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();
  const user = useUserState();
  const adminOrSupport = user.userGroups.includes(USERGROUP_ADMIN) || user.userGroups.includes(USERGROUP_SUPPORT);

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);
                const item = await DataStore.query(AnimalHome, id);
                if (!item) {
                    navigate(ROUTE_NOT_FOUND);
                }

                // set data
                form.setInitialValues({
                    id: item.id,
                    owner: item.owner,
                    name: item.name,
                    phone: item.phone,
                    email: item.email,
                    street : item.street,
                    zip : item.zip,
                    city : item.city,
                    country : item.country,
                    website : item.website,
            
                });
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

  const submitCallback = async (values) => {
    try {
        // set loading
        setLoading(LOADING_SHOW);
            // save item
            if (!id) {
                // create item
                const newAnimalHome = await DataStore.save(new AnimalHome({
                    name: values.name,
                    phone: values.phone,
                    email: values.email,
                    street : values.street,
                    zip : values.zip,
                    city : values.city,
                    country : values.country,
                    website : values.website,
                }));

                await new Promise((resolve) => {
                    const subscription = DataStore.observe(AnimalHome, newAnimalHome.id).subscribe(msg => {
                        if ((msg.opType === 'INSERT' || msg.opType === 'UPDATE') && msg.element.id === newAnimalHome.id) {
                            subscription.unsubscribe();
                            resolve();
                        }
                    });
                });

                // add owner field to the AnimalHome after creation
                await DataStore.save(AnimalHome.copyOf(newAnimalHome, updated => {
                    updated.owner = newAnimalHome.id;
                }));

            } else {
                // update item
                const original = await DataStore.query(AnimalHome, id);
                await DataStore.save(AnimalHome.copyOf(original, updated => {
                    updated.name = values.name;
                    updated.phone = values.phone;
                    updated.email = values.email;
                    updated.street = values.street;
                    updated.zip = values.zip;
                    updated.city = values.city;
                    updated.country = values.country;
                    updated.website = values.website;
                }));
            }


        // show success
        showNotification({ message: "Tierheim erfolgreich gespeichert.", color: 'green', icon: <Check /> });

        // navigate to a suitable page depending on the user
        const routeToNavigateTo = adminOrSupport ? 
                                    ROUTE_SUPPORT_ANIMALHOME_LISTING :
                                    ROUTE_ANIMALHOME_DETAILS;
        navigate(routeToNavigateTo);
    }
    catch (e) {
        setError({ action: ERROR_SHOW, error: e });
    }
    finally {
        setLoading(LOADING_RESET);
    }
}
  
  /**
   * wrapper to navigate back to animal home listing
   */
  const navigateToList = () => {
    navigate(ROUTE_SUPPORT_ANIMALHOME_LISTING);
  }

  // form hook
  const form = useForm({
    validationSchema: validationSchema,
    initialValues: {
        id: "",
        name: "",
        phone: "",
        email: "",
        street : "",
        zip : "",
        city : "",
        country : "",
        website : "",
    },
    submitCallback: submitCallback
  });

  return (
    <Stack>
        <Title>{form.values.id ? "Tierheim bearbeiten" : "Tierheim anlegen"}</Title>
        <Divider />

        <form
            onSubmit={form.onSubmit()}
            onReset={form.onReset}
        >
            <Stack>
                <TextInput
                    withAsterisk
                    label="Name"
                    placeholder="Name"
                    {...form.getInputProps('name')}
                />
                <TextInput
                    withAsterisk
                    label="Straße"
                    icon={<Location size="1rem" />}
                    placeholder="Straße..."
                    {...form.getInputProps('street')}
                />
                <TextInput
                    withAsterisk
                    label="PLZ"
                    icon={<Location size="1rem" />}
                    placeholder="PLZ..."
                    {...form.getInputProps('zip')}
                />
                <TextInput
                    withAsterisk
                    label="Stadt"
                    icon={<Location size="1rem" />}
                    placeholder="Stadt..."
                    {...form.getInputProps('city')}
                />
                <TextInput
                    withAsterisk
                    label="Land"
                    icon={<Location size="1rem" />}
                    placeholder="Land..."
                    {...form.getInputProps('country')}
                />
                <TextInput
                    withAsterisk
                    label="Telefonnummer"
                    icon={<Phone size="1rem" />}
                    placeholder="+43 *** *******"
                    {...form.getInputProps('phone')}
                />
                <TextInput
                    withAsterisk
                    label="E-Mail-Adresse"
                    icon={<At size="1rem" />}
                    placeholder="deine E-Mail"
                    {...form.getInputProps('email')}
                />
                <TextInput
                    label="Website"
                    icon={<At size="1rem" />}
                    placeholder="Tierheim website"
                    {...form.getInputProps('website')}
                />

                <Divider />

                <Group position='apart'>
                    <Group>
                        <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                        {adminOrSupport && (
                            <Button leftIcon={<List size={14} />} onClick={() => navigateToList()} color="yellow">Zurück zur Liste</Button>
                        )}
                    </Group>
                    <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                </Group>
            </Stack>
        </form>
    </Stack>
)
  
}
