import { useNavigate } from "react-router-dom";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { useForm } from "./Form";
import { AnimalBreeds, AnimalRaces, Animals } from "../models";
import { DataStore } from "aws-amplify";

/**
 * Form Edit Template component to edit an animal home form
 * @returns JSX
 */
export default function AnimalEditTemplate({ id, animalHomeId, FormName, viewRoute, initialValues, FormComponent, validationSchema }) {

  // globals
  const navigate = useNavigate();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();

  const submitCallback = async (values) => {

    try {
      setLoading(LOADING_SHOW);

      const race = await DataStore.query(AnimalRaces, values.raceID);
      const breed = await DataStore.query(AnimalBreeds, values.breedID)

      // update item
      const original = await DataStore.query(Animals, id);

      const animal = await DataStore.save(Animals.copyOf(original, updated => {
        updated.tassoRegNo = values.tassoRegNo || null;
        updated.euRegNo = values.euRegNo || null;
        updated.contractNumber = values.contractNumber || null;
        updated.race = race;
        updated.breed = breed;
        updated.name = values.name || null;
        updated.color = values.color || null;
        updated.gender = values.gender || null;
        updated.castratedPet = values.castratedPet;
        updated.birthDate = values.birthDate || null;
        updated.bringInDate = values.bringInDate || null;
        updated.chip = values.chip || null;
        updated.leftEarTattoo = values.leftEarTattoo || null;
        updated.rightEarTattoo = values.rightEarTattoo || null;
        updated.otherComments = values.otherComments || null;
        updated.status = values.status || null;
        updated.leaveDate = values.leaveDate || null;
        updated.imageMain = values.imageMain || null;
        updated.imagesAdditional = values.imagesAdditional || null;
        updated.medicalHistory = values.medicalHistory || null;
        updated.behavioralAssessment = values.behavioralAssessment || null;
        updated.specialCareNeeds = values.specialCareNeeds || null;
      }));

      if(animal){
        navigate(`${viewRoute}/${animal.id}`, { replace: true });
      }
      
    } catch (error) {
      console.error("Error:", error);
      setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
    }
    finally {
      setLoading(LOADING_RESET);
    }
  };

  // creating form object
  const form = useForm({
    validationSchema: validationSchema,
    initialValues: initialValues,
    submitCallback: submitCallback
  });
  
  return (
    <FormComponent form={form} animalhomeId={animalHomeId}/>
  );
}