import React, { useEffect, useState } from 'react';
import { singleAnimalPaymentsDataStructure } from './ListingDataStructures';
import { PREDICATE_CONTAINS, PREDICATE_EQUALS, PREDICATE_GE, PREDICATE_LE, getFilterPredicates } from '../helpers/Predicates';
import { Donations, PersonGroupIndividual, SinglePersonEntity } from '../models';
import { DataStore, SortDirection } from 'aws-amplify';
import { Accordion, Button, Group, NumberInput, Select, Stack } from '@mantine/core';
import { Filter, Reload, ReportMoney } from 'tabler-icons-react';
import { ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT, ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT, ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT, ROUTE_SUPPORT_PAYMENTS_EDIT_PAYMENT } from '../helpers/Routes';
import { useForm } from './Form';
import List from './List';
import SelectEntity from './SelectEntity';
import SortFieldInput from './SortFieldInput';
import SortDirectionInput from './SortDirectionInput';

export default function AnimalPaymentsListing({ animalhomeId, animalId, isAdmin }) {

    const [entity, setEntity] = useState(null);

    const form = useForm({
        initialValues: {
            entityId: "",
            amountMin: "",
            amountMax: "",
            expenseType: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    const getFilter = () => {
        return getFilterPredicates([
            { key: "owner", value: animalhomeId, type: PREDICATE_EQUALS },
            { key: "animalId", value: animalId, type: PREDICATE_EQUALS },
            { key: "entityId", value: form.values.entityId, type: PREDICATE_CONTAINS },
            { key: "amount", value: form.values.amountMin, type: PREDICATE_GE },
            { key: "amount", value: form.values.amountMax, type: PREDICATE_LE },
            { key: "expenseType", value: form.values.expenseType, type: PREDICATE_CONTAINS },
        ]);
    }

    useEffect(() => {
        async function prepareFilters(){
            const singlePersonEntities = await DataStore.query(SinglePersonEntity, (c) => c.owner.eq(animalhomeId));
            const personGroupIndividuals = await DataStore.query(PersonGroupIndividual, (c) => c.owner.eq(animalhomeId));
            const entities = singlePersonEntities.concat(personGroupIndividuals);
            setEntity(entities.map( entity => ({
                value: entity.id,
                label: entity.lastName+" "+entity.firstName
            })));
        }

        prepareFilters();
    },
        [animalhomeId]
    );

    return (
        entity &&
        <Accordion variant="separated" defaultValue="forms">
            <Accordion.Item value="forms">
                <Accordion.Control icon={<ReportMoney />}>Zahlungen</Accordion.Control>
                <Accordion.Panel>
                    <Stack>
                        <Accordion variant="separated">
                            <Accordion.Item value="filter">
                                <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                                <Accordion.Panel>
                                    <form
                                        onReset={form.onReset}
                                    >
                                        <Stack>
                                            <SelectEntity form={form} animalhomeId={animalhomeId} data={entity}/>

                                            <NumberInput
                                                label="Betrag ab"
                                                placeholder="Betrag ab"
                                                {...form.getInputProps('amountMin')}
                                            />

                                            <NumberInput
                                                label="Betrag bis"
                                                placeholder="Betrag bis"
                                                {...form.getInputProps('amountMax')}
                                            />

                                            <Select
                                                label="Art"
                                                placeholder="Art"
                                                data={[
                                                    { value: "donationOnce", label: "Einmalige Spende" },
                                                    { value: "donationRecurent", label: "Wiederkehrende Spende" },
                                                    { value: "membershipFee", label: "Mitgliedsbeitrag" },
                                                    { value: "sponsorshipOnce", label: "Einmalige Patenschaft" },
                                                    { value: "sponsorshipRecurent", label: "Wiederkehrendes Sponsoring" },
                                                ]}
                                                {...form.getInputProps("expenseType")}
                                                pt="xs"
                                            />

                                            <SortFieldInput
                                                data={[
                                                    { value: 'entityId', label: 'Entität' },
                                                    { value: 'animalId', label: 'Tier' },
                                                    { value: 'amount', label: 'Höhe' },
                                                    { value: 'expenseType', label: 'Art' },
                                                    { value: 'createdAt', label: 'Erstellzeit' },
                                                    { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                                ]}
                                                {...form.getInputProps('sortField')}
                                            />

                                            <SortDirectionInput
                                                {...form.getInputProps('sortDirection')}
                                            />

                                            <Group position='apart'>
                                                <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                            </Group>
                                        </Stack>
                                    </form>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                        <List
                            type={Donations}
                            id="id"
                            dataStructure={singleAnimalPaymentsDataStructure}
                            filter={getFilter()}
                            sort={s => s[form.values.sortField](form.values.sortDirection)}
                            editRoute={isAdmin ? ROUTE_SUPPORT_PAYMENTS_EDIT_PAYMENT : ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT}
                            showRoute={isAdmin ? ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT : ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT}
                            withDelete
                            isPaymentsList
                        />
                    </Stack>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    )
}
