import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../../helpers/GlobalLoadingState';
import { ERROR_SHOW, useErrorDispatch } from '../../helpers/GlobalErrorState';
import { DataStore, SortDirection } from 'aws-amplify';
import { AnimalBreeds, AnimalRaces } from '../../models';
import { ROUTE_NOT_FOUND } from '../../helpers/Routes';
import { useForm } from '../../components/Form';
import { Accordion, Button, Group, Modal, Stack, TextInput, Title } from '@mantine/core';
import { ClipboardList, Filter, Plus, Reload } from 'tabler-icons-react';
import SortFieldInput from '../../components/SortFieldInput';
import SortDirectionInput from '../../components/SortDirectionInput';
import List from '../../components/List';
import { AnimalBreedsDatastructure } from '../../components/AdminDatastructure';
import { getFilterPredicates } from '../../helpers/Predicates';
import { PREDICATE_CONTAINS, PREDICATE_EQUALS } from '../../helpers/Constants';

export default function PageAdminEditRace() {

    const { id } = useParams();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();

    const [isCreateBreedModalOpen, setCreateBreedModalOpen] = useState(false);
    const [raceValues, setRaceValues] = useState(null);

    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);
    
                // call function to get falues from database and set form
                await fetchDatabase();
    
    
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    // fetch data from database
    async function fetchDatabase(){

        const raceData = await DataStore.query(AnimalRaces, id);

        if (!raceData) {
            navigate(ROUTE_NOT_FOUND);
        }

        form.setInitialValues({
          ...raceData
        })

        setRaceValues(raceData);
    }

    
    const submitCallback = async (values) => {

      try {
        setLoading(LOADING_SHOW);

        // update item
        const original = await DataStore.query(AnimalRaces, id);
        const updatedRace = await DataStore.save(AnimalRaces.copyOf(original, updated => {
            updated.label = values.label;
            updated.labelEn = values.labelEn;
        }));

        setRaceValues(updatedRace);

      } catch (error) {
        console.error("Error:", error);
        setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
      }
      finally {
        setLoading(LOADING_RESET);
      }
    };

    const submitCallbackBreed = async (values) => {
        
      try {
        setLoading(LOADING_SHOW);

        // save item with realtional model
        const race = await DataStore.query(AnimalRaces, id);
        await DataStore.save(new AnimalBreeds({
            label: values.label,
            labelEn: values.labelEn,
            race: race
        }));

        handleCloseModal();

      } catch (error) {
        console.error("Error:", error);
        setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
      }
      finally {
        setLoading(LOADING_RESET);
        handleCloseModal();
      }
    };
  
    // creating form object
    const form = useForm({
        initialValues: {
            label: "",
            labelEn: ""
        },
        submitCallback: submitCallback
    });

    const filterForm = useForm({
        initialValues: {
            id: "",
            label: "",
            labelEn: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    const breedForm = useForm({
        initialValues: {
            label: "",
            labelEn: "",
        },
        submitCallback: submitCallbackBreed
    });

    const getFilter = () => {
        return getFilterPredicates([
            { key: "id", value: filterForm.values.id, type: PREDICATE_CONTAINS },
            { key: "label", value: filterForm.values.label, type: PREDICATE_CONTAINS },
            { key: "labelEn", value: filterForm.values.labelEn, type: PREDICATE_CONTAINS },
            { key: "raceID", value: id, type: PREDICATE_EQUALS }
        ]);
    }
    const handleCloseModal = () => {
      breedForm.reset();
      setCreateBreedModalOpen(false);
    }

    return (
        form.values && raceValues&&
        <>
            <Title>Edit {raceValues.label}</Title>
            <form onSubmit={form.onSubmit()}>
                <TextInput
                    label="Label"
                    placeholder="Label"
                    {...form.getInputProps('label')}
                />

                <TextInput
                    label="Label (english)"
                    placeholder="Label (english)"
                    {...form.getInputProps('labelEn')}
                />

                

                <Group position="right" mt="md">
                    <Button type="submit">Submit</Button>
                </Group>
            </form>

            <Accordion variant="separated" defaultValue="forms" mt={15}>
            <Accordion.Item value="forms">
                <Accordion.Control icon={<ClipboardList />}>Breeds</Accordion.Control>
                <Accordion.Panel>
                    <Group mb={20}>
                        <Button color="green" leftIcon={<Plus />} onClick={() => setCreateBreedModalOpen(true)}>Add new breed</Button>
                    </Group>

                    <Stack>
                        <Accordion variant="separated">
                            <Accordion.Item value="filter">
                                <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                                <Accordion.Panel>
                                    <form
                                        onReset={filterForm.onReset}
                                    >
                                        <Stack>
                                            <TextInput
                                                label="ID"
                                                placeholder="ID"
                                                {...filterForm.getInputProps('id')}
                                            />

                                            <TextInput
                                                label="Etikett"
                                                placeholder="Etikett"
                                                {...filterForm.getInputProps('label')}
                                            />

                                            <TextInput
                                                label="Etikett eng"
                                                placeholder="Etikett eng"
                                                {...filterForm.getInputProps('labelEn')}
                                            />

                                            <SortFieldInput
                                                data={[
                                                    { value: 'id', label: 'ID' },
                                                    { value: 'label', label: 'Etikett' },
                                                    { value: 'labelEn', label: 'Etikett eng' },
                                                    { value: 'createdAt', label: 'Erstellzeit' },
                                                    { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                                ]}
                                                {...filterForm.getInputProps('sortField')}
                                            />

                                            <SortDirectionInput
                                                {...filterForm.getInputProps('sortDirection')}
                                            />

                                            <Group position='apart'>
                                                <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                            </Group>
                                        </Stack>
                                    </form>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                        <List
                            type={AnimalBreeds}
                            id="id"
                            dataStructure={AnimalBreedsDatastructure}
                            filter={getFilter()}
                            sort={s => s[filterForm.values.sortField](filterForm.values.sortDirection)}
                            editInDatabase={true}
                        />
                    </Stack>
                </Accordion.Panel>
            </Accordion.Item>
            </Accordion>


            <Modal opened={isCreateBreedModalOpen} onClose={handleCloseModal} title="Add New Breed">
                <form onSubmit={breedForm.onSubmit()}>
                    <TextInput
                        label="Label"
                        placeholder="Label"
                        {...breedForm.getInputProps('label')}
                    />

                    <TextInput
                        label="Label (english)"
                        placeholder="Label (english)"
                        {...breedForm.getInputProps('labelEn')}
                    />

                    <Group position="right" mt="md">
                        <Button type="submit">Create</Button>
                    </Group>
                </form>
            </Modal>
        </>
    )
}
