import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../../helpers/GlobalLoadingState';
import { ERROR_SHOW, useErrorDispatch } from '../../helpers/GlobalErrorState';
import { DataStore } from 'aws-amplify';
import { AnimalHome, ApiToken } from '../../models';
import { ROUTE_NOT_FOUND } from '../../helpers/Routes';
import { useForm } from '../../components/Form';
import { Button, Group, TextInput, Title } from '@mantine/core';

export default function PageAdminApiEdit() {

    const { id } = useParams();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();

    const [apiToken, setApiToken] = useState(null);
    const [animalHome, setAnimalHome] = useState(null);

    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);
    
                // call function to get falues from database and set form
                await fetchDatabase();
    
    
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    // fetch data from database
    async function fetchDatabase(){

        const apiToken = await DataStore.query(ApiToken, id);
        const animalHome = await DataStore.query(AnimalHome, apiToken.animalHomeId);

        if (!apiToken) {
            navigate(ROUTE_NOT_FOUND);
        }

        form.setInitialValues({
          ...apiToken
        })

        setApiToken(apiToken);
        setAnimalHome(animalHome);
    }

    
    const submitCallback = async (values) => {

      try {
        setLoading(LOADING_SHOW);

        const animalHome = await DataStore.query(AnimalHome, values.animalHomeId);

        // update item
        const original = await DataStore.query(ApiToken, id);
        const updatedApiToken = await DataStore.save(ApiToken.copyOf(original, updated => {
            updated.token = values.token;
            updated.animalHomeId = values.animalHomeId;
            updated.animalhome = animalHome;
        }));

        setApiToken(updatedApiToken);

        navigate(-1, { replace: true })

      } catch (error) {
        console.error("Error:", error);
        setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
      }
      finally {
        setLoading(LOADING_RESET);
      }
    };

    // creating form object
    const form = useForm({
        initialValues: {
            token: "",
            animalHomeId: ""
        },
        submitCallback: submitCallback
    });

    return (
        form.values && apiToken && animalHome &&
        <>
            <Title>Edit API Token for {animalHome.name} Animal Home</Title>
            <form onSubmit={form.onSubmit()}>
                <TextInput
                    label="Token"
                    placeholder="Token"
                    {...form.getInputProps('token')}
                />

                <TextInput
                    label="Animal Home ID"
                    placeholder="Animal Home ID"
                    {...form.getInputProps('animalHomeId')}
                />

                

                <Group position="right" mt="md">
                    <Button type="submit">Submit</Button>
                </Group>
            </form>
        </>
    )
}
