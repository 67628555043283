import { Title, Text, Button, Paper, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { USERGROUP_ADMIN, USERGROUP_ANIMALHOME_ADMIN, USERGROUP_ANIMALHOME_USER, USERGROUP_SUPPORT } from "../../helpers/Constants";
import { useUserState } from "../../helpers/GlobalUserState";
import { ROUTE_ANIMALHOME_HOME, ROUTE_SIGNIN, ROUTE_SUPPORT_HOME } from "../../helpers/Routes";

/**
 * implementation of general home page
 * @returns JSX
 */
export default function PageGeneralHome() {

    // globals
    const user = useUserState();
    const navigate = useNavigate();
    const theme = useMantineTheme();

    /** 
     * use effect hook to redirect logged in users
     */
    useEffect(() => {
        if (user.userGroups.includes(USERGROUP_SUPPORT) || user.userGroups.includes(USERGROUP_ADMIN)) {
            navigate(ROUTE_SUPPORT_HOME);
        }
        if (user.userGroups.includes(USERGROUP_ANIMALHOME_ADMIN) || user.userGroups.includes(USERGROUP_ANIMALHOME_USER)) {
            navigate(ROUTE_ANIMALHOME_HOME);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
        },
        paper: {
            padding: theme.spacing.xl,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing.md,
            backgroundColor: '#fff',
        }
    };

    if (user.id) {
        return null;
    } else {
        return (
            
            <div style={styles.wrapper}>
                <Paper style={styles.paper} radius="md" shadow="xl">
                    <Title order={1} style={{ color: '#004289' }}>Herzlich Willkommen bei TIEVER</Title>
                    <Text size="md" style={{ textAlign: 'center', marginTop: 20 }}>
                        Herzlich Willkommen bei TIEVER - hier geht's zur Anmeldung.
                    </Text>
                    <Button
                        className="sign-in-button"
                        onClick={() => navigate(ROUTE_SIGNIN)}
                    >
                        Anmelden
                    </Button>
                </Paper>
            </div>
        );
    }
}