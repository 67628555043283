export const groupEntityDataStructure = [
  { key: "name", title: "Name" },
  { key: "type", title: "Typ" },
  { key: "phone", title: "Telefonnummer", type: "phone" },
  { key: "email", title: "E-Mail-Adresse", type: "email" },
  { key: "createdAt", title: "erstellt am", type: "timestamp" },
  { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
]

export const singleEntityDataStructure = [
  { key: "firstName", title: "Vorname" },
  { key: "lastName", title: "Nachname" },
  { key: "phone", title: "Telefonnummer", type: "phone" },
  { key: "email", title: "E-Mail-Adresse", type: "email" },
  { key: "createdAt", title: "erstellt am", type: "timestamp" },
  { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
]

export const animalsDataStructure = [
  { key: "name", title: "Name" },
  { key: "race.label", title: "Tierart" },
  { key: "breed.label", title: "Rasse" },
  { key: "birthDate", title: "Geburtsdatum", type: "timestamp" },
  { key: "status", title: "Status", type: "status" },
  { key: "createdAt", title: "erstellt am", type: "timestamp" },
  { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
]

export const animalDocumentsDataStructure = [
  { key: "originalName", title: "Dateiname" },
  { key: "documentType", title: "Typ", type: "documentType" },
  { key: "documentDescription", title: "Beschreibung" },
  { key: "createdAt", title: "erstellt am", type: "timestamp" },
  { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
]

export const animalPaymentsDataStructure = [
  { key: "entityId", title: "Entität" },
  { key: "animalId", title: "Tier" },
  { key: "amount", title: "Betrag" },
  { key: "expenseType", title: "Art", type: "expenseType" },
  { key: "createdAt", title: "erstellt am", type: "timestamp" },
  { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
]

export const singleAnimalPaymentsDataStructure = [
  { key: "entityId", title: "Entität" },
  { key: "amount", title: "Betrag" },
  { key: "expenseType", title: "Art", type: "expenseType" },
  { key: "createdAt", title: "erstellt am", type: "timestamp" },
  { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
]

export const singleAnimalForms = [
  { key: "entityId", title: "Entität" },
  { key: "customPdfName", title: "Dateiname" },
  { key: "createdAt", title: "erstellt am", type: "timestamp" },
  { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
]
