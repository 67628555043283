import React, { useEffect, useState } from "react";
import { TextInput, Checkbox, Group, Button, Select, Textarea, Stack } from "@mantine/core";
import DatePickerInput from "../components/DatePickerInput";
import FileInput from "../components/FileInput";
import { FILE_TYPE_IMAGE } from "../helpers/Constants";
import { PRIVATE_PROTECTION_LEVEL } from "../helpers/APIHelper";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { AnimalBreeds, AnimalRaces } from "../models";


export default function FormAnimal({ form, animalhomeId }) {

    const [races, setRaces] = useState(null);
    const [race, setRace] = useState(null);
    const [breeds, setBreeds] = useState([]);

    useEffect(() => {
        async function fetchOptions(){
            const races = await DataStore.query(AnimalRaces, Predicates.ALL,{
                sort: (s) => s.label(SortDirection.ASCENDING)
            });
            setRaces(races)

            if(form.values.raceID){
                const breeds = await DataStore.query(AnimalBreeds, (c) => c.raceID.eq(form.values.raceID), {
                    sort: (c) => c.label(SortDirection.ASCENDING)
                });
                setBreeds(breeds)
            }

        }

        fetchOptions()
    }, [form.values.raceID])

    async function getBreeds(raceId) {
        setBreeds([]);
        form.setValues({
            ...form.values,
            breedID: '',
        });

        const breeds = await DataStore.query(AnimalBreeds, (c) => c.raceID.eq(raceId), {
            sort: (c) => c.label(SortDirection.ASCENDING)
        });
        setRace(raceId);
        form.setValues({
            ...form.values,
            raceID: raceId,
        });
        if(breeds){
            setBreeds(breeds);
        }
    }

    return (
        races &&
        <Stack>
            <form onSubmit={form.onSubmit()}>
                {/* Tasso registration number */}
                <TextInput
                    label="Tasso-Registrierungsnummer"
                    placeholder="Tasso-Registrierungsnummer"
                    {...form.getInputProps("tassoRegNo")}
                    pt="xs"
                />

                {/* EU registration number */}
                <TextInput
                    label="EU-Ausweisnummer"
                    placeholder="EU-Ausweisnummer"
                    {...form.getInputProps("euRegNo")}
                    pt="xs"
                />

                {/* contract number */}
                <TextInput label="Vertragsnummer" placeholder="Vertragsnummer" {...form.getInputProps("contractNumber")} pt="xs" />
                
                {/* race */}
                <Select
                    label="Tierart"
                    placeholder="Tierart"
                    data={
                        races.map(race => ({
                            value: race.id,
                            label: race.label
                        }))
                    }
                    {...form.getInputProps("raceID")}
                    value={race || form.values.raceID || ''}
                    onChange={(e) => getBreeds(e)}
                    pt="xs"
                    withAsterisk
                />

                {/* breed */}
                <Select
                    label="Rasse"
                    placeholder="Rasse"
                    data={
                        breeds.map(breed => ({
                            value: breed.id,
                            label: breed.label
                        }))
                    }
                    {...form.getInputProps("breedID")}
                    value={form.values.breedID || ''}
                    pt="xs"
                    withAsterisk
                />

                {/* pet name */}
                <TextInput label="Name" placeholder="Name" {...form.getInputProps("name")} pt="xs" withAsterisk/>

                {/* pet color */}
                <TextInput label="Farbe" placeholder="Farbe" {...form.getInputProps("color")} pt="xs" withAsterisk/>

                {/* pet gender */}
                <Select
                    label="Geschlecht"
                    placeholder="Geschlecht"
                    data={[
                        { value: "m", label: "Männlich" },
                        { value: "f", label: "Weiblich" },
                        { value: "o", label: "Unbekannt" },
                    ]}
                    {...form.getInputProps("gender")}
                    pt="xs"
                    withAsterisk
                />

                {/* castrated/neutered */}
                <Checkbox checked={form.values.castratedPet} label="Kastriert" {...form.getInputProps("castratedPet")} pt="xs" />

                {/* pet bithdate */}
                <DatePickerInput label="Geburtsdatum" placeholder="Geburtsdatum" form={form} inputKey="birthDate" pt="xs" withAsterisk/>

                {/* bring in date */}
                <DatePickerInput label="Eingangsdatum" placeholder="Eingangsdatum" form={form} inputKey="bringInDate" pt="xs" withAsterisk/>

                {/* pet chip*/}
                <TextInput label="Chipnummer" placeholder="Chipnummer" {...form.getInputProps("chip")} pt="xs" />

                {/* pet left ear tattoo*/}
                <TextInput label="Tattoo Ohr links" placeholder="Tattoo Ohr links" {...form.getInputProps("leftEarTattoo")} pt="xs" />

                {/* pet right ear tattoo*/}
                <TextInput label="Tattoo Ohr Rechts" placeholder="Tattoo Ohr Rechts" {...form.getInputProps("rightEarTattoo")} pt="xs" />

                {/* other comments */}
                <Textarea placeholder="Sonstige Bemerkungen" label="Sonstige Bemerkungen" {...form.getInputProps("otherComments")} pt="xs" />

                {/* pet status */}
                <Select
                    label="Status"
                    placeholder="Status"
                    data={[
                        { value: "inShelterAdoptionAvailable", label: "Im Tierheim, zur Adoption verfügbar" },
                        { value: "inShelterAdoptionNotAvailable", label: "Im Tierheim, nicht zur Adoption verfügbar" },
                        { value: "temporarilyAdopted", label: "Vorübergehend übernommen" },
                        { value: "permanentlyAdopted", label: "Dauerhaft übernommen" },
                        { value: "atVet", label: "Tier beim Tierarzt" },
                        { value: "deadAnimal", label: "Verstorben" },
                    ]}
                    {...form.getInputProps("status")}
                    pt="xs"
                    withAsterisk
                />
                {/* leave date */}
                <DatePickerInput label="Ausgangsdatum" placeholder="Ausgangsdatum" form={form} inputKey="leaveDate" pt="xs" />

                {/* medical history */}
                <Textarea placeholder="Krankengeschichte" label="Krankengeschichte" {...form.getInputProps("medicalHistory")} pt="xs" />

                {/* behavioral assessment */}
                <Textarea placeholder="Verhaltensbeurteilung" label="Verhaltensbeurteilung" {...form.getInputProps("behavioralAssessment")} pt="xs" />

                {/* special care needs */}
                <Textarea
                    placeholder="Besondere Pflegebedürfnisse"
                    label="Besondere Pflegebedürfnisse"
                    {...form.getInputProps("specialCareNeeds")}
                    pt="xs"
                />

                <h4>Fotos</h4>

                {/* profile picture uploader */}
                <FileInput
                    initFiles={form.values.imageMain || ''}
                    multiple={false}
                    label="Bild"
                    description="Wählen Sie Bilder zum Hochladen aus."
                    path="imageMain"
                    form={form}
                    withAsterisk={false}
                    fileType={FILE_TYPE_IMAGE}
                    storagePrefix="media/animalProfilePicture"
                    fileProtectionLevel={PRIVATE_PROTECTION_LEVEL}
                    identityId={animalhomeId}
                    pt="xs"
                />

                {/* additional pictures */}
                <FileInput
                    initFiles={form.values.imagesAdditional || []}
                    multiple={true}
                    label="Weitere Bilder"
                    description="Wählen Sie Bilder zum Hochladen aus."
                    path="imagesAdditional"
                    form={form}
                    withAsterisk={false}
                    fileType={FILE_TYPE_IMAGE}
                    storagePrefix="media"
                    fileProtectionLevel={PRIVATE_PROTECTION_LEVEL}
                    identityId={animalhomeId}
                    pt="xs"
                />

                <Group position="right" mt="md">
                    <Button type="submit">Speichern</Button>
                </Group>
            </form>
        </Stack>
    );
}
