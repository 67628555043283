import { Text, Button, Center, Stack, Title, Paper, TextInput, PasswordInput, Container } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth, DataStore } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORD, VALIDATION_SCHEMA_PASSWORD_CONFIRMATION, VALIDATION_SCHEMA_PASSWORT_REQUIRED } from "../../helpers/Validation";
import { useForm } from "../../components/Form";
import Broadcaster, { BC_TYPE_USERCHANGE } from "../../components/Broadcaster";
import { ROUTE_HOME, ROUTE_SIGNIN } from "../../helpers/Routes";
import { clearDataStore } from "../../helpers/Datastore";

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    oldPassword: VALIDATION_SCHEMA_PASSWORT_REQUIRED,
    password: VALIDATION_SCHEMA_PASSWORD,
    passwordConfirm: VALIDATION_SCHEMA_PASSWORD_CONFIRMATION,
});

/**
 * component to handle password change
 * @returns JSX
 */
export default function PageGeneralChangePassword(props) {

    // globals
    const navigate = useNavigate();
    const { email } = useParams();

    // submit callback
    const submitCallback = async (values) => {
        try {
            Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: "new" });
            const loginResult = await Auth.signIn(values.email.toLowerCase(), values.oldPassword);

            if (loginResult.challengeName === "NEW_PASSWORD_REQUIRED") {
                await Auth.completeNewPassword(loginResult, values.password);
                showNotification({ message: "Passwort geändert.", color: 'green', icon: <Check /> });
                await clearDataStore();
                await DataStore.start();
            }
            else {
                showNotification({ message: "Keine Passwortänderung ausständig. Wenn Sie ein neues Passwort vergeben möchten, können Sie ihr Passwort zurücksetzen.", color: 'red', icon: <X /> });
            }
            navigate(`${ROUTE_HOME}`);
        }
        catch (e) {
            throw e;
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: email ? email : "",
            oldPassword: "",
            password: "",
            passwordConfirm: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100%" }}>
            <Container size={600} style={{ width: "600px" }}>
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center">Passwort ändern.</Title>
                        <Link to={ROUTE_SIGNIN}>
                            <Text className="pointer" underline color="dimmed" size="sm" align="center" mb="md">
                                Zurück zur Anmeldung
                            </Text>
                        </Link>

                        <Stack>
                            <TextInput
                                withAsterisk
                                label="E-Mail-Adresse"
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                                disabled={email}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Aktuelles Passwort"
                                placeholder="Aktuelles Passwort"
                                {...form.getInputProps('oldPassword')}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Neues Passwort"
                                placeholder="Neues Passwort"
                                {...form.getInputProps('password')}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Neues Passwort bestätigen"
                                placeholder="Neues Passwort"
                                {...form.getInputProps('passwordConfirm')}
                            />
                        </Stack>
                        <Button type="submit" fullWidth mt="xl">Passwort zurücksetzen</Button>
                    </Paper>
                </form>
            </Container>
        </Center>
    );
}