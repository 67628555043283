import { useParams } from "react-router-dom";
import { Animals, FormBringInAnimal as FormBringInAnimalModel} from "../../models";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL } from "../../helpers/Routes";
import { FORM_BRING_IN_ANIMAL, PDF_BRING_IN_ANIMAL } from "../../helpers/Constants";
import FormBringInAnimal from "../../formulars/FormBringInAnimal";
import FormEditPageTemplate from "../../components/FormEditPageTemplate";
import { validationForBringInAnimalDashboard } from "../../helpers/FormValidations";

/**
 * Page used to Edit a Bring in Animal form 
 * @returns JSX
 */
export default function PageAnimalHomeFormsEditBringInAnimal() {

  const { id } = useParams();

  return (
    <FormEditPageTemplate
      id={id}
      pdfName={PDF_BRING_IN_ANIMAL}
      formTitle={FORM_BRING_IN_ANIMAL}
      animalsTypeModel={Animals}
      FormModel={FormBringInAnimalModel}
      FormComponent={FormBringInAnimal}
      viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL}
      validationSchema={validationForBringInAnimalDashboard}
    />
  );
}