import { useParams } from "react-router-dom";
import { Animals} from "../../models";
import { ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL } from "../../helpers/Routes";
import { ANIMAL } from "../../helpers/Constants";
import AnimalEditPageTemplate from "../../components/AnimalEditPageTemplate";
import FormAnimal from "../../formulars/FormAnimal";
import { animalsValidationSchema } from "../../helpers/FormValidations";

/**
 * Page used to Edit a Bring in Animal form 
 * @returns JSX
 */
export default function PageAnimalHomeAnimalsEditAnimal() {

  const { id } = useParams();

  return (
    <AnimalEditPageTemplate
      id={id}
      title={ANIMAL}
      viewRoute={ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL}
      AnimalModel={Animals}
      FormComponent={FormAnimal}
      validationSchema={animalsValidationSchema}
    />
  );
}