import React from 'react';
import { useForm } from "./Form.js";
import { useNavigate } from 'react-router-dom';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState.js';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState.js';
import { DataStore } from 'aws-amplify';
import { Actions } from '../models';
import { ACTION_DONATION } from '../helpers/Constants.js';

export default function PaymentCreateTemplate({ animalHomeId, entityId, animals, FormName, viewRoute, initialValues, FormComponent, animalId, entities, defaultEntitySelectValue, validationSchema }) {

    // globals
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();

    const submitCallback = async (values) => {

        values.owner = animalHomeId;

        try {
            setLoading(LOADING_SHOW);

            // create new payment
            const payment = await DataStore.save(new FormName({
                expenseType: values.expenseType,
                amount: values.amount,
                expensePeriod: values.expensePeriod,
                startDate: values.startDate,
                endDate: values.endDate ? values.endDate : null,
                entityId: values.entityId,
                animalId: values.animalId ? values.animalId : null,
                scannedDocuments: values.scannedDocuments ? values.scannedDocuments : null,
                owner: values.owner
            }));

            // redirect
            if(payment){

                if(payment.animalId){
                    // save action in database
                    await DataStore.save(new Actions({
                        owner: values.owner,
                        animalId: values.animalId,
                        entityId: values.entityId,
                        actionType: ACTION_DONATION,
                        formId: payment.id
                    }));
                }

                navigate(`${viewRoute}/${payment.id}`);
            }
        } catch (error) {
            console.error('Error:', error);
            setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    };

    // creating form object
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: initialValues,
        submitCallback: submitCallback
    });

    return (
        <FormComponent 
            form={form}
            animalhomeId={animalHomeId}
            entities={entities || []}
            entityId={entityId}
            animals={animals || []}
            animalId={animalId}
            defaultEntitySelectValue={defaultEntitySelectValue}
        />
    )
}
