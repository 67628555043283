import { schema } from "../models/schema";

/**
 * A funtion to get the fields from a schema model
 * @param {MutableModel} model the schema model type to get the fields from
 * @returns {string[]} the array of strings containing the processed model fields
 */
export function fieldsModel(model) {
  const fields = Object.keys(schema.models[model.name].fields);
  return fields.filter(field => !["id", "owner", "createdAt", "updatedAt"].includes(field));
}

/**
 * A funtion to get the fields for an entity
 * @param {MutableModel} model the schema model type to get the fields from
 * @returns {string[]} the array of strings containing the processed model fields
 */
export function fieldsEntity(model) {
  const fields = fieldsModel(model);
  return fields.filter(field => !["animalHome", "status", "individuals", "entityGroupId"].includes(field));
}

/**
 * A funtion to get the fields for a anmial home form
 * @param {MutableModel} model the schema model type to get the fields from
 * @returns {string[]} the array of strings containing the processed model fields
 */
export function fieldsForm(model) {
  const fields = fieldsModel(model);
  return fields.filter(field => !["entityId", "fileName", "entityGroupId", "race", "breed", "customPdfNameNormalized"].includes(field));
}

/**
 * A funtion to get the fields for an anmial home
 * @param {MutableModel} model the schema model type to get the fields from
 * @returns {string[]} the array of strings containing the processed model fields
 */
export function fieldsAnimalHome(model) {
  const fields = fieldsModel(model);
  return fields.filter(field => !["tokenId", "tokens"].includes(field));
}

/**
 * Creates an object from an array of string values
 * @param {string[]} modelFields the array of schema model fields
 * @param {MutableModel} modelData the object containing the model data
 * @returns {object} the object containing the initial values to be used when creating a form
 */
export function initialValues(modelFields, modelData) {
  let initialValues = {};
  modelFields.forEach(field => {
    initialValues[field] = modelData ? modelData[field] : '';
  });
  return initialValues;
}

/**
 * Sets the initial falues from a schema model type
 * @param {MutableModel} model the schema model type to get the initial values from
 * @returns {object} the object containing the initial values
 */
export function initialValuesModel(model) {
  const modelFields = fieldsModel(model);
  return initialValues(modelFields);
}

/**
 * Sets the initial falues for an Entity from a schema model type
 * @param {MutableModel} model the schema model type to get the initial values from
 * @returns {object} the object containing the initial values
 */
export function initialValuesEntity(model) {
  const entityFields = fieldsEntity(model);
  return initialValues(entityFields);
}

/**
 * Sets the initial falues for a Form from a schema model type
 * @param {MutableModel} formModel the schema model type to get the initial values from
 * @param {MutableModel} formData the object containing the form data
 * @returns {object} the object containing the initial values
 */
export function initialValuesForm(formModel, formData) {
  const formFields = fieldsForm(formModel);
  return initialValues(formFields, formData);
}
