import React, { useCallback, useEffect, useState } from 'react';
import Avatar from './Avatar';
import { ActionIcon, Card, Flex, Modal, Avatar as MantineAvatar, Loader } from '@mantine/core';
import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel';
import { Download, File } from 'tabler-icons-react';
import { API_FILE_NAME, API_POST, FILE_API_URL_DOWNLOAD_ALL, PRIVATE_PROTECTION_LEVEL, executeApiCall } from '../helpers/APIHelper';
import { downloadFileFromUrl, getSingleFileUrl} from '../helpers/Files';

export default function DisplayImagesAndDocumentsInView({ imageMain, petImages, scannedDocuments, owner }) {

    const TRANSITION_DURATION = 200;
    const [modalOpened, setModalOpened] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const [initialImageUrls, setInitialImageUrls] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [embla, setEmbla] = useState(null);
    const [mainImageUrl, setMainImageUrl] = useState('');
    const [isMainImageModalOpen, setIsMainImageModalOpen] = useState(false);
    
    const AVATAR_SIZE = 150;

    useAnimationOffsetEffect(embla, TRANSITION_DURATION);

    const handleMainImageClick = () => {
        setIsMainImageModalOpen(true);
    };

    // fetches all image URLS from the API
    const getAllImagesUrl = useCallback(async () => {
        try {
            return await executeApiCall(API_FILE_NAME, API_POST, FILE_API_URL_DOWNLOAD_ALL, {
                protectionLevel: PRIVATE_PROTECTION_LEVEL,
                identityId: owner,
                keys: petImages,
            });
        } catch (error) {
            console.error("Error loading image url", error);
        }
    }, [owner, petImages]);

    // ensures that the URL is fetched before opening the modal
    const handleImageClick = async (index) => {
        try {
            // set the active slide and open the modal
            setActiveSlide(index);
            setModalOpened(true);
    
        } catch (error) {
            console.error("Error loading image URLs", error);
        }
    };

    // handles the download of a file and prevents opening the modal
    const downloadFile = async (fileKey, e) => {
        e.stopPropagation();
        try {
            const url = await getSingleFileUrl(fileKey, owner);
            await downloadFileFromUrl(url, fileKey);
        } catch (error) {
            console.error('Error while downloading the file:', error);
        }
    };

    useEffect(() => {
        let isSubscribed = true; // flag to track mounted state

        if (isSubscribed && petImages?.length > 0) {

            const fetchAndCombineImageUrls = async () => {
                try {
                    const combinedData = await getAllImagesUrl();
                    setInitialImageUrls(combinedData);
                    setImageUrls(combinedData.map(data => data.signedUrl));
                } catch (error) {
                    console.error("Error fetching signed URLs", error);
                }               
            };
            
            fetchAndCombineImageUrls();
        }

        return () => { isSubscribed = false; }; // cleanup function to avoid setting state on unmounted component
    }, [petImages, getAllImagesUrl]);

    useEffect(() => {
        if (modalOpened && embla) {
            const timeoutId = setTimeout(() => {
                // reinitialize Embla to make sure it's updated with any possible layout changes
                embla.reInit();
                // navigate to the active slide with no animation for an immediate transition
                embla.scrollTo(activeSlide, false);
            }, TRANSITION_DURATION); // a small delay to allow the UI to stabilize
    
            // clean up the timeout if the component is unmounted
            return () => clearTimeout(timeoutId);
        }
    }, [modalOpened, activeSlide, embla]);

    // handle the url for the main image
    useEffect(() => {
        if (imageMain) {
            const fetchMainImageUrl = async () => {
                try {
                    const url = await getSingleFileUrl(imageMain, owner);
                    setMainImageUrl(url);
                } catch (error) {
                    console.error("Error fetching main image URL", error);
                }
            };

            fetchMainImageUrl();
        }
    }, [imageMain, owner]);

    return (
        <Flex
            align="flex-start"
            direction="row"
            wrap="wrap"
            gap="xs"
            pb={(petImages?.length > 0 || scannedDocuments?.length > 0 || imageMain) ? "xs" : 0}
        >
            {/* main image */}
            {imageMain && (
                <Card withBorder p="xs" onClick={() => handleMainImageClick()} style={{ cursor: 'pointer' }}>
                    {mainImageUrl ? (
                        <MantineAvatar size={AVATAR_SIZE} radius="md" src={mainImageUrl} />
                    ) : (
                        <Loader size={AVATAR_SIZE} />
                    )}
                </Card>
            )}

            {/* other images */}
            {petImages && petImages.map((_, index) => (
                <Card withBorder p="xs" key={index} onClick={() => handleImageClick(index)} style={{ cursor: 'pointer' }}>
                    {initialImageUrls.length > index ? (
                        <>
                            <ActionIcon onClick={(e) => downloadFile(petImages[index], e)} variant="outline" color="blue" size={20} mb="xs" ml="auto"><Download /></ActionIcon>
                            <MantineAvatar size={AVATAR_SIZE} radius="md" src={initialImageUrls[index].signedUrl} />
                        </>
                    ) : (
                        <Loader size={AVATAR_SIZE} />
                    )}
                </Card>
            ))}

            {/* documents */}
            {scannedDocuments?.map((documentFileName) => (
                <Card withBorder p="xs" key={documentFileName}>
                    <ActionIcon onClick={(e) => downloadFile(documentFileName, e)} variant="outline" color="blue" size={20} mb="xs" ml="auto"><Download /></ActionIcon>
                    <Avatar
                        key={documentFileName}
                        placeholder={File}
                    />
                </Card>
            ))}

            {/* modal for main image */}
            <Modal
                opened={isMainImageModalOpen}
                onClose={() => setIsMainImageModalOpen(false)}
                size="500px"
                padding={10}
                transitionProps={{ duration: TRANSITION_DURATION }}
                withCloseButton={true}
                keepMounted={true}
            >
                <img
                    src={mainImageUrl}
                    alt="profileImage"
                    style={{ width: "100%", height: "100%", objectFit: 'contain' }}
                />
            </Modal>

            {/* modal for other images */}
            <Modal
                opened={modalOpened}
                onClose={() => setModalOpened(false)}
                size="500px"
                padding={10}
                transitionProps={{ duration: TRANSITION_DURATION }}
                withCloseButton={true}
                keepMounted={true}
            >
                <Carousel
                    loop
                    getEmblaApi={setEmbla}
                    slideSize="100%"
                    slideGap="md"
                    slidesToScroll={1}
                    initialSlide={activeSlide}
                    withIndicators
                >
                    {petImages?.map((imageFileName, index) => (
                        <Carousel.Slide key={imageFileName}>
                            <img
                                src={imageUrls[index]} // Access the URL by index
                                alt={`Slide ${imageFileName}`}
                                style={{ width: "100%", height: "100%", objectFit: 'contain' }}
                            />
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Modal>
        </Flex>
    )
}
