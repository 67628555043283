
// predicates
export const PREDICATE_CONTAINS = "CONTAINS";
export const PREDICATE_EQUALS = "EQ";
export const PREDICATE_GE = "GE";
export const PREDICATE_LT = "LT";
export const PREDICATE_LE = "LE";

/**
 * builds a predicate to filter with
 * @param {array} elements array of elements to build predicates for
 * @returns 
 */
export const getFilterPredicates = (elements) => {
    // filter predicates that have values set
    var filteredElements = elements.filter(e => e.value);

    // check if values are set to build predicates for
    if (filteredElements.length === 0) {
        // no values set, return all predicate
        return null;
    }

    // if values are set, build predicates for the values
    const predicate = p => filteredElements.map(e => {
        return getFilterPredicate(p, e);
    });

    // return array of predicates
    return predicate;
}

/**
 * builds a predicate line
 * @param {object} p the predicate
 * @param {object} element the element to build one predicate statement for
 * @returns 
 */
const getFilterPredicate = (p, element) => {
    var path = getPredicatePath(p, element.key);

    const pathExeptions = ['race', 'breed'];

    switch (element.type) {
        case PREDICATE_CONTAINS:
            if(pathExeptions.includes(element.key)){
                return path.label.contains(element.value);
            }
            return path.contains(element.value);
        case PREDICATE_EQUALS:
            return path.eq(element.value);
        case PREDICATE_GE:
            return path.ge(element.value);
        case PREDICATE_LT:
            return path.lt(element.value);
        case PREDICATE_LE:
            return path.le(element.value);
        default:
            throw new Error("predicate type invalid");
    }
}

/**
 * recursive function to get predicate for objects within objects
 * @param {object} p predicate
 * @param {string} key key string, separated by '.'
 * @returns predicate
 */
const getPredicatePath = (p, key) => {
    var index = key.indexOf('.');
    if (index > -1) {
        var newKey = key.substring(index + 1);
        return getPredicatePath(p[key.substring(0, index)], newKey);
    }

    return p[key];
}