import * as Yup from 'yup';
import { OPTIONS_YESNO_WITHALL, USERGROUPS_ANIMALHOME } from './Constants';

// validation error messages
export const VALIDATION_ERROR_EMAIL = "E-Mail-Adresse ungültig.";
export const VALIDATION_ERROR_PASSWORD_REQUIRED = "Geben Sie Ihr persönliches Passwort ein.";
export const VALIDATION_ERROR_PASSWORD_REQUIREMENTS = "Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Zahl enthalten.";
export const VALIDATION_ERROR_PASSWORD_CONFIRMATION_REQUIRED = "Geben Sie das Passwort zur Bestätigung erneut ein.";
export const VALIDATION_ERROR_PASSWORD_CONFIRMATION_INVALID = "Passwörter stimmen nicht überein.";
export const VALIDATION_ERROR_2FA_CODE = "Geben Sie den erhaltenen Bestätigungscode ein.";
export const VALIDATION_ERROR_ANIMALHOME_NAME = "Bitte geben Sie den Namen Ihres Tierheims ein.";
export const VALIDATION_ERROR_NAME = "Bitte geben Sie Ihren Namen (Titel Vorname Nachname) ein.";
export const VALIDATION_ERROR_NUMBER = "Bitte wählen Sie eine gültige Zahl, 0 oder größer.";
export const VALIDATION_ERROR_DATE = "Bitte wählen Sie ein gültiges Datum.";
export const VALIDATION_ERROR_USERGROUP = "Wählen Sie eine Berechtigungsgruppe.";
export const VALIDATION_ERROR_OPTIONS_YESNO = "Wählen Sie eine Option.";
export const VALIDATION_ERROR_PHONE = "Bitte geben Sie den Telefonnummer Ihres Tierheims ein.";
export const VALIDATION_ERROR_ADDRESS = "Bitte geben Sie den Adresse Ihres Tierheims ein.";
export const VALIDATION_ERROR_GROUP_ENTITY_TYPE = "Bitte geben Sie den Entitätstyp der Personengruppe (z. B. Verein, Amt, Unternehmen) ein.";

// person group individual
export const VALIDATION_ERROR_SALUTATION = "Anrede ungültig.";
export const VALIDATION_ERROR_TITLE = "Ungültiger Titel.";
export const VALIDATION_ERROR_FIRST_NAME = "Falscher oder fehlender Vorname.";
export const VALIDATION_ERROR_LAST_NAME = "Falscher oder fehlender Name.";
export const VALIDATION_ERROR_ROLE = "Ungültige oder fehlende Rolle.";
export const VALIDATION_ERROR_INSTITUTION = "Der Name der Institution ist ungültig oder fehlt.";


// templates for form validation
export const VALIDATION_SCHEMA_EMAIL = Yup.string().required(VALIDATION_ERROR_EMAIL).email(VALIDATION_ERROR_EMAIL);
export const VALIDATION_SCHEMA_PASSWORD = Yup.string().required(VALIDATION_ERROR_PASSWORD_REQUIRED).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, VALIDATION_ERROR_PASSWORD_REQUIREMENTS);
export const VALIDATION_SCHEMA_PASSWORT_REQUIRED = Yup.string().required(VALIDATION_ERROR_PASSWORD_REQUIRED);
export const VALIDATION_SCHEMA_PASSWORD_CONFIRMATION = Yup.string().required(VALIDATION_ERROR_PASSWORD_CONFIRMATION_REQUIRED).oneOf([Yup.ref('password'), null], VALIDATION_ERROR_PASSWORD_CONFIRMATION_INVALID);
export const VALIDATION_SCHEMA_2FA_CODE = Yup.string().required(VALIDATION_ERROR_2FA_CODE);
export const VALIDATION_SCHEMA_ANIMALHOME_NAME = Yup.string().required(VALIDATION_ERROR_ANIMALHOME_NAME);
export const VALIDATION_SCHEMA_NAME = Yup.string().required(VALIDATION_ERROR_NAME);
export const VALIDATION_SCHEMA_NUMBER = Yup.number(VALIDATION_ERROR_NUMBER).typeError(VALIDATION_ERROR_NUMBER).min(0, VALIDATION_ERROR_NUMBER).required(VALIDATION_ERROR_NUMBER);
export const VALIDATION_SCHEMA_DATE = Yup.string(VALIDATION_ERROR_DATE).matches(/^\d{4}(\.|-)\d{2}(\.|-)\d{2}$/, VALIDATION_ERROR_DATE).required(VALIDATION_ERROR_DATE);
export const VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL = Yup.mixed().oneOf(OPTIONS_YESNO_WITHALL, VALIDATION_ERROR_OPTIONS_YESNO).required(VALIDATION_ERROR_OPTIONS_YESNO);
export const VALIDATION_SCHEMA_USERGROUP_ANIMALHOME = Yup.mixed().oneOf(USERGROUPS_ANIMALHOME, VALIDATION_ERROR_USERGROUP).required(VALIDATION_ERROR_USERGROUP);
export const VALIDATION_SCHEMA_PHONE = Yup.string().required(VALIDATION_ERROR_PHONE);
export const VALIDATION_SCHEMA_ADDRESS = Yup.string().required(VALIDATION_ERROR_ADDRESS);
export const VALIDATION_SCHEMA_GROUP_ENTITY_TYPE = Yup.string().required(VALIDATION_ERROR_GROUP_ENTITY_TYPE);

export const VALIDATION_SCHEMA_SALUTATION = Yup.string().required(VALIDATION_ERROR_SALUTATION);
export const VALIDATION_SCHEMA_TITLE = Yup.string().required(VALIDATION_ERROR_TITLE);
export const VALIDATION_SCHEMA_FIRST_NAME = Yup.string().required(VALIDATION_ERROR_FIRST_NAME);
export const VALIDATION_SCHEMA_LAST_NAME = Yup.string().required(VALIDATION_ERROR_LAST_NAME);
export const VALIDATION_SCHEMA_ROLE = Yup.string().required(VALIDATION_ERROR_ROLE);
export const VALIDATION_SCHEMA_INSTITUTION = Yup.string().required(VALIDATION_ERROR_INSTITUTION);
