import React from "react";
import { Animals } from "../../models";
import { ANIMAL } from "../../helpers/Constants";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import FormAnimal from "../../formulars/FormAnimal";
import { ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import AnimalCreatePageTemplate from "../../components/AnimalCreatePageTemplate";
import { animalsValidationSchema } from "../../helpers/FormValidations";

export default function PageAnimalHomeAnimalsCreateAnimal() {
    const user = useUserState();

    return (
        <AnimalCreatePageTemplate
            formTitle={`${ANIMAL}`}
            FormModel={Animals}
            FormComponent={FormAnimal}
            viewRoute={ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL}
            animalHomeId={user.animalHomeId}
            initialValues={initialValuesForm(Animals)}
            validationSchema={animalsValidationSchema}
        />
    );
}
