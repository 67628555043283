import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SortDirection } from "aws-amplify";
import { Plus, User, Users } from "tabler-icons-react";
import { Accordion, Button, Divider, Group, Stack, Title, SegmentedControl } from "@mantine/core";
import { CREATE } from "../../helpers/Constants";
import { useUserState } from "../../helpers/GlobalUserState";
import { PREDICATE_EQUALS } from "../../helpers/Predicates";
import { PersonGroupEntity, SinglePersonEntity } from "../../models";
import { groupEntityDataStructure, singleEntityDataStructure } from "../../components/ListingDataStructures";
import * as routes from "../../helpers/Routes";
import ListingTemplate from "../../components/ListingTemplate";

/**
 * implementation of animal home user home page
 * @returns JSX
 */
export default function PageAnimalHomeEntitiesListing() {
  // globals
  const navigate = useNavigate();
  const user = useUserState();
  const animalHomeId = user.animalHomeId;
  const [value, setValue] = useState("single");
  const displaySingle = value === "single" ? "initial" : "none";
  const displayGroup = value === "group" ? "initial" : "none";

  const filterPredicates = [
    { key: "owner", value: animalHomeId, type: PREDICATE_EQUALS },
  ]

  const sortFieldInputData = [
    { value: 'createdAt', label: 'Erstellzeit' },
    { value: 'updatedAt', label: 'Aktualisierungszeit' },
  ]

  return (
    <Stack>
      <Group position="apart">
        <Title>Entitäten</Title>
        <Button color="green" leftIcon={<Plus />} onClick={() => navigate(`${routes.ROUTE_ANIMALHOME_ENTITY_NEW}?type=${value}`)}>
          Neue Entität {CREATE}
        </Button>
      </Group>
      <Divider />

      <SegmentedControl
        fullWidth
        value={value}
        onChange={setValue}
        data={[
          {
            label: "Einzelperson",
            value: "single",
          },
          { label: "Personengruppe", value: "group" },
        ]}
      />

      <Accordion variant="separated" value="single" chevron={null} display={displaySingle}>
        <Accordion.Item value="single">
          <Accordion.Control icon={<User />}>Einzelpersonen</Accordion.Control>
          <Accordion.Panel>
            <ListingTemplate
              animalhomeId={animalHomeId}
              dataStructure={singleEntityDataStructure}
              editRoute={routes.ROUTE_ANIMALHOME_SINGLE_PERSON_ENTITY_EDIT}
              filterPredicates={filterPredicates}
              listTypeModel={SinglePersonEntity}
              showRoute={routes.ROUTE_ANIMALHOME_SINGLE_PERSON_ENTITY_VIEW}
              sortDirection={SortDirection.DESCENDING}
              sortField="createdAt"
              sortFieldInputData={sortFieldInputData}
              sortByStatus={SortDirection.ASCENDING}
              filterFields={['firstName', 'lastName', 'phone', 'email']}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Accordion variant="separated" value="group" chevron={null} display={displayGroup}>
        <Accordion.Item value="group">
          <Accordion.Control icon={<Users />}>Personengruppen</Accordion.Control>
          <Accordion.Panel>
            <ListingTemplate
              animalhomeId={animalHomeId}
              dataStructure={groupEntityDataStructure}
              editRoute={routes.ROUTE_ANIMALHOME_PERSON_GROUP_ENTITY_EDIT}
              filterPredicates={filterPredicates}
              listTypeModel={PersonGroupEntity}
              showRoute={routes.ROUTE_ANIMALHOME_PERSON_GROUP_ENTITY_VIEW}
              sortDirection={SortDirection.DESCENDING}
              sortField="createdAt"
              sortFieldInputData={sortFieldInputData}
              sortByStatus={SortDirection.ASCENDING}
              filterFields={['name', 'type', 'phone', 'email']}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Stack>
  );
}