import { useNavigate } from 'react-router-dom';
import { Menu, Button } from '@mantine/core';
import { ChevronDown, ClipboardList } from 'tabler-icons-react';
import { CREATE, FORM_NAMES } from '../helpers/Constants';

export default function ActionsMenu({ modelObjectData, routes, isAdmin}) {
  const navigate = useNavigate();

  return (
    modelObjectData && routes && (!modelObjectData.type || modelObjectData.breed) &&
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button rightIcon={<ChevronDown />} leftIcon={<ClipboardList />}>Aktionen</Button>
      </Menu.Target>

      <Menu.Dropdown>

        {Object.keys(FORM_NAMES).map(formName => (
          <Menu.Item
            key={formName + CREATE}
            onClick={() => navigate(`${routes[formName + 'CREATE']}/${modelObjectData.id}/${isAdmin ? modelObjectData.owner : ''}`)}
          >
            {FORM_NAMES[formName]} {CREATE}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}