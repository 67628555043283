import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { Auth, AuthModeStrategyType } from 'aws-amplify';
import { Amplify } from "@aws-amplify/core";
import awsConfig from './aws-exports';
import * as Sentry from "@sentry/react";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// configure amplify
Amplify.configure({
  ...awsConfig,
  DataStore: {
      authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
  API: {
      graphql_headers: async () => {
          try {
              const session = await Auth.currentSession();
              return { Authorization: session.getIdToken().getJwtToken() };
          } catch (e) {
              if (e !== "No current user") {
                  Sentry.captureException(e);
              } 
              
              return {};
          }
      },
  }
});
// Amplify.Logger.LOG_LEVEL = 'DEBUG';