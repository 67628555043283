import React from "react";
import { Animals, Donations, PersonGroupIndividual, SinglePersonEntity } from "../../models";
import { PAYMENT, CREATE } from "../../helpers/Constants";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import FormPayment from "../../formulars/FormPayment";
import { ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT } from "../../helpers/Routes";
import PaymentCreatePageTemplate from "../../components/PaymentCreatePageTemplate";
import { useParams } from "react-router-dom";
import { validationForDonationsAnimal } from "../../helpers/FormValidations";

export default function PageSupportPaymentsCreatePaymentForAnimal() {
    const { id, animalHomeId } = useParams();

    return (
        <PaymentCreatePageTemplate
            pageTitle={`${PAYMENT}`}
            FormComponent={FormPayment}
            FormName={Donations}
            viewRoute={ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT}
            animalHomeId={animalHomeId}
            animalId={id}
            animalsTypeModel={Animals}
            singleEntityTypeModel={SinglePersonEntity}
            individualEntityTypeModel={PersonGroupIndividual}
            initialValues={initialValuesForm(Donations)}
            validationSchema={validationForDonationsAnimal}
        />
    );
}