// general routes
export const ROUTE_ROOT = "/";
export const ROUTE_HOME = "/home";
export const ROUTE_SIGNIN = "/signin";
export const ROUTE_SIGNIN_CHANGEPASSWORD = "/changepassword";
export const ROUTE_PASSWORD_FORGOT = "/forgotpassword";
export const ROUTE_PASSWORD_FORGOT_CONFIRM = `${ROUTE_PASSWORD_FORGOT}/confirm`;

// ANIMAL HOME routes
export const ROUTE_ANIMALHOME_ROOT = "";
export const ROUTE_ANIMALHOME_HOME = `${ROUTE_ANIMALHOME_ROOT}/dashboard`;
export const ROUTE_ANIMALHOME_EDIT = `${ROUTE_ANIMALHOME_ROOT}/edit`;
export const ROUTE_ANIMALHOME_DETAILS = `${ROUTE_ANIMALHOME_ROOT}/details`;
export const ROUTE_ANIMALHOME_USERS_NEW = `${ROUTE_ANIMALHOME_ROOT}/users/new`;
export const ROUTE_ANIMALHOME_FORMULARS = `${ROUTE_ANIMALHOME_ROOT}/forms`;
export const ROUTE_ANIMALHOME_ANIMALS = `${ROUTE_ANIMALHOME_ROOT}/animals`;
export const ROUTE_ANIMALHOME_PAYMENTS = `${ROUTE_ANIMALHOME_ROOT}/payments`;
// Entity related
export const ROUTE_ANIMALHOME_ENTITY = `${ROUTE_ANIMALHOME_ROOT}/entity`;
export const ROUTE_ANIMALHOME_ENTITY_LISTING = `${ROUTE_ANIMALHOME_ENTITY}/listing`;
export const ROUTE_ANIMALHOME_ENTITY_NEW = `${ROUTE_ANIMALHOME_ENTITY}/new`;
export const ROUTE_ANIMALHOME_PERSON_GROUP_INDIVIDUAL_CREATE = `${ROUTE_ANIMALHOME_ENTITY}/individual/create`;
export const ROUTE_ANIMALHOME_PERSON_GROUP_ENTITY_EDIT = `${ROUTE_ANIMALHOME_ENTITY}/group/edit`;
export const ROUTE_ANIMALHOME_PERSON_GROUP_INDIVIDUAL_EDIT = `${ROUTE_ANIMALHOME_ENTITY}/individual/edit`;
export const ROUTE_ANIMALHOME_SINGLE_PERSON_ENTITY_EDIT = `${ROUTE_ANIMALHOME_ENTITY}/single/edit`;
export const ROUTE_ANIMALHOME_PERSON_GROUP_ENTITY_VIEW = `${ROUTE_ANIMALHOME_ENTITY}/group/view`;
export const ROUTE_ANIMALHOME_PERSON_GROUP_INDIVIDUAL_VIEW = `${ROUTE_ANIMALHOME_ENTITY}/individual/view`;
export const ROUTE_ANIMALHOME_SINGLE_PERSON_ENTITY_VIEW = `${ROUTE_ANIMALHOME_ENTITY}/single/view`;
// Forms related
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/followupcheck/persongroupindividual`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_ANIMALHOME_FORMULARS}/create/followupcheck/singlepersonentity`;
export const ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK = `${ROUTE_ANIMALHOME_FORMULARS}/view/followupcheck`;
export const ROUTE_ANIMALHOME_FORMULARS_EDIT_FOLLOWUP_CHECK = `${ROUTE_ANIMALHOME_FORMULARS}/edit/followupcheck`;

export const ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/bringinanimal/persongroupindividual`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_ANIMALHOME_FORMULARS}/create/bringinanimal/singlepersonentity`;
export const ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL = `${ROUTE_ANIMALHOME_FORMULARS}/view/bringinanimal`;
export const ROUTE_ANIMALHOME_FORMULARS_EDIT_BRING_IN_ANIMAL = `${ROUTE_ANIMALHOME_FORMULARS}/edit/bringinanimal`;

export const ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/temporarilyadoption/persongroupindividual`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_ANIMALHOME_FORMULARS}/create/temporarilyadoption/singlepersonentity`;
export const ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION = `${ROUTE_ANIMALHOME_FORMULARS}/view/temporarilyadoption`;
export const ROUTE_ANIMALHOME_FORMULARS_EDIT_TEMPORARILY_ADOPTION = `${ROUTE_ANIMALHOME_FORMULARS}/edit/temporarilyadoption`;

export const ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/permanentlyadoption/persongroupindividual`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_ANIMALHOME_FORMULARS}/create/permanentlyadoption/singlepersonentity`;
export const ROUTE_ANIMALHOME_FORMULARS_VIEW_PERMANENTLY_ADOPTION = `${ROUTE_ANIMALHOME_FORMULARS}/view/permanentlyadoption`;
export const ROUTE_ANIMALHOME_FORMULARS_EDIT_PERMANENTLY_ADOPTION = `${ROUTE_ANIMALHOME_FORMULARS}/edit/permanentlyadoption`;
// Animal related
export const ROUTE_ANIMALHOME_ANIMALS_CREATE_ANIMAL = `${ROUTE_ANIMALHOME_ANIMALS}/create/animal`;
export const ROUTE_ANIMALHOME_ANIMALS_EDIT_ANIMAL = `${ROUTE_ANIMALHOME_ANIMALS}/edit/animal`;
export const ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL = `${ROUTE_ANIMALHOME_ANIMALS}/view/animal`;

export const ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_ANIMAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/followupcheck/animal`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_ANIMAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/bringinanimal/animal`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_ANIMAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/temporarilyadoption/animal`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_ANIMAL = `${ROUTE_ANIMALHOME_FORMULARS}/create/permanentlyadoption/animal`;
// Payment related
export const ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_ANIMALHOME_PAYMENTS}/create/payment/persongroupindividual`;
export const ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_ANIMALHOME_PAYMENTS}/create/payment/singlepersonentity`;
export const ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT = `${ROUTE_ANIMALHOME_PAYMENTS}/edit/payment`;
export const ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT = `${ROUTE_ANIMALHOME_PAYMENTS}/view/payment`;

export const ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_ANIMAL = `${ROUTE_ANIMALHOME_PAYMENTS}/create/payment/animal`;

// dashboard
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_DASHBOARD = `${ROUTE_ANIMALHOME_FORMULARS}/create/followupcheck/dashboard`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_DASHBOARD = `${ROUTE_ANIMALHOME_FORMULARS}/create/bringinanimal/dashboard`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_DASHBOARD = `${ROUTE_ANIMALHOME_FORMULARS}/create/temporarilyadoption/dashboard`;
export const ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_DASHBOARD = `${ROUTE_ANIMALHOME_FORMULARS}/create/permanentlyadoption/dashboard`;
export const ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_DASHBOARD = `${ROUTE_ANIMALHOME_PAYMENTS}/create/payment/dashboard`;


// SUPPORT routes
export const ROUTE_SUPPORT_ROOT = "/support";
export const ROUTE_SUPPORT_HOME = `${ROUTE_SUPPORT_ROOT}/home`;
export const ROUTE_SUPPORT_ANIMALHOME = `${ROUTE_SUPPORT_ROOT}/animalhome`;
export const ROUTE_SUPPORT_ANIMALHOME_LISTING = `${ROUTE_SUPPORT_ANIMALHOME}/listing`;
export const ROUTE_SUPPORT_ANIMALHOME_NEW = `${ROUTE_SUPPORT_ANIMALHOME}/new`;
export const ROUTE_SUPPORT_ANIMALHOME_EDIT = `${ROUTE_SUPPORT_ANIMALHOME}/edit`;
export const ROUTE_SUPPORT_ANIMALHOME_SHOW = `${ROUTE_SUPPORT_ANIMALHOME}/show`;
export const ROUTE_SUPPORT_ANIMALHOME_USERS_NEW = `${ROUTE_SUPPORT_ANIMALHOME}/users/new`;
// Entity related
export const ROUTE_SUPPORT_ENTITY = `${ROUTE_SUPPORT_ANIMALHOME}/entity`;
export const ROUTE_SUPPORT_ENTITY_NEW = `${ROUTE_SUPPORT_ENTITY}/new`;
export const ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_CREATE = `${ROUTE_SUPPORT_ENTITY}/individual/create`;
export const ROUTE_SUPPORT_PERSON_GROUP_ENTITY_EDIT = `${ROUTE_SUPPORT_ENTITY}/group/edit`;
export const ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_EDIT = `${ROUTE_SUPPORT_ENTITY}/individual/edit`;
export const ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_EDIT = `${ROUTE_SUPPORT_ENTITY}/single/edit`;
export const ROUTE_SUPPORT_PERSON_GROUP_ENTITY_VIEW = `${ROUTE_SUPPORT_ENTITY}/group/view`;
export const ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_VIEW = `${ROUTE_SUPPORT_ENTITY}/individual/view`;
export const ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_VIEW = `${ROUTE_SUPPORT_ENTITY}/single/view`;
// Forms related
export const ROUTE_SUPPORT_FORMULARS = `${ROUTE_SUPPORT_ANIMALHOME}/forms`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_SUPPORT_FORMULARS}/create/followupcheck/persongroupentity`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_SUPPORT_FORMULARS}/create/followupcheck/singlepersonentity`;
export const ROUTE_SUPPORT_FORMULARS_VIEW_FOLLOWUP_CHECK = `${ROUTE_SUPPORT_FORMULARS}/view/followupcheck`;
export const ROUTE_SUPPORT_FORMULARS_EDIT_FOLLOWUP_CHECK = `${ROUTE_SUPPORT_FORMULARS}/edit/followupcheck`;

export const ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_SUPPORT_FORMULARS}/create/temporarilyadoption/persongroupentity`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_SUPPORT_FORMULARS}/create/temporarilyadoption/singlepersonentity`;
export const ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION = `${ROUTE_SUPPORT_FORMULARS}/view/temporarilyadoption`;
export const ROUTE_SUPPORT_FORMULARS_EDIT_TEMPORARILY_ADOPTION = `${ROUTE_SUPPORT_FORMULARS}/edit/temporarilyadoption`;

export const ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_SUPPORT_FORMULARS}/create/bringinanimal/persongroupentity`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_SUPPORT_FORMULARS}/create/bringinanimal/singlepersonentity`;
export const ROUTE_SUPPORT_FORMULARS_VIEW_BRING_IN_ANIMAL = `${ROUTE_SUPPORT_FORMULARS}/view/bringinanimal`;
export const ROUTE_SUPPORT_FORMULARS_EDIT_BRING_IN_ANIMAL = `${ROUTE_SUPPORT_FORMULARS}/edit/bringinanimal`;

export const ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_SUPPORT_FORMULARS}/create/permanentlyadoption/persongroupentity`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_SUPPORT_FORMULARS}/create/permanentlyadoption/singlepersonentity`;
export const ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION = `${ROUTE_SUPPORT_FORMULARS}/view/permanentlyadoption`;
export const ROUTE_SUPPORT_FORMULARS_EDIT_PERMANENTLY_ADOPTION = `${ROUTE_SUPPORT_FORMULARS}/edit/permanentlyadoption`;
// Animals related
export const ROUTE_SUPPORT_ANIMALS = `${ROUTE_SUPPORT_ANIMALHOME}/animals`;
export const ROUTE_SUPPORT_ANIMALS_CREATE_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/create/animal`;
export const ROUTE_SUPPORT_ANIMALS_EDIT_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/edit/animal`;
export const ROUTE_SUPPORT_ANIMALS_VIEW_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/view/animal`;

export const ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/create/followupcheck/animal`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/create/bringinanimal/animal`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/create/temporarilyadoption/animal`;
export const ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/create/permanentlyadoption/animal`;
export const ROUTE_SUPPORT_PAYMENTS_CREATE_PAYMENT_FOR_ANIMAL = `${ROUTE_SUPPORT_ANIMALS}/create/payment/animal`;

// Payment related
export const ROUTE_SUPPORT_PAYMENTS = `${ROUTE_SUPPORT_ROOT}/payments`;
export const ROUTE_SUPPORT_PAYMENTS_CREATE_PAYMENT_FOR_PERSON_GROUP_INDIVIDUAL = `${ROUTE_SUPPORT_PAYMENTS}/create/payment/persongroupindividual`;
export const ROUTE_SUPPORT_PAYMENTS_CREATE_PAYMENT_FOR_SINGLE_PERSON_ENTITY = `${ROUTE_SUPPORT_PAYMENTS}/create/payment/singlepersonentity`;
export const ROUTE_SUPPORT_PAYMENTS_EDIT_PAYMENT = `${ROUTE_SUPPORT_PAYMENTS}/edit/payment`;
export const ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT = `${ROUTE_SUPPORT_PAYMENTS}/view/payment`;

// admin routes
export const ROUTE_ADMIN_ROOT = "/admin";
export const ROUTE_ADMIN_SUPPORTUSERS = `${ROUTE_ADMIN_ROOT}/supportusers`;
export const ROUTE_ADMIN_SUPPORTUSERS_NEW = `${ROUTE_ADMIN_SUPPORTUSERS}/new`;
export const ROUTE_RACE_BREED_CRUD = `${ROUTE_ADMIN_ROOT}/crud`;
export const ROUTE_CSV_PROCESSOR = `${ROUTE_ADMIN_ROOT}/csvprocessor`;
export const ROUTE_API = `${ROUTE_ADMIN_ROOT}/api`;
export const ROUTE_API_EDIT = `${ROUTE_ADMIN_ROOT}/api/edit`;

export const ROUTE_EDIT_RACE = `${ROUTE_ADMIN_ROOT}/editrace`;

// 404 routes
export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_CATCH = "*";