import { Button, Divider, Group, Select, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "../../components/Form";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_NAME, VALIDATION_SCHEMA_USERGROUP_ANIMALHOME } from "../../helpers/Validation";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { ArrowLeft, Check, File, Reload } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { USERGROUPS_ANIMALHOME, USERGROUP_ADMIN, USERGROUP_ANIMALHOME_ADMIN, USERGROUP_ANIMALHOME_USER, USERGROUP_SUPPORT } from "../../helpers/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_API_ADMIN_GETUSER, ADMIN_API_ANIMALHOMEADMIN_CREATEUSER, API_GET, API_POST, ADMIN_API_SUPPORT_CREATE_ANIMALHOME_USER, executeApiCall, API_ADMIN_NAME } from "../../helpers/APIHelper";
import { useUserState } from "../../helpers/GlobalUserState";

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_NAME,
    email: VALIDATION_SCHEMA_EMAIL,
    group: VALIDATION_SCHEMA_USERGROUP_ANIMALHOME,
});

/**
 * animal home details page to create new animal home user or admin user
 * @returns JSX
 */
export default function PageAnimalHomeUserNew() {

    // globals
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();
    const { animalhomeId } = useParams();
    const user = useUserState();
    const adminOrSupport = user.userGroups.includes(USERGROUP_ADMIN) || user.userGroups.includes(USERGROUP_SUPPORT);

    // submit callback
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);

            // check if user exists
            var userExists = false;
            if (adminOrSupport) {
                userExists = await executeApiCall(API_ADMIN_NAME, API_GET, ADMIN_API_ADMIN_GETUSER, {
                    "username": values.email.toLowerCase(),
                })
            }
            if (userExists) {
                setError({ action: ERROR_SHOW, error: new Error("User exisitiert bereits.") });
                return;
            }

            const routeToCall = adminOrSupport ? 
                                    ADMIN_API_SUPPORT_CREATE_ANIMALHOME_USER : 
                                    ADMIN_API_ANIMALHOMEADMIN_CREATEUSER;
            // create new user
            await executeApiCall(API_ADMIN_NAME, API_POST, routeToCall, {
                "name": values.name,
                "email": values.email.toLowerCase(),
                "group": values.group,
                "animalhomeId": animalhomeId,
            })

            // show message
            showNotification({ message: "User erfolgreich eingeladen. Der neue User erhält auf die angegebene E-Mail-Adresse ein temporäres Passwort zugesendet.", color: 'green', icon: <Check /> });

            // redirect
            navigate(-1);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            email: "",
            group: USERGROUPS_ANIMALHOME
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <Group position="apart">
                <Title>Neuen User einladen</Title>
                <Group>
                    <Button leftIcon={<ArrowLeft size={14} />} onClick={() => navigate(-1, { replace: true })} color="yellow">Zurück</Button>
                </Group>
            </Group>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Titel Vorname Nachname"
                        {...form.getInputProps('name')}
                    />
                    <TextInput
                        withAsterisk
                        label="E-Mail-Adresse"
                        placeholder="name@email.com"
                        {...form.getInputProps('email')}
                    />
                    <Select
                        withAsterisk
                        label="Berechtigungsgruppe"
                        placeholder="Berechtigungsgruppe wählen..."
                        data={[
                            { value: USERGROUP_ANIMALHOME_USER, label: 'Benutzer' },
                            { value: USERGROUP_ANIMALHOME_ADMIN, label: 'Administrator' },
                        ]}
                        {...form.getInputProps('group')}
                    />
                    <Divider />

                    <Group position='apart'>
                        <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                        <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}