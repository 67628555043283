import React from 'react';
import { TextInput, Group, Button, Textarea, Stack } from "@mantine/core";
import DatePickerInput from "../components/DatePickerInput";
import SelectAnimal from '../components/SelectAnimal';
import SelectEntity from '../components/SelectEntity';


export default function FormAdoptTemporarily({ form, animalhomeId, animals, defaultAnimalSelectValue, defaultEntitySelectValue, animalId, entityId, entities }) {

    if(animalId){
        form.values.animalId = animalId;
    }

    return (
        <Stack>
            <form onSubmit={form.onSubmit()}>
                {!animalId &&
                    <SelectAnimal form={form} animalhomeId={animalhomeId} data={animals} defaultValue={defaultAnimalSelectValue} withAsterisk={true}/>
                }

                {!entityId &&
                    <SelectEntity form={form} animalhomeId={animalhomeId} data={entities} defaultValue={defaultEntitySelectValue} withAsterisk={true}/>
                }

                {/* animal number */}
                <TextInput label="Tiernummer" placeholder="Tiernummer" {...form.getInputProps('animalNumber')} pt="xs"/>
                <hr></hr>

                {/* pet adopted untill */}
                <DatePickerInput 
                    label="Der Pflegezeitraum ist befristet bis zum" 
                    placeholder="Der Pflegezeitraum ist befristet bis zum" 
                    form={form} 
                    inputKey="petAdoptedUntill" 
                    pt="xs"
                    withAsterisk
                />

                {/* other comments */}
                <Textarea placeholder="Sonstiges" label="Sonstiges" {...form.getInputProps('petOtherComments')} pt="xs"/>

                <hr></hr>
                {/* performed action date */}
                <DatePickerInput 
                    label="Eingangsdatum" 
                    placeholder="Eingangsdatum" 
                    form={form} 
                    inputKey="performedActionDate" 
                    pt="xs"
                    withAsterisk
                />

                {/* pdf name*/}
                <TextInput label="Name des Dokuments" placeholder="Name des Dokuments" {...form.getInputProps("customPdfName")} pt="xs" withAsterisk/>


                <Group position="right" mt="md">
                    <Button type="submit">Speichern</Button>
                </Group>
            </form>
        </Stack>
    )
}
