import { useParams } from "react-router-dom";
import { Animals, FormCheckAnimal as FormCheckAnimalModel } from "../../models";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK } from "../../helpers/Routes";
import { FORM_CHECK_ANIMAL, PDF_CHECK_ANIMAL } from "../../helpers/Constants";
import FormCheckAnimal from "../../formulars/FormCheckAnimal";
import FormEditPageTemplate from "../../components/FormEditPageTemplate";
import { validationForFolowUpCheckDashboard } from "../../helpers/FormValidations";

/**
 * Page used to Edit a Follow up Check form
 * @returns JSX
 */
export default function PageAnimalHomeFormsEditFollowupCheck() {

  const { id } = useParams();

  return (
    <FormEditPageTemplate
      id={id}
      pdfName={PDF_CHECK_ANIMAL}
      formTitle={FORM_CHECK_ANIMAL}
      animalsTypeModel={Animals}
      FormModel={FormCheckAnimalModel}
      FormComponent={FormCheckAnimal}
      viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK}
      validationSchema={validationForFolowUpCheckDashboard}
    />
  );
}
