import { useParams } from "react-router-dom";
import { Animals, FormAdoptTemporarily as FormAdoptTemporarilyModel} from "../../models";
import { ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION } from "../../helpers/Routes";
import { FORM_ADOPT_TEMPORARILY, PDF_ADOPT_TEMPORARILY } from "../../helpers/Constants";
import FormAdoptTemporarily from "../../formulars/FormAdoptTemporarily";
import FormEditPageTemplate from "../../components/FormEditPageTemplate";

/**
 * Page used to Edit a Temporary Adoption form
 * @returns JSX
 */
export default function PageSupportFormsEditTemporarilyAdoption() {

  const { id } = useParams();

  return (
    <FormEditPageTemplate
      id={id}
      pdfName={PDF_ADOPT_TEMPORARILY}
      formTitle={FORM_ADOPT_TEMPORARILY}
      FormModel={FormAdoptTemporarilyModel}
      animalsTypeModel={Animals}
      FormComponent={FormAdoptTemporarily}
      viewRoute={ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION}
    />
  );
}