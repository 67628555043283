import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { USERGROUP_ANIMALHOME_ADMIN } from '../helpers/Constants';
import { useUserState } from '../helpers/GlobalUserState';
import { ROUTE_HOME, ROUTE_SIGNIN } from '../helpers/Routes';

/**
 * Private route implementation to redirect 
 * @param {array} props The props of the component
 */
const RouteAnimalHomeAdmin = ({ Component }) => {
    const location = useLocation();
    const user = useUserState();

    if (user.userGroups.includes(USERGROUP_ANIMALHOME_ADMIN)) {
        return <Component />
    }
    else if (user.id) {
        return <Navigate to={ROUTE_HOME} />
    }
    else {
        return <Navigate to={ROUTE_SIGNIN} state={{ from: location }} />
    }
}

// Default export of the class
export default RouteAnimalHomeAdmin;