import React from 'react'
import CookieConsent from 'react-cookie-consent'

export default function AcceptCookies() {
  return (
    <CookieConsent
        buttonText="Akzeptieren"
        cookieName="gt_tiever_cookie"
        expires={365}
    >
        Diese Webseite verwendet Cookies, welche für die Funktionalitäten der Seite zwingend nötig sind.
    </CookieConsent>
  )
}
