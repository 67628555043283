import React from 'react';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Button } from '@mantine/core';
import { Download } from 'tabler-icons-react';

const ExportCSVButton = ({ data }) => {
    const csvConfig = mkConfig({
        fieldSeparator: ';',
        filename: 'Report',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useKeysAsHeaders: true,
    });

    const handleExport = () => {
        const csvData = generateCsv(csvConfig)(data);
        download(csvConfig)(csvData);
    };

    return (
        <Button onClick={handleExport} rightIcon={<Download size={14} />}>
            Export CSV
        </Button>
    );
};

export default ExportCSVButton;