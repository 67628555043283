// user groups
export const USERGROUP_ANIMALHOME_USER = "AnimalHomeUser";
export const USERGROUP_ANIMALHOME_ADMIN = "AnimalHomeAdmin";
export const USERGROUPS_ANIMALHOME = [
    USERGROUP_ANIMALHOME_USER,
    USERGROUP_ANIMALHOME_ADMIN,
]
export const USERGROUP_SUPPORT = "Support";
export const USERGROUP_ADMIN = "Admin";

// user group select options
export const USERGROUPS_ALL = "Alle";

// actions
export const CREATE = "erstellen";
export const EDIT = "bearbeiten";

// animals
export const ANIMAL = "Tier";
export const ANIMALS = "Tiere";
export const ANIMALHOME = "Tierheim";

export const DOCUMENTS = "Dokumente";
export const PAYMENT = "Zahlung";
export const FINANCIAL_EARNINGS = "Zahlungen"

// entities
export const SINGLE_PERSON_ENTITY = "Einzelperson";
export const PERSON_GROUP_ENTITY = "Personengruppe";
export const PERSON_GROUP_INDIVIDUAL = "Personen";

// form names
export const FORM_CHECK_ANIMAL = "Nachkontrollbogen";
export const FORM_ADOPT_TEMPORARILY = "Pflegevertrag";
export const FORM_ADOPT_PERMANENTLY = "Schutzvertrag";
export const FORM_BRING_IN_ANIMAL = "Aufnahme";
export const FORM_NAMES = {
    FORM_CHECK_ANIMAL,
    FORM_ADOPT_TEMPORARILY,
    FORM_ADOPT_PERMANENTLY,
    FORM_BRING_IN_ANIMAL,
};

// pdf names
export const PDF_CHECK_ANIMAL = "pdfCheckAnimal";
export const PDF_ADOPT_TEMPORARILY = "pdfAdoptTemporarily";
export const PDF_ADOPT_PERMANENTLY = "pdfAdoptPermanently";
export const PDF_BRING_IN_ANIMAL = "pdfBringInAnimal";

// file types
export const FILE_SOURCE_INIT = "INIT";
export const FILE_SOURCE_SELECTED = "SELECTED";
export const FILE_TYPE_IMAGE = "image";
export const FILE_TYPE_PDF = "pdf";
export const FILE_TYPE_ACCEPT = {
    "image": { 'image/jpeg': [], 'image/jpg': [], 'image/png': [] },
    "pdf": { 'application/pdf': [] }
}

// table
export const TABLE_ACTION_WIDTH = 30;

// predicates
export const PREDICATE_CONTAINS = "CONTAINS";
export const PREDICATE_EQUALS = "EQ";
export const PREDICATE_GE = "GE";
export const PREDICATE_LT = "LT";

// avatar
export const AVATAR_SIZE = 150;
export const AVATAR_RADIUS = "md";

// misc
export const OPTION_YES = "Ja";
export const OPTION_NO = "Nein";
export const OPTION_ALL = "Alle";
export const OPTIONS_YESNO = [
    OPTION_YES,
    OPTION_NO
];
export const OPTIONS_YESNO_WITHALL = [
    OPTION_ALL,
    ...OPTIONS_YESNO,
];

// emails
export const EMAIL_CHECK_ANIMAL = "emailCheckAnimal";
export const EMAIL_ADOPT_TEMPORARILY = "emailAdoptTemporarily";
export const EMAIL_ADOPT_PERMANENTLY = "emailAdoptPermanently";
export const EMAIL_BRING_IN_ANIMAL = "emailBringInAnimal";

// formulars names based on actions
export const CHECK_ANIMAL = "FormCheckAnimal";
export const ADOPT_TEMPORARILY = "FormAdoptTemporarily";
export const ADOPT_PERMANENTLY = "FormAdoptPermanently";
export const BRING_IN = "FormBringInAnimal";

// actions
export const ACTION_CHECK_ANIMAL = "followUpCheck";
export const ACTION_ADOPT_TEMPORARILY = "temporarilyAdopt";
export const ACTION_ADOPT_PERMANENTLY = "permanentlyAdopt";
export const ACTION_BRING_IN = "bringIn";
export const ACTION_DONATION = "donationReceived";

// animal statuses
export const STATUS_IN_SHELTER_ADOPTION_AVAILABLE = "inShelterAdoptionAvailable";
export const STATUS_IN_SHELTER_ADOPTION_NOT_AVAILABLE = "inShelterAdoptionNotAvailable";
export const STATUS_TEMPORARILY_ADOPTED = "temporarilyAdopted";
export const STATUS_PERMANENTLY_ADOPTED = "permanentlyAdopted";
export const STATUS_AT_VET = "atVet";
export const STATUS_DEAD_ANIMAL = "deadAnimal";

// routes
export const ANIMALHOME_ENTITIES_LISTING = "/animalhome/entity/listing";
export const ANIMALHOME_ANIMALS_LISTING = "/animalhome/animals";