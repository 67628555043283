import { Accordion } from "@mantine/core";
import React from "react";
import ListingTemplate from "./ListingTemplate";
import { SortDirection } from "aws-amplify";
import { AnimalsDocuments } from "../models";
import { animalDocumentsDataStructure } from "./ListingDataStructures";
import { PREDICATE_EQUALS } from "../helpers/Predicates";
import { Article } from "tabler-icons-react";
import { DOCUMENTS } from "../helpers/Constants";

export default function AnimalDocumentsListing({ animalId }) {
    const filterPredicates = [{ key: "animalId", value: animalId, type: PREDICATE_EQUALS }];

    const sortFieldInputData = [
        { value: "createdAt", label: "Erstellzeit" },
        { value: "updatedAt", label: "Aktualisierungszeit" },
    ];

    return (
        <Accordion variant="separated">
            <Accordion.Item value="forms">
                <Accordion.Control icon={<Article />}>{DOCUMENTS}</Accordion.Control>
                <Accordion.Panel>
                    <ListingTemplate
                        animalhomeId={animalId}
                        dataStructure={animalDocumentsDataStructure}
                        filterPredicates={filterPredicates}
                        sortFieldInputData={sortFieldInputData}
                        listTypeModel={AnimalsDocuments}
                        sortField="createdAt"
                        sortDirection={SortDirection.DESCENDING}
                        filterFields={['originalName', 'documentDescription']}
                        isAnimalDocument={true}
                    />
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
}
