import { API_PDF_NAME, API_POST, FILE_PDF_EMAIL_ROUTE, executeApiCall } from "./APIHelper";

export async function SendMailToEntity(formValues, entityEmail, emailName) {
    try {
        const route = FILE_PDF_EMAIL_ROUTE;
        const body = { 
            formValues: formValues,
            entityEmail: [entityEmail],
            emailName: emailName
        };
  
        await executeApiCall(
            API_PDF_NAME,
            API_POST,
            route,
            body,
        );

    } catch (error) {
        console.error("Error:", error);
    }
}