import { Text, Button, Center, Stack, Title, Paper, TextInput, PasswordInput, Container, Group } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth } from 'aws-amplify';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { VALIDATION_SCHEMA_2FA_CODE, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORD, VALIDATION_SCHEMA_PASSWORD_CONFIRMATION } from "../../helpers/Validation";
import { useForm } from "../../components/Form";
import { ROUTE_SIGNIN } from "../../helpers/Routes";

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    password: VALIDATION_SCHEMA_PASSWORD,
    passwordConfirm: VALIDATION_SCHEMA_PASSWORD_CONFIRMATION,
    code: VALIDATION_SCHEMA_2FA_CODE,
});

/**
 * component to handle password reset confirmation
 * @returns JSX
 */
export default function PageGeneralResetpasswordConfirm() {

    // globals
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();

    // get email if provided
    const { email } = useParams();

    // submit callback
    const submitCallback = async (values) => {
        try {
            await Auth.forgotPasswordSubmit(values.email.toLowerCase(), values.code, values.password);
            showNotification({ message: "Das Passwort wurde erfolgreich geändert.", color: 'green', icon: <Check /> });
            navigate(`${ROUTE_SIGNIN}/${values.email.toLowerCase()}`);
        }
        catch (e) {
            if (e.code === "CodeMismatchException") {
                showNotification({ message: "Der Bestätigungscode war nicht korrekt. bitte versuchen Sie es erneut, oder fordern Sie einen neuen Code an.", color: 'red', icon: <X /> });
            }
            else if (e.code === "LimitExceededException") {
                showNotification({ message: "Es wurde zu oft versucht das Passwort zurückzusetzen. Bitte versichen Sie es in ein paar Minuten erneut.", color: 'red', icon: <X /> });
            }
            else if (e.code === "ExpiredCodeException") {
                showNotification({ message: "Der Bestätigungscode ist abgelaufen. Bitte fordern Sie einen neuen Code an.", color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    /**
     * wrapper for sending a new code
     */
    const sendNewCode = async (email) => {
        try {
            setLoading(LOADING_SHOW);
            await Auth.forgotPassword(email);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: email ? email : "",
            code: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100%" }}>
            <Container size={600} style={{ width: "600px" }}>
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center">Passwort zurücksetzen</Title>
                        <Link to={`${ROUTE_SIGNIN}/${form.values.email.toLowerCase()}`}>
                            <Text className="pointer" underline color="dimmed" size="sm" align="center" mb="md">
                                Zurück zur Anmeldung? Anmelden
                            </Text>
                        </Link>


                        <Stack>
                            <TextInput
                                withAsterisk
                                label="E-Mail-Adresse"
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                                disabled={true}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Passwort"
                                placeholder="Passwort"
                                {...form.getInputProps('password')}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Passwort bestätigen"
                                placeholder="Passwort"
                                {...form.getInputProps('passwordConfirm')}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Bestätigungscode"
                                placeholder="Bestätigungscode"
                                {...form.getInputProps('code')}
                            />
                        </Stack>
                        <Group position="right" mt={5}>
                            <Text className="pointer" underline color="dimmed" size="sm" align="center" onClick={() => sendNewCode(form.values.email)}>
                                Neuen Code anfordern
                            </Text>
                        </Group>
                        <Button type="submit" fullWidth mt="xl">Passwort zurücksetzen</Button>
                    </Paper>
                </form>
            </Container>
        </Center>
    );
}