import { Avatar as MantineAvatar, Loader, Text, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { X } from "tabler-icons-react";
import { getDataFromBlob } from "../helpers/Files";
import * as Sentry from "@sentry/react";
import { API_FILE_NAME, API_GET, FILE_API_URL_DOWNLOAD, executeApiCall } from "../helpers/APIHelper";

export default function Avatar(props) {
    const size = props.size ? props.size : 150;
    const placeholderSize = size * 0.7;
    const [src, setSrc] = useState(null);

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        if (props.url) {
            setSrc(props.url);
        }
        else if (props.file) {
            getDataFromBlob(props.file).then((result) => {
                setSrc(result);
            })
        }
        else if (props.fileKey) {
            executeApiCall(
                API_FILE_NAME,
                API_GET,
                FILE_API_URL_DOWNLOAD,
                {
                    key: props.fileKey, 
                    protectionLevel: props.fileProtectionLevel, 
                    identityId: props.identityId
                }
            ).then((url) => {
                setSrc(url);
            }).catch((err) => {
                Sentry.captureException(err);
            });
        }
        else {
            setSrc(null);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.file, props.fileKey, props.fileProtectionLevel, props.url, props.identityId]
    );

    /**
     * return placeholder depending on state
     * @returns JSX
     */
    const getPlaceholder = () => {
        if (props.fileKey || props.file) {
            return (
                <Loader size={placeholderSize} />
            );
        }

        if (props.placeholder) {
            return (
                <props.placeholder size={placeholderSize} color={props.color} />
            );
        }

        return (
            <X size={placeholderSize} color="red" />
        );
    }

    return (
        <Stack spacing={5}>
            <MantineAvatar
                size={size}
                radius="md"
                src={src}
                // variant="outline"
                color={props.color}
            >
                {getPlaceholder()}
            </MantineAvatar>
            {props.fileKey || props.file ?
                props.captionImage && <Text size="xs" align="center" color="dimmed">{props.captionImage}</Text>
                :
                props.captionPlaceholder && <Text size="xs" align="center" color="dimmed">{props.captionPlaceholder}</Text>
            }
        </Stack>
    )
}