import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { ROUTE_NOT_FOUND } from "../helpers/Routes";
import { useEffect, useState } from "react";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { DataStore } from "aws-amplify";
import { ANIMAL, CREATE, PERSON_GROUP_INDIVIDUAL, SINGLE_PERSON_ENTITY } from "../helpers/Constants";
import FormCreateTemplate from "./FormCreateTemplate";
import { PersonGroupIndividual, SinglePersonEntity } from "../models";

/**
 * Create Form Template Page to create a new animal home form
 * @returns JSX
 */
export default function FormCreatePageTemplate({
    animalHomeId,
    entityId,
    entityTypeModel,
    animalsTypeModel,
    formTitle,
    initialValues,
    pdfName,
    viewRoute,
    FormComponent,
    FormName,
    animalId,
    validationSchema
}) {

    // globals
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const [entity, setEntity] = useState(null);
    const [animals, setAnimals] = useState(null);
    const [animal, setAnimal] = useState(null);

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (entityId) {
                // get item
                setLoading(LOADING_SHOW);

                const entity = await DataStore.query(entityTypeModel, entityId);

                if (!entity) {
                    navigate(ROUTE_NOT_FOUND);
                }

                setEntity(entity);
            } else{
                // provide all entities to choose from
                const singlePersonEntities = await DataStore.query(SinglePersonEntity, (c) => c.owner.eq(animalHomeId));
                const personGroupIndividuals = await DataStore.query(PersonGroupIndividual, (c) => c.owner.eq(animalHomeId));
                const entities = singlePersonEntities.concat(personGroupIndividuals);
                setEntity(entities.map( entity => ({
                    value: entity.id,
                    label: entity.lastName+" "+entity.firstName
                })));
            }

            if(!animalId){
                // provide all animals to choose from
                const animals = await DataStore.query(animalsTypeModel, (c) => c.owner.eq(animalHomeId));
                setAnimals(animals.map( animal => ({
                    value: animal.id,
                    label: animal.name
                })));
            } else{
                const animal = await DataStore.query(animalsTypeModel, animalId);
                setAnimal(animal);
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entityId]
    );

    return (
        ((entity && animals) || animalId) && (
            <Stack>
                <Group position="apart">
                    <Title>{formTitle} {CREATE}</Title>
                    <Group>
                        <Button
                            leftIcon={<ArrowLeft size={14} />}
                            onClick={() => navigate(-1, { replace: true })}
                            color="yellow"
                        >
                            Zurück
                        </Button>
                    </Group>
                </Group>
                {animal && (<Title order={3}>Für {animal?.name} {ANIMAL}</Title>)}
                {entityId && (<Title order={3}>Für {entity?.firstName} {entity.role ?
                    PERSON_GROUP_INDIVIDUAL :
                    SINGLE_PERSON_ENTITY}</Title>)}
                <Divider />

                <FormCreateTemplate
                    entities={entity}
                    animals={animals}
                    animalHomeId={animalHomeId}
                    entityId={entityId}
                    FormComponent={FormComponent}
                    FormName={FormName}
                    initialValues={initialValues}
                    defaultEntitySelectValue={animal?.entityId}
                    pdfName={pdfName}
                    viewRoute={viewRoute}
                    animalId={animalId}
                    validationSchema={validationSchema}
                />
            </Stack>
        )
    )
}