import { Divider, Group, Stack, Title } from "@mantine/core";
import ActionsShortcuts from "../../components/ActionsShortcuts";
import Statistics from "../../components/Statistics";
import { useUserState } from "../../helpers/GlobalUserState";
import RecentlyUpdatedAnimals from "../../components/RecentlyUpdatedAnimals";

/**
 * implementation of animal home user home page
 * @returns JSX
 */
export default function PageAnimalHomeHome() {

    const user = useUserState();

    return (
        <div>
            <Stack>
                <Group>
                    <Title>Dashboard</Title>
                </Group>

                <Divider />

                <ActionsShortcuts />
                
                <Divider />

                <Statistics animalHomeId={user.animalHomeId} />

                <RecentlyUpdatedAnimals animalHomeId={user.animalHomeId} />
            </Stack>
        </div>
    );
}