import { useParams } from "react-router-dom";
import { Animals, FormAdoptTemporarily as FormAdoptTemporarilyModel, PersonGroupIndividual } from "../../models";
import { FORM_ADOPT_TEMPORARILY, PDF_ADOPT_TEMPORARILY } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import FormAdoptTemporarily from "../../formulars/FormAdoptTemporarily";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import { validationForAdoptTemporarilyEntity } from "../../helpers/FormValidations";

/**
 * Page used to create a new Temporary Adoption form for a Person Group Entity
 * @returns JSX
 */
export default function PageAnimalHomeFormsCreateTemporarilyAdoptionForPersonGroupIndividual() {
    const { id } = useParams();
    const user = useUserState();

    return (
        <FormCreatePageTemplate
            FormComponent={FormAdoptTemporarily}
            FormName={FormAdoptTemporarilyModel.name}
            animalHomeId={user.animalHomeId}
            entityId={id}
            entityTypeModel={PersonGroupIndividual}
            animalsTypeModel={Animals}
            formTitle={FORM_ADOPT_TEMPORARILY}
            initialValues={initialValuesForm(FormAdoptTemporarilyModel)}
            pdfName={PDF_ADOPT_TEMPORARILY}
            viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION}
            validationSchema={validationForAdoptTemporarilyEntity}
        />
    )
}