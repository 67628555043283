import { useParams } from "react-router-dom";
import { Animals, Donations } from "../../models";
import { ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT } from "../../helpers/Routes";
import { PAYMENT } from "../../helpers/Constants";
import FormPayment from "../../formulars/FormPayment";
import PaymentEditPageTemplate from "../../components/PaymentEditPageTemplate";
import { validationForDonationsDashboard } from "../../helpers/FormValidations";

export default function PageSupportPaymentsEditPayment() {
    const { id } = useParams();

    return (
      <PaymentEditPageTemplate
        id={id}
        formTitle={PAYMENT}
        animalsTypeModel={Animals}
        FormModel={Donations}
        FormComponent={FormPayment}
        viewRoute={ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT}
        validationSchema={validationForDonationsDashboard}
      />
    );
}
