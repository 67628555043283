import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { ROUTE_NOT_FOUND } from "../helpers/Routes";
import { DataStore } from "aws-amplify";
import { ActionIcon, Badge, Button, Divider, Grid, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { ArrowLeft, Download, Edit, ExternalLink, Printer } from "tabler-icons-react";
import DisplayImagesAndDocumentsInView from "./DisplayImagesAndDocumentsInView";
import { downloadFileFromUrl, getSingleFileUrl } from "../helpers/Files";
import { PATH_TO_PDFS } from "../helpers/APIHelper";
import t from "../helpers/Translations";
import { Animals, PersonGroupIndividual, SinglePersonEntity } from "../models";
import { displayDate, getItemLinkAndName } from "../helpers/Functions";
import { FORM_ADOPT_PERMANENTLY, FORM_ADOPT_TEMPORARILY, FORM_BRING_IN_ANIMAL, FORM_CHECK_ANIMAL } from "../helpers/Constants";

const formExeption = [FORM_CHECK_ANIMAL, FORM_BRING_IN_ANIMAL, FORM_ADOPT_PERMANENTLY, FORM_ADOPT_TEMPORARILY];

export default function FormViewTemplate({ id, formModel, formName, formFields, editFormRoute, onlyEditable, isAdmin }) {

    // globals
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const [formDataObject, setFormDataObject] = useState(null);
    const [animal, setAnimal] = useState(null);
    const [entity, setEntity] = useState(null);

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);

                const formDataStoreObject = await DataStore.query(formModel, id);
                
                const animal = await DataStore.query(Animals, formDataStoreObject.animalId);

                if(formDataStoreObject.entityId){
                    const singlePerson = await DataStore.query(SinglePersonEntity, formDataStoreObject.entityId);
                    if(singlePerson){
                        setEntity(singlePerson);
                    } else {
                        const personGroupIndividual = await DataStore.query(PersonGroupIndividual, formDataStoreObject.entityId);
                        setEntity(personGroupIndividual);
                    }
                }

                if (!formDataStoreObject) {
                    navigate(ROUTE_NOT_FOUND);
                }

                setFormDataObject(formDataStoreObject);
                setAnimal(animal);

                // add entity id to action forms to display entity name
                if(formExeption.includes(formName) && !formFields.includes("entityId")){
                    formFields.unshift("entityId")
                }
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    // download the pdf
    const downloadFile = async (fileKey) => {
        try {
            const url = await getSingleFileUrl(fileKey, formDataObject.owner);
            await downloadFileFromUrl(url, fileKey);
        } catch (error) {
            console.error('Error while downloading the file:', error);
        }
    };

    const openFileHandler = async (fileKey) => {
        try {
            const url = await getSingleFileUrl(fileKey, formDataObject.owner);
            window.open(url, '_blank');
        } catch (error) {
            console.error('Could not open the file:', error);
        }
    };

    const handlePrintFile = async (fileKey) => {
        try {
            const url = await getSingleFileUrl(fileKey, formDataObject.owner);
    
            // download PDF as blob
            const response = await fetch(url);
            const blob = await response.blob();
    
            // generate temporarily url for blob
            const blobUrl = window.URL.createObjectURL(blob);
    
            // create a hidden iframe and attach the doc
            let iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
    
            iframe.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            };
        } catch (error) {
            console.error('Could not print the file:', error);
        }
    };

    const renderField = (field) => {
        switch (field) {
            case "customPdfNameNormalized":
                return;
            case "scannedDocuments":
            case "petImages":
                return (
                    <Grid.Col span={6}>
                        <DisplayImagesAndDocumentsInView
                            petImages={field === 'petImages' ? formDataObject[field] : []}
                            scannedDocuments={field === 'scannedDocuments' ? formDataObject[field] : []}
                            owner={formDataObject.owner}
                        />
                    </Grid.Col>
                );
            case "expenseType":
                return ( <Grid.Col span={6}><Badge variant="outline">{t(formDataObject[field], field)}</Badge></Grid.Col> );
            case "animalId":
                const { animalLink, animalName } = getItemLinkAndName(isAdmin, field, animal);
                return animal && ( <Grid.Col span={6}>
                    <Text 
                        component="a" 
                        onClick={() => navigate(animalLink)}
                        style={{ cursor: 'pointer', color: 'blue' }}
                    >
                        {animalName}
                    </Text>
                </Grid.Col> );
            case "entityId":
                const { entityLink, entityName } = getItemLinkAndName(isAdmin, field, entity);
                return entity && ( <Grid.Col span={6}>
                    <Text 
                        component="a" 
                        onClick={() => navigate(entityLink)}
                        style={{ cursor: 'pointer', color: 'blue' }}
                    >
                        {entityName}
                    </Text></Grid.Col> );
            case "amount":
                const numberFormat = new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                });
                return ( <Grid.Col span={6}><Text>{numberFormat.format(formDataObject[field])}</Text></Grid.Col> );
            case "typeOfKeeping":
            case "animalPartners":
            case "petBehaviorStrangers":
            case "bringInType":
                return <Grid.Col span={6}>{formDataObject[field].map(value => {
                    return ( <Text key={value}>{t(value, field)}</Text> )
                })}</Grid.Col>;
            case "gender":
            case "documentType":
            case "inspectionType":
            case "isOwnedByShelter":
            case "petBehaviorOwner":
            case "overallImpression":
            case "needsAnotherCheck":
            case "lookingImpressions":
            case "environment":
                return ( <Grid.Col span={6}><Text>{t(formDataObject[field], field)}</Text></Grid.Col> );
            case "deliveredOn":
            case "dateOfControl":
            case "petAdoptedUntill":
            case "performedActionDate":
                return ( <Grid.Col span={6}><Text variant="outline">{displayDate(formDataObject[field])}</Text></Grid.Col> );
            default:
                return ( <Grid.Col span={6}><Text>{formDataObject[field]}</Text></Grid.Col> );
        }
    }

    return (
        formDataObject &&
        <Stack>
            <Group position="apart">
                <Title>{formName}</Title>
                <Group>
                    <Button
                        leftIcon={<ArrowLeft size={14} />}
                        onClick={() => navigate(-1, { replace: true })}
                        color="yellow"
                    >
                        Zurück
                    </Button>
                </Group>
            </Group>
            <Divider />

            <Stack>
                <Paper shadow="xs" p="xl">
                    <Group>
                        <ActionIcon
                            color="blue"
                            onClick={() => navigate(`${editFormRoute}/${formDataObject.id}`)}
                        >
                            <Edit />
                        </ActionIcon>
                        {!onlyEditable &&
                            <>
                                <ActionIcon
                                    color="blue"
                                    onClick={() => downloadFile(`${PATH_TO_PDFS}${formDataObject.fileName}`)}
                                >
                                    <Download />
                                </ActionIcon>
                                <ActionIcon
                                    color="blue"
                                    onClick={() => openFileHandler(`${PATH_TO_PDFS}${formDataObject.fileName}`)}
                                >
                                    <ExternalLink />
                                </ActionIcon>
                                <ActionIcon
                                    color="blue"
                                    onClick={() => handlePrintFile(`${PATH_TO_PDFS}${formDataObject.fileName}`)}
                                >
                                    <Printer />
                                </ActionIcon>
                            </>
                        }
                    </Group>

                    <Grid mt={5} grow>
                        {formFields.map(field => (
                            <React.Fragment key={field} >
                                <Grid.Col span={12} sm={6}><Text fw={700}>{t(field, formModel.name)}</Text></Grid.Col>
                                {renderField(field)}
                            </React.Fragment>
                        ))}
                    </Grid>

                </Paper>
            </Stack>
        </Stack>
    )
}
