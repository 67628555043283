import { DataStore } from "aws-amplify";
import { ActionIcon, Badge, Group, Table, Text } from "@mantine/core";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Download, Edit, ExternalLink, Mail, Trash } from "tabler-icons-react";
import { TABLE_ACTION_WIDTH, USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import moment from "../helpers/Moment";
import { downloadFileFromUrl, getSingleFileUrl } from "../helpers/Files";
import DeleteDocumentModal from "./DeleteDocumentModal";
import EditDocumentModal from "./EditDocumentModal";
import EditBreedModal from "./EditBreedModal";
import ExportCSV from "./ExportCSV";
import AdminDeleteFromDatabase from "./AdminDeleteFromDatabase";
import { Animals, PersonGroupIndividual, SinglePersonEntity } from "../models";
import t from "../helpers/Translations";
import { useUserState } from "../helpers/GlobalUserState";
import ConfirmEmailSendModal from "./ConfirmEmailSendModal";

/**
 * list component
 * @param {object} props component props
 * @returns JSX
 */
export default function List(props) {

    // globals
    const subscription = useRef(null);
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const user = useUserState();
    const adminOrSupport = user.userGroups.includes(USERGROUP_ADMIN) || user.userGroups.includes(USERGROUP_SUPPORT);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const initialFetchRef = useRef(false);
    const [preprocessedItems, setPreprocessedItems] = useState({});
    const [totalDonations, setTotalDonations] = useState(0);
    const [totalDonationsForAnimalHome, setTotalDonationsForAnimalHome] = useState(0);
    const [totalDonationsForAnimals, setTotalDonationsForAnimals] = useState(0);
    const [activeDocumentId, setActiveDocumentId] = useState(null);

    const handleOpenModal = (id) => {
        setActiveDocumentId(id);
    };
    const handleCloseModal = () => {
        setActiveDocumentId(null);
    };

    /**
     * Use effect hook to fetch data when filter or sorting changes
     */
    useEffect(() => {
        if (initialFetchRef.current) {
            debouncedFetchData(props.filter, props.sort, props.customFilter);
        }

        // unsubscribe from listeners when unmounting
        return () => {
            unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.filter, props.sort, props.customFilter]
    );

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData(props.filter, props.sort, props.customFilter);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to fetch data
     */
    const fetchData = async (filter, sort, customFilter) => {
        // fist unsubscribe of already running
        unsubscribe();

        // fetch data
        const items = await DataStore.query(
            props.type,
            filter ? p => p.and(filter) : null,
            {
                sort: sort ? sort : null,
            }
        );

        setItemsHandler(items);

        // subscription for changes
        subscription.current = DataStore.observeQuery(
            props.type,
            filter ? p => p.and(filter) : null,
            {
                sort: sort ? sort : null,
            }
        ).subscribe({
            next(snapshot) {
                setItemsHandler(snapshot.items, customFilter);
            },
            error(err) {
                setLoading(LOADING_RESET);
                setError({ action: ERROR_SHOW, error: err });
            }
        });
    }

    /**
     * wrapper to check and fetch sub objects if needed
     * @param {array} items the items to set
     */
    const setItemsHandler = async (items, customFilter) => {
        try {
            // get all columns in subobjects
            const filteredSubColumns = props.dataStructure.filter((e) => {
                return e.key.includes(".");
            });

            // check if we do have any sub columns
            if (filteredSubColumns.length === 0) {
                // we dont have any sub colums, set the items
                setItems(items);
            }
            else {
                // we do have sub columns so we need to fetch them
                // map sub columns
                const subColumns = filteredSubColumns.map((e) => {
                    const split = e.key.split(".");
                    return {
                        key: e.key,
                        path: split,
                    }
                });

                // iterate over all items and create promises
                var promises = [];
                items.forEach(item => {
                    promises.push(new Promise(async (resolve, reject) => {
                        var subPromises = [];
                        subColumns.forEach(subColumn => {
                            subPromises.push(new Promise(async (resolve, reject) => {
                                try {
                                    const subLength = subColumn.path.length;
                                    
                                    var subObject = await item[subColumn.path[0]];

                                    for (var i = 1; i < subLength - 1; i++) {
                                        subObject = await subObject[subColumn.path[i]]
                                    }
                                    resolve({ key: subColumn.key, value: subObject[subColumn.path[subLength - 1]] });
                                }
                                catch (err) {
                                    reject(err);
                                }
                            }));
                        });

                        // wait for subpromises
                        try {
                            const subPromiseResult = await Promise.all(subPromises);
                            var newItem = { ...item };
                            subPromiseResult.forEach(result => {
                                newItem[result.key] = result.value;
                            });

                            resolve(newItem);
                        }
                        catch (err) {
                            reject(err);
                        }
                    }));
                });

                // wait for promises to execute
                var promiseResult = await Promise.all(promises);

                // execute custom filter if set
                if (customFilter) {
                    promiseResult = customFilter(promiseResult);
                }

                // set items
                setItems(promiseResult);
            }

            // set intial fetch to true
            initialFetchRef.current = true;


            let newPreprocessedItems = {};
            for (let item of items) {
                if (item.entityId) {
                    const singlePerson = await DataStore.query(SinglePersonEntity, item.entityId);
                    if (singlePerson) {
                        newPreprocessedItems[item.entityId] = {
                            entityLink: adminOrSupport ? `/support/animalhome/entity/single/view/${item.entityId}` : `/entity/single/view/${item.entityId}`,
                            entityName: `${singlePerson.lastName} ${singlePerson.firstName}`
                        };
                    } else {
                        const personGroupIndividual = await DataStore.query(PersonGroupIndividual, item.entityId);
                        newPreprocessedItems[item.entityId] = {
                            entityLink: adminOrSupport ? `/support/animalhome/entity/individual/view/${item.entityId}` : `/entity/individual/view/${item.entityId}`,
                            entityName: `${personGroupIndividual.lastName} ${personGroupIndividual.firstName}`
                        };
                    }
                }

                if (item.animalId) {
                    const animal = await DataStore.query(Animals, item.animalId);
                    if (animal) {
                        newPreprocessedItems[item.animalId] = {
                            animalLink: adminOrSupport ? `/support/animalhome/animals/view/animal/${item.animalId}` : `/animals/view/animal/${item.animalId}`,
                            animalName: animal.name
                        };
                    }
                }
            }

            if (props.isPaymentsList) {
                let totalDonations = 0;
                let totalForAnimalHome = 0;
                let totalForAnimals = 0;
            
                items.forEach(item => {
                    const amount = item.amount ? parseFloat(item.amount) : 0;
                    totalDonations += amount;
            
                    if (item.animalId) {
                        totalForAnimals += amount;
                    } else {
                        totalForAnimalHome += amount;
                    }
                });
            
                setTotalDonations(totalDonations);
                setTotalDonationsForAnimalHome(totalForAnimalHome);
                setTotalDonationsForAnimals(totalForAnimals);
            }

            setPreprocessedItems(newPreprocessedItems);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * debounce wrapper for the fetch
     */
    const debouncedFetchData = useRef(debounce(async (filter, sort, customFilter) => {
        fetchData(filter, sort, customFilter);
    }, 300)).current;

    /**
     * wrapper to unsubscribe
     */
    const unsubscribe = () => {
        if (subscription.current) {
            subscription.current.unsubscribe();
            subscription.current = null;
        }
    }

    /**
     * table header JSX
     */
    const tableHeader = <tr>
        {props.editRoute && <th></th>}
        {props.showRoute && <th></th>}
        {props.entityEmail && <th></th>}
        {props.isAnimalDocument && (
            <>
                {/* edit */}
                <th></th>
                {/* download */}
                <th></th>
                {/* open */}
                <th></th>
                {/* delete */}
                <th></th>
            </>
        )}
        {props.editInDatabase &&(
            <>
                {/* edit */}
                <th></th>
                {/* delete */}
                <th></th>
            </>
        )}
        {props.withDelete && <th></th>}
        {props.dataStructure.map((e) => (
            <th style={{ minWidth: '100px' }} key={e.key}>{e.title}</th>
        ))}
    </tr>

    /**
     * renders a field in a column
     * @param {object} element data column info
     * @param {*} item item to render
     * @returns JSX
     */
    const renderField = (element, item) => {

        if (element.key === "entityId" && preprocessedItems[item.entityId]) {
            const { entityLink, entityName } = preprocessedItems[item.entityId];
            return (
                <Text 
                    component="a" 
                    onClick={() => navigate(entityLink)}
                    style={{ cursor: 'pointer', color: 'blue' }}
                >
                    {entityName}
                </Text>
            );
        }

        if (element.key === "animalId" && preprocessedItems[item.animalId]) {
            const { animalLink, animalName } = preprocessedItems[item.animalId];
            return (
                <Text 
                    component="a" 
                    onClick={() => navigate(animalLink)}
                    style={{ cursor: 'pointer', color: 'blue' }}
                >
                    {animalName}
                </Text>
            );
        }

        if (element.key === "amount") {
            const numberFormat = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            });
            return numberFormat.format(item[element.key]);
        }

        switch (element.type) {
            case "color":
                return <Group>
                    <div style={{ backgroundColor: item[element.key], height: "25px", width: "25px", borderRadius: "25px" }}></div>
                    <Text>{item[element.key]}</Text>
                </Group>;
            case "timestamp":
                var timestamp = moment(item[element.key]).local()
                if (timestamp.isValid()) {
                    return timestamp.format("DD.MM.YYYY HH:mm");
                }
                else {
                    return "";
                }
            case "date":
                var date = moment(item[element.key]).local()
                if (date.isValid()) {
                    return date.format("DD.MM.YYYY");
                }
                else {
                    return "";
                }
            case "status":
            case "expenseType":
            case "documentType":
                return ( <Badge variant="outline">{t(item[element.type], element.key)}</Badge> );
            default:
                return item[element.key];
        }
    }

    const downloadFile = async (fileKey, owner) => {
        try {
            const url = await getSingleFileUrl(fileKey, owner);
            await downloadFileFromUrl(url, fileKey);
        } catch (error) {
            console.error('Error while downloading the file:', error);
        }
    };

    const openFileHandler = async (fileKey, owner) => {
        try {
            const url = await getSingleFileUrl(fileKey, owner);
            window.open(url, '_blank');
        } catch (error) {
            console.error('Could not open the file:', error);
        }
    };

    const handleDeleteConfirm = (fileKey, owner, fileId) => {
        console.log("Document deleted:", fileKey, owner, fileId);
    };

    const handleDocumentUpdate = (id, documentDescription, documentType) => {
        console.log(`Document updated with ID: ${id}, Description: ${documentDescription}, Type: ${documentType}`);
    };

    const getCSVData = (items, dataStructure) => {
        return items.map(item => {
            let newItem = {};
            dataStructure.forEach(element => {
                const value = item[element.key];
                newItem[element.title] = value === null ? "" : renderField(element, item);

                if(typeof(newItem[element.title]) === 'object'){
                    newItem[element.title] = newItem[element.title].props.children;
                }

            });
            return newItem;
        });
    };

    const csvData = getCSVData(items, props.dataStructure);

    const numberFormat = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });

    const inactiveRowStyle = {
        opacity: 0.8,
        backgroundImage: 'linear-gradient(45deg, rgba(128,128,128,0.2) 25%, transparent 25%, transparent 50%, rgba(128,128,128,0.2) 50%, rgba(128,128,128,0.2) 75%, transparent 75%, transparent)',
        backgroundSize: '10px 10px'
    };

    /**
     * table rows JSX
     */
    const rows = items.map((item) => (
        <tr 
            key={item[props.id]}
            style={{ 
                ...item.status && item.status === 'INACTIVE' ? inactiveRowStyle : null,
                cursor: props.onRowSelect ? 'pointer' : 'default'
            }}
            onClick={() => props.onRowSelect && props.onRowSelect(item[props.id])}
        >
            {props.editRoute &&
                <td
                    key={`${item[props.id]}_${"edit"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => navigate(`${props.editRoute}/${item[props.id]}`)}
                    >
                        <Edit />
                    </ActionIcon>
                </td>
            }
            {props.showRoute &&
                <td
                    key={`${item[props.id]}_${"show"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => navigate(`${props.showRoute}/${item[props.id]}`)}
                    >
                        <ExternalLink />
                    </ActionIcon>
                </td>
            }
            {props.entityEmail &&
                <td
                    key={`${item[props.id]}_${"email"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => handleOpenModal(item[props.id])}
                    >
                        <Mail />
                    </ActionIcon>
                    {activeDocumentId === item[props.id] && (
                        <ConfirmEmailSendModal
                            item={item}
                            entityEmail={props.entityEmail}
                            emailName={props.emailName}
                            onClose={handleCloseModal}
                            open={activeDocumentId === item[props.id]}
                        />
                    )}
                </td>
            }
            {props.isAnimalDocument &&
            <>
                <td
                    key={`${item[props.id]}_${"editAnimalDocument"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => EditDocumentModal.open(item[props.id], item.documentDescription, item.documentType, item.owner, item.fileKey, item.originalName)}
                    >
                        <Edit />
                    </ActionIcon>
                    <EditDocumentModal onDocumentUpdate={handleDocumentUpdate} />
                </td>
                <td
                    key={`${item[props.id]}_${"download"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => downloadFile(item.fileKey, item.owner)}
                    >
                        <Download />
                    </ActionIcon>
                </td>
                <td
                    key={`${item[props.id]}_${"open"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => openFileHandler(item.fileKey, item.owner)}
                    >
                        <ExternalLink />
                    </ActionIcon>
                </td>
                <td
                    key={`${item[props.id]}_${"delete"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="red"
                        onClick={() => DeleteDocumentModal.open(item.fileKey, item.owner, item[props.id])}
                    >
                        <Trash />
                    </ActionIcon>
                    <DeleteDocumentModal onDeleteConfirm={handleDeleteConfirm} />
                </td>
            </>
            }
            {props.editInDatabase &&
                <>
                    <td
                        key={`${item[props.id]}_${"editInDatabase"}`}
                        style={{
                            width: `${TABLE_ACTION_WIDTH}px`,
                            padding: 5,
                        }}
                    >
                        <ActionIcon
                            color="blue"
                            onClick={() => EditBreedModal.open(item[props.id], item.label, item.labelEn)}
                        >
                            <Edit />
                        </ActionIcon>
                        <EditBreedModal type={props.type} onDocumentUpdate={handleDocumentUpdate} />
                    </td>

                    <td
                        key={`${item[props.id]}_${"deleteFromDatabase"}`}
                        style={{
                            width: `${TABLE_ACTION_WIDTH}px`,
                            padding: 5,
                        }}
                    >
                        <ActionIcon
                            color="red"
                            onClick={() => AdminDeleteFromDatabase.open(item[props.id])}
                        >
                            <Trash />
                        </ActionIcon>
                        <AdminDeleteFromDatabase type={props.type}/>
                    </td>
                </>
            }
            {props.withDelete &&
                <td
                    key={`${item[props.id]}_${"deleteFromDatabase"}`}
                    style={{
                        width: `${TABLE_ACTION_WIDTH}px`,
                        padding: 5,
                    }}
                >
                    <ActionIcon
                        color="red"
                        onClick={() => AdminDeleteFromDatabase.open(item[props.id])}
                    >
                        <Trash />
                    </ActionIcon>
                    <AdminDeleteFromDatabase type={props.type}/>
                </td>
            }
            {props.dataStructure.map((element) => (
                <td key={`${item[props.id]}_${element.key}`}>
                    <div style={{ minWidth: '100px' }}>
                        {renderField(element, item)}
                    </div>
                </td>
            ))}
        </tr>
    ));

    return (
        <div style={{ overflowX: 'auto' }}>
            {props.isPaymentsList &&
                <Group>
                    <Badge size="xl" variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }} mt={"md"} mb={"md"}>Total: {numberFormat.format(totalDonations)}</Badge>
                    {props.displayPaymentsDetails &&
                        <>
                            <Badge size="xl" variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }} mt={"md"} mb={"md"}>An Tierheim: {numberFormat.format(totalDonationsForAnimalHome)}</Badge>
                            <Badge size="xl" variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} mt={"md"} mb={"md"}>An bestimmte Tiere: {numberFormat.format(totalDonationsForAnimals)}</Badge>
                        </>
                    }
                </Group>
            }

            <Table striped highlightOnHover withBorder>
                <thead>{tableHeader}</thead>
                <tbody>{rows}</tbody>
            </Table>
            {csvData && csvData.length > 0 && !props.onRowSelect &&
                <Group position="right" mt={"md"}>
                    <ExportCSV data={csvData} />
                </Group>
            }
        </div>
    )
}