import { useParams } from "react-router-dom";
import { Animals, FormAdoptPermanently as FormAdoptPermanentlyModel} from "../../models";
import { ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION } from "../../helpers/Routes";
import { FORM_ADOPT_PERMANENTLY, PDF_ADOPT_PERMANENTLY } from "../../helpers/Constants";
import FormAdoptPermanently from "../../formulars/FormAdoptPermanently";
import FormEditPageTemplate from "../../components/FormEditPageTemplate";

/**
 * Page used to Edit a Permanent Adoption form
 * @returns JSX
 */
export default function PageSupportFormsEditPermanentlyAdoption() {

  const { id } = useParams();

  return (
    <FormEditPageTemplate
      id={id}
      pdfName={PDF_ADOPT_PERMANENTLY}
      formTitle={FORM_ADOPT_PERMANENTLY}
      FormModel={FormAdoptPermanentlyModel}
      animalsTypeModel={Animals}
      FormComponent={FormAdoptPermanently}
      viewRoute={ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION}
    />
  );
}