import React from 'react';
import { TextInput, Checkbox, Group, Button, Textarea, Stack, NumberInput } from "@mantine/core";
import DatePickerInput from "../components/DatePickerInput";
import SelectAnimal from '../components/SelectAnimal';
import SelectEntity from '../components/SelectEntity';

export default function FormBringInAnimal({ form, animalhomeId, animals, defaultAnimalSelectValue, defaultEntitySelectValue, animalId, entityId, entities }) {

    if(animalId){
        form.values.animalId = animalId;
    }

    return (
        <Stack>
            <form onSubmit={form.onSubmit()}>
            {!animalId &&
                    <SelectAnimal form={form} animalhomeId={animalhomeId} data={animals} defaultValue={defaultAnimalSelectValue} withAsterisk={true}/>
                }

                {!entityId &&
                    <SelectEntity form={form} animalhomeId={animalhomeId} data={entities} defaultValue={defaultEntitySelectValue} withAsterisk={true}/>
                }

                {/* animal number */}
                <TextInput label="Tiernummer" placeholder="Tiernummer" {...form.getInputProps('animalNumber')} pt="xs"/>

                {/* adoption contract number */}
                <TextInput label="Vertragsnummer" placeholder="Vertragsnummer" {...form.getInputProps('adoptionContractNo')} pt="xs" withAsterisk/>

                {/* is owned by shelter or found */}
                <Checkbox.Group label="Aufnahmeart" {...form.getInputProps('bringInType')} pt="xs" direction="column" withAsterisk>
                    <Checkbox value="foundAnimal" label="Fundtier" />
                    <Checkbox value="taxAnimal" label="Abgabe" pt="xs"/>
                    <Checkbox value="insuranceAnimal" label="Sicherstellung" pt="xs"/>
                    <Checkbox value="seizureAnimal" label="Beschlagnahmung" pt="xs"/>
                    <Checkbox value="custodyAnimal" label="Verwahrung" pt="xs"/>
                    <Checkbox value="anotherCountryAnimal" label="Übernahme aus dem Ausland" pt="xs"/>
                    <Checkbox value="NGOAnimal" label="Übernahme aus einem Tierschutzverein" pt="xs"/>
                </Checkbox.Group>
                <hr></hr>

                {/* other comments */}
                <Textarea placeholder="Sonstige Bemerkungen" label="Sonstige Bemerkungen" {...form.getInputProps('petOtherComments')} pt="xs"/>
                <hr></hr>

                {/* pet adoption fee value as number */}
                <NumberInput
                    decimalSeparator=","
                    thousandsSeparator="."
                    label="Betrag zur Adoption (€)"
                    placeholder="0"
                    precision={2}
                    min={0}
                    defaultValue={0.00}
                    parser={(value) => value.replace(/€\s?/g, '')}
                    formatter={(value) =>
                        !Number.isNaN(parseFloat(value))
                            ? `€ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
                            : '€ '
                    }
                    {...form.getInputProps("petAdoptionFeeNo")}
                    pt="xs"
                />

                {/* pet adoption fee value as text*/}
                <TextInput label="Betrag zur Adoption (in Worten)" placeholder="Null" {...form.getInputProps('petAdoptionFeeText')} pt="xs"/>

                <hr></hr>
                {/* performed action date */}
                <DatePickerInput 
                    label="Eingangsdatum" 
                    placeholder="Eingangsdatum" 
                    form={form} 
                    inputKey="performedActionDate" 
                    pt="xs"
                    withAsterisk
                />

                {/* pdf name*/}
                <TextInput label="Name des Dokuments" placeholder="Name des Dokuments" {...form.getInputProps("customPdfName")} pt="xs" withAsterisk/>

                <Group position="right" mt="md">
                    <Button type="submit">Speichern</Button>
                </Group>
            </form>
        </Stack>
    )
}
