import { useParams } from "react-router-dom";
import * as Routes from "../../helpers/Routes";
import { PersonGroupEntity } from "../../models";
import { PERSON_GROUP_ENTITY } from "../../helpers/Constants";
import { titlePersonGroupEntity } from "../../helpers/Functions";
import { fieldsEntity } from "../../helpers/FormFieldsProcessor";
import EntityViewPageTemplate from "../../components/EntityViewPageTemplate";

/**
 * Page used to View the details of an existing PersonGroup entity for admin and support
 * @returns JSX
 */
export default function PageSupportEntitiesViewPersonGroupEntity() {
    const { id } = useParams();

    const personGroupEntityFields = fieldsEntity(PersonGroupEntity);

    const routes = {
        entityEdit: Routes.ROUTE_SUPPORT_PERSON_GROUP_ENTITY_EDIT,
        individualCreate: Routes.ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_CREATE,
        individualView: Routes.ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_VIEW,
        individualEdit: Routes.ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_EDIT,
        FORM_CHECK_ANIMALVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_BRING_IN_ANIMAL,
        FORM_CHECK_ANIMALEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_BRING_IN_ANIMAL,
    };

    return (
        <EntityViewPageTemplate
            id={id}
            isAdmin={true}
            EntityType={PersonGroupEntity}
            entityFields={personGroupEntityFields}
            filterKey='entityGroupId'
            routes={routes}
            title={PERSON_GROUP_ENTITY}
            getEntityTitle={titlePersonGroupEntity}
        />
    );
}
