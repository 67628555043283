import { Button, Divider, Group, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "./Form";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_DATE, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_FIRST_NAME, VALIDATION_SCHEMA_INSTITUTION, VALIDATION_SCHEMA_LAST_NAME, VALIDATION_SCHEMA_NUMBER, VALIDATION_SCHEMA_PHONE, VALIDATION_SCHEMA_ROLE, VALIDATION_SCHEMA_SALUTATION, VALIDATION_SCHEMA_TITLE } from "../helpers/Validation";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { Check, File, Reload } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../helpers/GlobalUserState";
import { DataStore } from "aws-amplify";
import { PersonGroupIndividual } from "../models";
import DatePickerInput from "./DatePickerInput";

// validation schema with yup
const validationSchema = Yup.object().shape({
    salutation: VALIDATION_SCHEMA_SALUTATION,
    title: VALIDATION_SCHEMA_TITLE,
    firstName: VALIDATION_SCHEMA_FIRST_NAME,
    lastName: VALIDATION_SCHEMA_LAST_NAME,
    birthdate: VALIDATION_SCHEMA_DATE,
    role: VALIDATION_SCHEMA_ROLE,
    phone: VALIDATION_SCHEMA_PHONE,
    email: VALIDATION_SCHEMA_EMAIL,
    idNumber: VALIDATION_SCHEMA_NUMBER,
    idReleasedOn: VALIDATION_SCHEMA_DATE,
    idReleasedBy: VALIDATION_SCHEMA_INSTITUTION
});

/**
 * animal home details page to create new person group entity individual
 * @returns JSX
 */
export default function PersonGroupIndividualNew({ entityId, animalhomeId }) {

    // globals
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();
    const user = useUserState();

    // submit callback
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);

            // create new person group individual
            await DataStore.save(new PersonGroupIndividual({
                salutation: values.salutation,
                title: values.title,
                firstName: values.firstName,
                lastName: values.lastName,
                additionalName: values.additionalName,
                birthdate: values.birthdate,
                role: values.role,
                phone: values.phone,
                email: values.email,
                idNumber: values.idNumber,
                idReleasedOn: values.idReleasedOn,
                idReleasedBy: values.idReleasedBy,
                entityGroupId: entityId,
                owner: user.animalHomeId ? user.animalHomeId : animalhomeId,
                status: "ACTIVE"
            }));

            // show message
            showNotification({ message: "Einzelperson erfolgreich hinzugefügt.", color: 'green', icon: <Check /> });

            // redirect
            navigate(-1);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            salutation: "",
            title: "",
            firstName: "",
            lastName: "",
            additionalName: "",
            birthdate: "",
            role: "",
            phone: "",
            email: "",
            idNumber: "",
            idReleasedOn: "",
            idReleasedBy: ""
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    {/* salutation */}
                    <Select
                        withAsterisk
                        label="Anrede"
                        placeholder="Anrede"
                        data={[
                            { value: 'm', label: 'Herr' },
                            { value: 'f', label: 'Frau' },
                            { value: 'o', label: 'Divers' }
                        ]}
                        {...form.getInputProps('salutation')}
                    />

                    {/* title */}
                    <TextInput
                        withAsterisk
                        label="Titel"
                        placeholder="Titel"
                        {...form.getInputProps('title')}
                    />

                    {/* first name */}
                    <TextInput
                        withAsterisk
                        label="Vorname"
                        placeholder="Vorname"
                        {...form.getInputProps('firstName')}
                    />

                    {/* last name */}
                    <TextInput
                        withAsterisk
                        label="Nachname"
                        placeholder="Nachname"
                        {...form.getInputProps('lastName')}
                    />

                    {/* aditional name */}
                    <TextInput label="Namenszusatz" placeholder="Namenszusatz" {...form.getInputProps('additionalName')} />

                    {/* birthdate */}
                    <DatePickerInput 
                        withAsterisk
                        label="Geburtsdatum" 
                        placeholder="Geburtsdatum" 
                        form={form} 
                        inputKey="birthdate"                       
                    />

                    {/* role */}
                    <TextInput
                        withAsterisk
                        label="Rolle/Position"
                        placeholder="Rolle/Position"
                        {...form.getInputProps('role')}
                    />

                    {/* phone */}
                    <TextInput
                        withAsterisk
                        label="Telefonnummer"
                        placeholder="Telefonnummer"
                        {...form.getInputProps('phone')}
                    />

                    {/* email */}
                    <TextInput
                        withAsterisk
                        label="E-Mail-Adresse"
                        placeholder="E-Mail-Adresse"
                        {...form.getInputProps('email')}
                    />

                    {/* id number */}
                    <TextInput 
                        withAsterisk
                        label="Personalausweis-/Reisepassnummer" 
                        placeholder="Personalausweis-/Reisepassnummer" 
                        {...form.getInputProps('idNumber')} 
                    />

                    {/* id released on */}
                    <DatePickerInput
                        withAsterisk
                        label="Personalausweis/Reisepass ausgestellt am" 
                        placeholder="Personalausweis/Reisepass ausgestellt am" 
                        form={form} 
                        inputKey="idReleasedOn" 
                    />

                    {/* id released by */}
                    <TextInput 
                        withAsterisk
                        label="Personalausweis/Reisepass ausgestellt durch" 
                        placeholder="Personalausweis/Reisepass ausgestellt durch" 
                        {...form.getInputProps('idReleasedBy')}
                    />
                    
                    <Divider />

                    <Group position='apart'>
                        <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                        <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}