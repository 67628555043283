import React, { useState } from 'react';
import { Modal, Button, Group, TextInput } from '@mantine/core';
import { DataStore } from 'aws-amplify';
import { useForm } from './Form';

export default function EditBreedModal(props, { onDocumentUpdate }) {

    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState(null);

    const submitCallback = async (values) => {
        try {
            // update item
            const original = await DataStore.query(props.type, itemId);
            await DataStore.save(props.type.copyOf(original, updated => {
                updated.label = values.label;
                updated.labelEn = values.labelEn;
            }));

            handleCloseModal();
        } catch (error) {
            console.error('Error while saving the edited breed:', error);
        } finally {
            closeModal();
        }
    }

    // form hook
    const form = useForm({
        initialValues: {
            label: "",
            labelEn: ""
        },
        submitCallback: submitCallback,
    });

    const handleCloseModal = () => {
        form.reset();
        setOpen(false);
    }

    const closeModal = () => {
        setOpen(false);
    };

    EditBreedModal.open = (id, label, labelEn) => {
        form.setValues({ label, labelEn });
        setOpen(true);
        setItemId(id);
    };

    return (
        <Modal opened={open} onClose={closeModal} title="Edit Breed">
            <form onSubmit={form.onSubmit()}>
                <TextInput
                    label="Label"
                    placeholder="Label"
                    {...form.getInputProps('label')}
                />

                <TextInput
                    label="Label (english)"
                    placeholder="Label (english)"
                    {...form.getInputProps('labelEn')}
                />

                <Group position="right" mt="md">
                    <Button type="submit">Save</Button>
                </Group>
            </form>
        </Modal>
    );
}