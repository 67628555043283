import { Animals, FormCheckAnimal as FormCheckAnimalModel } from "../../models";
import { FORM_CHECK_ANIMAL, PDF_CHECK_ANIMAL } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import FormCheckAnimal from "../../formulars/FormCheckAnimal";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import { validationForFolowUpCheckDashboard } from "../../helpers/FormValidations";

/**
 * Page used to create a new Follow up Check form from dashboard directly
 * @returns JSX
 */
export default function PageAnimalHomeFormsCreateFollowupCheckForDashboard() {
    const user = useUserState();

    return (
        <FormCreatePageTemplate
            FormComponent={FormCheckAnimal}
            FormName={FormCheckAnimalModel.name}
            animalHomeId={user.animalHomeId}
            formTitle={FORM_CHECK_ANIMAL}
            animalsTypeModel={Animals}
            initialValues={initialValuesForm(FormCheckAnimalModel)}
            pdfName={PDF_CHECK_ANIMAL}
            viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK}
            validationSchema={validationForFolowUpCheckDashboard}
        />
    )
}