import { useParams } from "react-router-dom";
import { ROUTE_ANIMALHOME_FORMULARS_EDIT_PERMANENTLY_ADOPTION } from "../../helpers/Routes";
import { FormAdoptPermanently } from "../../models";
import FormViewTemplate from "../../components/FormViewTemplate";
import { FORM_ADOPT_PERMANENTLY } from "../../helpers/Constants";
import { fieldsForm } from "../../helpers/FormFieldsProcessor";

/**
 * Page used to View the details of an existing Permanent Adoption form
 * @returns JSX
 */
export default function PageAnimalHomeFormsViewPermanentlyAdoption() {

  const { id } = useParams();

  const formAdoptPermanentlyFields = fieldsForm(FormAdoptPermanently);

  return (
    <FormViewTemplate
      id={id}
      formModel={FormAdoptPermanently}
      formName={FORM_ADOPT_PERMANENTLY}
      formFields={formAdoptPermanentlyFields}
      editFormRoute={ROUTE_ANIMALHOME_FORMULARS_EDIT_PERMANENTLY_ADOPTION}
    />
  );
}
