import React, { useEffect, useState } from 'react'
import { Button, Group, Modal, Select, Title } from '@mantine/core'
import { List } from 'tabler-icons-react'
import ListingTemplate from './ListingTemplate';
import { singleEntityDataStructure } from './ListingDataStructures';
import { PREDICATE_EQUALS } from '../helpers/Constants';
import { PersonGroupIndividual, SinglePersonEntity } from '../models';
import { SortDirection } from 'aws-amplify';

export default function SelectEntity({ form, animalhomeId, data, defaultValue, withAsterisk }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntityId, setSelectedEntitylId] = useState();

  useEffect(() => {
    if (defaultValue) {
      setSelectedEntitylId(defaultValue);
      form.setValues({
        ...form.values,
        entityId: defaultValue
      });
    }
  },
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [defaultValue]);
  

  const handleEntitySelect = (entityId) => {
      setSelectedEntitylId(entityId);
      form.setValues({
        ...form.values,
        entityId: entityId
      });
    setIsModalOpen(false);
  };

  const filterPredicates = [
    { key: "owner", value: animalhomeId, type: PREDICATE_EQUALS },
  ]

  const sortFieldInputData = [
    { value: 'createdAt', label: 'Erstellzeit' },
    { value: 'updatedAt', label: 'Aktualisierungszeit' },
  ]

  return (
    <>
      <Group>
        <Select
          withAsterisk={withAsterisk}
          label="Entität"
          placeholder="Entität"
          nothingFound="Keine Ergebnisse gefunden"
          searchable
          clearable
          data={data}
          {...form.getInputProps("entityId")}
          value={selectedEntityId}
          onChange={(e) => {handleEntitySelect(e)}}
          icon={<List size="1rem" />}
          style={{ flex: 1 }}
          pt="xs"
        />
        <Button 
          label="Entität suchen"
          onClick={() => setIsModalOpen(true)}
          style={{"marginTop" : "32px"}}
        >
          Erweiterte Suche
        </Button>
      </Group>

      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Entität suchen"
        size="90%"
      >
        
        <Title order={5}>Einzelperson</Title>
        <ListingTemplate
            animalhomeId={animalhomeId}
            dataStructure={singleEntityDataStructure}
            filterPredicates={filterPredicates}
            listTypeModel={SinglePersonEntity}
            sortDirection={SortDirection.DESCENDING}
            sortField="updatedAt"
            sortFieldInputData={sortFieldInputData}
            onRowSelect={handleEntitySelect}
            sortByStatus={SortDirection.ASCENDING}
            filterFields={['firstName', 'lastName', 'phone', 'email']}
        />
       
        <Title order={5} pt="md">Personen</Title>
        <ListingTemplate
            animalhomeId={animalhomeId}
            dataStructure={singleEntityDataStructure}
            filterPredicates={filterPredicates}
            listTypeModel={PersonGroupIndividual}
            sortDirection={SortDirection.DESCENDING}
            sortField="updatedAt"
            sortFieldInputData={sortFieldInputData}
            onRowSelect={handleEntitySelect}
            sortByStatus={SortDirection.ASCENDING}
            filterFields={['firstName', 'lastName', 'phone', 'email']}
        />
      </Modal>
    </>
  )
}
