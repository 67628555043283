import * as Yup from 'yup';

// Follow up check
const followUpCheckBase = Yup.object({
    deliveredOn: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable()
    .required('Pflichtfeld'),

    inspectionType: Yup.string().required('Pflichtfeld'),

    typeOfKeeping: Yup.array()
    .transform(function(value, originalValue) {
        return (typeof originalValue === 'string' && originalValue === '') ? [] : value;
    })
    .of(Yup.string())
    .min(1, 'Pflichtfeld')
    .required('Pflichtfeld'),

    lookingImpressions: Yup.string().required('Pflichtfeld'),
    environment: Yup.string().required('Pflichtfeld'),

    animalPartners: Yup.array()
    .transform(function(value, originalValue) {
        return (typeof originalValue === 'string' && originalValue === '') ? [] : value;
    })
    .of(Yup.string())
    .min(1, 'Pflichtfeld')
    .required('Pflichtfeld'),

    petBehaviorOwner: Yup.string().required('Pflichtfeld'),

    petBehaviorStrangers: Yup.array()
    .transform(function(value, originalValue) {
        return (typeof originalValue === 'string' && originalValue === '') ? [] : value;
    })
    .of(Yup.string())
    .min(1, 'Pflichtfeld')
    .required('Pflichtfeld'),

    overallImpression: Yup.string().required('Pflichtfeld'),
    needsAnotherCheck: Yup.string().required('Pflichtfeld'),
    controlMadeBy: Yup.string().required('Pflichtfeld'),

    dateOfControl: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable()
    .required('Pflichtfeld'),

    customPdfName: Yup.string().required('Pflichtfeld'),
});

export const validationForFolowUpCheckDashboard = followUpCheckBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
    animalId: Yup.string().required('Pflichtfeld'),
});

export const validationForFolowUpCheckAnimal = followUpCheckBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
});

export const validationForFolowUpCheckEntity = followUpCheckBase.shape({
    animalId: Yup.string().required('Pflichtfeld'),
});

// Temporarily adoption
const formAdoptTemporarilyBase = Yup.object({
    petAdoptedUntill: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable()
    .required('Pflichtfeld'),

    performedActionDate: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable()
    .required('Pflichtfeld'),

    customPdfName: Yup.string().required('Pflichtfeld'),
});

export const validationForAdoptTemporarilyDashboard = formAdoptTemporarilyBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
    animalId: Yup.string().required('Pflichtfeld'),
});

export const validationForAdoptTemporarilyAnimal = formAdoptTemporarilyBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
});

export const validationForAdoptTemporarilyEntity = formAdoptTemporarilyBase.shape({
    animalId: Yup.string().required('Pflichtfeld'),
});

//Permanently adoption
const formAdoptPermanentlyBase = Yup.object({
    adoptionContractNo: Yup.string().required('Pflichtfeld'),
    isOwnedByShelter: Yup.string().required('Pflichtfeld'),

    performedActionDate: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable()
    .required('Pflichtfeld'),

    customPdfName: Yup.string().required('Pflichtfeld'),
});

export const validationForAdoptPermanentlyDashboard = formAdoptPermanentlyBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
    animalId: Yup.string().required('Pflichtfeld'),
});

export const validationForAdoptPermanentlyAnimal = formAdoptPermanentlyBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
});

export const validationForAdoptPermanentlyEntity = formAdoptPermanentlyBase.shape({
    animalId: Yup.string().required('Pflichtfeld'),
});

// Bring in
const formBringInAnimalBase = Yup.object({
    adoptionContractNo: Yup.string().required('Pflichtfeld'),

    bringInType: Yup.array()
    .transform(function(value, originalValue) {
        return (typeof originalValue === 'string' && originalValue === '') ? [] : value;
    })
    .of(Yup.string())
    .min(1, 'Pflichtfeld')
    .required('Pflichtfeld'),

    performedActionDate: Yup.date().transform((value, originalValue) => 
          originalValue === "" ? null : value
      )
      .nullable()
      .required('Pflichtfeld'),
    customPdfName: Yup.string().required('Pflichtfeld'),
});

export const validationForBringInAnimalDashboard = formBringInAnimalBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
    animalId: Yup.string().required('Pflichtfeld'),
});

export const validationForBringInAnimalAnimal = formBringInAnimalBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
});

export const validationForBringInAnimalEntity = formBringInAnimalBase.shape({
    animalId: Yup.string().required('Pflichtfeld'),
});

// Donations
const formDonationsBase = Yup.object({
    expenseType: Yup.string().required('Pflichtfeld'),
    amount: Yup.number().typeError('Muss eine Zahl sein.').required('Pflichtfeld'),
    expensePeriod: Yup.string().required('Pflichtfeld'),
    
    startDate: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable()
    .required('Pflichtfeld'),
    
    endDate: Yup.date().transform((value, originalValue) =>
        originalValue === "" ? null : value
    )
    .nullable()
    .when('expenseType', (expenseType, schema) => {
        if (Array.isArray(expenseType) && expenseType.some(type => ['donationRecurent', 'membershipFee', 'sponsorshipRecurent'].includes(type))) {
            return schema.required('Enddatum für wiederholende Spenden, Mitgliedsbeiträge, wiederholendes Sponsoring verpflichten. ');
        } else {
            return schema.nullable();
        }
    }),
});

export const validationForDonationsDashboard = formDonationsBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
});

export const validationForDonationsAnimal = formDonationsBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
});

export const validationForDonationsEntity = formDonationsBase.shape({
    entityId: Yup.string().required('Pflichtfeld'),
});

// Animals
export const animalsValidationSchema = Yup.object({
    raceID: Yup.string().required('Pflichtfeld'),
    breedID: Yup.string().required('Pflichtfeld'),
    name: Yup.string().required('Pflichtfeld'),
    color: Yup.string().required('Pflichtfeld'),
    gender: Yup.string().required('Pflichtfeld'),
    birthDate: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable().required('Pflichtfeld'),
    bringInDate: Yup.date().transform((value, originalValue) => 
        originalValue === "" ? null : value
    )
    .nullable().required('Pflichtfeld'),
    status: Yup.string().required('Pflichtfeld'),
});

// Animal documents
export const animalsDocumentsValidation = Yup.object({
    updatedScannedDocuments: Yup.array().of(
        Yup.object().shape({
          typeOfDocument: Yup.string().required('Pflichtfeld'),
          description: Yup.string().required('Pflichtfeld'),
        })
    )
});