import { Accordion, ActionIcon, Button, Divider, Group, Menu, Select, Stack, Table, TextInput, Title, UnstyledButton } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Check, Filter, Plus, Reload, Settings, Star, StarOff, UserCheck, UserOff } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import { ADMIN_API_ADMIN_ADDUSERTOGROUP, ADMIN_API_ADMIN_DISABLEUSER, ADMIN_API_ADMIN_ENABLEUSER, ADMIN_API_ADMIN_LISTSUPPORTUSERS, ADMIN_API_ADMIN_REMOVEUSERFROMGROUP, API_ADMIN_NAME, API_GET, API_POST, executeApiCall } from "../../helpers/APIHelper";
import { CREATE, OPTIONS_YESNO_WITHALL, OPTION_ALL, OPTION_NO, OPTION_YES, USERGROUPS_ALL, USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL } from "../../helpers/Validation";
import { showNotification } from "@mantine/notifications";
import { ROUTE_ADMIN_SUPPORTUSERS_NEW } from "../../helpers/Routes";
import BadgeTimestamp from "../../components/BadgeTimestamp";

// validation schema with yup for user filter
const validationSchemaUserFilter = Yup.object().shape({
    enabled: VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL,
});

// initial values for user filter
const initialValuesUserFilter = {
    name: "",
    email: "",
    enabled: OPTION_ALL,
    group: USERGROUPS_ALL,
};

/**
 * implementation of page to administer support users
 * @returns JSX
 */
export default function PageAdminSupportUsers() {

    // globals
    const usersRef = useRef([]);
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const navigate = useNavigate();
    const [filteredUsers, setFilteredUsers] = useState([]);

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        try {
            const users = await executeApiCall(API_ADMIN_NAME, API_GET, ADMIN_API_ADMIN_LISTSUPPORTUSERS);
            usersRef.current = users;
            filterUsers();
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * submit callback for user filter form
     * @param {object} values form values
     */
    const filterUserSubmitCallback = async () => {
        try {
            setLoading(LOADING_SHOW);
            filterUsers();

        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * filters users depending on set filter in filter form
     */
    const filterUsers = () => {
        // start with all users
        var filteredUsers = [...usersRef.current];
        const values = form.values;

        // filter email
        if (values.email) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.email.toLowerCase().includes(values.email.toLowerCase());
            });
        }

        // filter enabled state
        if (values.enabled === OPTION_YES) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === true;
            });
        }
        else if (values.enabled === OPTION_NO) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === false;
            });
        }

        // filter group
        if (values.group !== USERGROUPS_ALL) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.groups.includes(values.group);
            });
        }

        setFilteredUsers(filteredUsers);
    }

    /**
     * reset callback for user filter
     */
    const resetUserFilterCallback = () => {
        setFilteredUsers(usersRef.current);
    }

    /**
     * changes the users enabled status
     * @param {string}  username    username to update
     * @param {boolean} enabled     enabled status
     */
    const changeUserEnabled = async (username, enabled) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // update user
            await executeApiCall(
                API_ADMIN_NAME,
                API_POST,
                enabled ? ADMIN_API_ADMIN_ENABLEUSER : ADMIN_API_ADMIN_DISABLEUSER,
                {
                    "username": username,
                }
            )
            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                newUsers[index].enabled = enabled;
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            showNotification({ message: enabled ? "User aktiviert." : "User deaktiviert", color: 'green', icon: <Check /> });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * changes the admin status of a user
     * @param {string}  username  username to update
     * @param {boolean} admin     admin status
     */
    const changeAdminPermission = async (username, admin) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // set new status
            await executeApiCall(
                API_ADMIN_NAME,
                API_POST,
                admin ? ADMIN_API_ADMIN_ADDUSERTOGROUP : ADMIN_API_ADMIN_REMOVEUSERFROMGROUP,
                {
                    "username": username,
                    "groupname": USERGROUP_ADMIN
                }
            )

            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                if (admin) {
                    newUsers[index].groups = [USERGROUP_ADMIN, USERGROUP_SUPPORT];
                }
                else {
                    newUsers[index].groups = [USERGROUP_SUPPORT];
                }
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            if (admin) {
                showNotification({ message: "Administrator Berechtigungen aktiviert.", color: 'green', icon: <Check /> });
            } else {
                showNotification({ message: "Administrator Berechtigungen deaktiviert", color: 'green', icon: <Check /> });
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook for user filter
    const form = useForm({
        validationSchema: validationSchemaUserFilter,
        initialValues: initialValuesUserFilter,
        submitCallback: filterUserSubmitCallback,
        resetCallback: resetUserFilterCallback,
    });

    /**
     * wrapper to map users in table
     */
    const rows = filteredUsers.map((element) => {
        return (
            <tr key={element.username}>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {element.email}
                    </div>
                </td>
                
                <td>               
                    <div style={{ minWidth: '100px' }}>
                        {element.enabled ? "Ja" : "Nein"}
                    </div>
                </td>
                <td>                
                    <div style={{ minWidth: '100px' }}>
                        {element.groups.join(", ")}
                    </div>
                </td>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {element.status}
                    </div>
                </td>
                <td>          
                    <div style={{ minWidth: '100px' }}>
                        {<BadgeTimestamp time={element.createdAt} />}
                    </div>
                </td>
                <td>                   
                    <div style={{ minWidth: '100px' }}>
                        {<BadgeTimestamp time={element.updatedAt} />}
                    </div>
                </td>
                <td>
                    <Menu position="bottom-end" width={220} withinPortal>
                        <Menu.Target>
                            <ActionIcon><Settings size={18} /></ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {element.enabled === true ?
                                <Menu.Item
                                    icon={<UserOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, false)}
                                >
                                    User deaktivieren
                                </Menu.Item>
                                :
                                <Menu.Item
                                    icon={<UserCheck size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, true)}
                                >
                                    User aktivieren
                                </Menu.Item>
                            }

                            {element.groups.includes(USERGROUP_ADMIN) ?
                                <Menu.Item
                                    icon={<StarOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, false)}
                                >
                                    Admin-Zugang entziehen
                                </Menu.Item>
                                :
                                <Menu.Item
                                    icon={<Star size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, true)}
                                >
                                    Admin-Zugang erlauben
                                </Menu.Item>
                            }
                        </Menu.Dropdown>
                    </Menu>
                </td>
            </tr>
        )
    });

    return (
        <Stack>
            <Group position="apart">
                <Title>Support Benutzer</Title>
                <Button
                    color="green"
                    leftIcon={<Plus />}
                    onClick={() => navigate(ROUTE_ADMIN_SUPPORTUSERS_NEW)}
                >
                    Neuen Support Benutzer {CREATE}
                </Button>
            </Group>
            <Divider />

            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onSubmit={form.onSubmit()}
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label="E-Mail-Adresse"
                                    placeholder="E-Mail-Adresse"
                                    {...form.getInputProps('email')}
                                />

                                <Select
                                    label="Aktiv"
                                    placeholder="..."
                                    data={OPTIONS_YESNO_WITHALL}
                                    {...form.getInputProps('enabled')}
                                />

                                <Select
                                    label="Berechtigungsgruppe"
                                    placeholder="Berechtigungsgruppe wählen..."
                                    data={[
                                        { value: USERGROUPS_ALL, label: 'Alle' },
                                        { value: USERGROUP_SUPPORT, label: 'Support' },
                                        { value: USERGROUP_ADMIN, label: 'Admin' },
                                    ]}
                                    {...form.getInputProps('group')}
                                />

                                <Group mt="sm" position="apart">
                                    <Button variant="outline" color="yellow" leftIcon={<Reload />} type="reset">Zurücksetzen</Button>
                                    <Button variant="outline" color="green" leftIcon={<Filter />} type="submit">Filtern</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <div style={{ overflowX: 'auto' }}>
                <Table striped highlightOnHover withBorder>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '100px' }}>E-Mail</th>
                            <th style={{ minWidth: '100px' }}>Aktiv</th>
                            <th style={{ minWidth: '100px' }}>Berechtigungsgruppen</th>
                            <th style={{ minWidth: '100px' }}>Status</th>
                            <th style={{ minWidth: '100px' }}>Erstellt am</th>
                            <th style={{ minWidth: '100px' }}>Zuletzt geändert am</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>
        </Stack>
    )
}