import t from "./Translations";

/**
 * Format Title function for formatting a string title 
 * by capitalizing the first letter of the string 
 * and adding spaces between the words of the string
 * e.g. aRandomTitle -> A Random Title
 * @param {string} title
 * @returns {string}
 */
export function formatTitle(title) {
  return title.charAt(0).toUpperCase() + title.slice(1).replace(/([A-Z])/g, ' $1').trim();
}

/**
 * Trannable function for formatting a string
 * @param {string} title
 * @returns {string}
 */
export function translateTitle(title) {
  const titleTranslations = {
    name: "Name",
    race: "Tierart",
    breed: "Rasse",
    firstName: "Vorname",
    lastName: "Nachname",
    phone: "Telefonnummer",
    type: "Typ",
    originalName: "Dateiname",
    documentDescription: "Beschreibung",
  }
  
  return title in titleTranslations ? titleTranslations[title] : formatTitle(title);
}

/**
 * A funtion to get the title for a single person entity
 * @param {string} entity
 * @returns {string} the title string for a single person entity
 */
export function titleSinglePersonEntity(entity) {
  return t(entity.salutation, 'salutation') + " " + 
  entity.firstName + " " + entity.lastName;
}

/**
 * A funtion to get the title for a person group entity
 * @param {string} entity
 * @returns {string} the title string for a person group entity
 */
export function titlePersonGroupEntity(entity) {
  return entity.type + " " + entity.name;
}

export function displayDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  if(dateString){
    return `${day}-${month}-${year}`;
  }
}



export function getItemLinkAndName(isAdmin, field, data){
  let newPreprocessedItems = {};

      if (field === "entityId") {     
        newPreprocessedItems = {
            entityLink: `${isAdmin ? '/support/animalhome' : ''}/entity/${data.entityGroupId ? 'individual' : 'single'}/view/${data.id}`,
            entityName: `${data.lastName} ${data.firstName}`
        };
      }

      if (field === "animalId") {
        newPreprocessedItems = {
            animalLink: isAdmin ? `/support/animalhome/animals/view/animal/${data.id}` : `/animals/view/animal/${data.id}`,
            animalName: data.name
        };
      }

  return newPreprocessedItems;
}

