import React, { useEffect, useState } from 'react';
import { singleAnimalForms } from './ListingDataStructures';
import { PREDICATE_CONTAINS, PREDICATE_EQUALS, getFilterPredicates } from '../helpers/Predicates';
import { FormAdoptPermanently, FormAdoptTemporarily, FormBringInAnimal, FormCheckAnimal, PersonGroupIndividual, SinglePersonEntity } from '../models';
import { DataStore, SortDirection } from 'aws-amplify';
import { Accordion, Button, Group, Stack, TextInput } from '@mantine/core';
import { ClipboardList, Filter, Reload } from 'tabler-icons-react';
import { useForm } from './Form';
import List from './List';
import SelectEntity from './SelectEntity';
import SortFieldInput from './SortFieldInput';
import SortDirectionInput from './SortDirectionInput';
import { FORM_NAMES } from '../helpers/Constants';
import * as Routes from "../helpers/Routes";

export default function AnimalViewForms({ animalId, animalhomeId, isAdmin }) {

    const [entity, setEntity] = useState(null);

    const form = useForm({
        initialValues: {
            entityId: "",
            customPdfName: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    const getFilter = () => {
        return getFilterPredicates([
            { key: "owner", value: animalhomeId, type: PREDICATE_EQUALS },
            { key: "animalId", value: animalId, type: PREDICATE_EQUALS },
            { key: "entityId", value: form.values.entityId, type: PREDICATE_CONTAINS },
            { key: "customPdfNameNormalized", value: form.values.customPdfName.toLowerCase(), type: PREDICATE_CONTAINS },
        ]);
    }

    useEffect(() => {
        async function prepareFilters(){
            const singlePersonEntities = await DataStore.query(SinglePersonEntity, (c) => c.owner.eq(animalhomeId));
            const personGroupIndividuals = await DataStore.query(PersonGroupIndividual, (c) => c.owner.eq(animalhomeId));
            const entities = singlePersonEntities.concat(personGroupIndividuals);
            setEntity(entities.map( entity => ({
                value: entity.id,
                label: entity.lastName+" "+entity.firstName
            })));
        }

        prepareFilters();
    },
        [animalhomeId]
    );

    const formTypes = {
        FORM_CHECK_ANIMAL: FormCheckAnimal,
        FORM_ADOPT_TEMPORARILY: FormAdoptTemporarily,
        FORM_ADOPT_PERMANENTLY: FormAdoptPermanently,
        FORM_BRING_IN_ANIMAL: FormBringInAnimal,
    }

    const routes = {
        FORM_CHECK_ANIMALVIEW: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_VIEW_FOLLOWUP_CHECK : Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYVIEW: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION : Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYVIEW: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION : Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALVIEW: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_VIEW_BRING_IN_ANIMAL : Routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL,
        FORM_CHECK_ANIMALEDIT: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_EDIT_FOLLOWUP_CHECK : Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYEDIT: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_EDIT_TEMPORARILY_ADOPTION : Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYEDIT: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_EDIT_PERMANENTLY_ADOPTION : Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALEDIT: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_EDIT_BRING_IN_ANIMAL : Routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_BRING_IN_ANIMAL,
    };

    return (
        entity &&
        <>
        {Object.keys(FORM_NAMES).map(formName => (
            <Accordion key={formName} variant="separated">
                <Accordion.Item value="forms">
                    <Accordion.Control icon={<ClipboardList />}>{FORM_NAMES[formName]}</Accordion.Control>
                    <Accordion.Panel>
                        <Stack>
                            <Accordion variant="separated">
                                <Accordion.Item value="filter">
                                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                                    <Accordion.Panel>
                                        <form
                                            onReset={form.onReset}
                                        >
                                            <Stack>
                                                <SelectEntity form={form} animalhomeId={animalhomeId} data={entity}/>

                                                <TextInput
                                                    label="Dateiname"
                                                    placeholder="Dateiname"
                                                    {...form.getInputProps('customPdfName')}
                                                />

                                                <SortFieldInput
                                                    data={[
                                                        { value: 'entityId', label: 'Entität' },
                                                        { value: 'createdAt', label: 'Erstellzeit' },
                                                        { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                                    ]}
                                                    {...form.getInputProps('sortField')}
                                                />

                                                <SortDirectionInput
                                                    {...form.getInputProps('sortDirection')}
                                                />

                                                <Group position='apart'>
                                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                                </Group>
                                            </Stack>
                                        </form>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                            <List
                                type={formTypes[formName]}
                                id="id"
                                dataStructure={singleAnimalForms}
                                filter={getFilter()}
                                sort={s => s[form.values.sortField](form.values.sortDirection)}
                                editRoute={routes[formName + 'EDIT']}
                                showRoute={routes[formName + 'VIEW']}
                            />
                        </Stack>                      
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        ))}

        </>
    )
}
