import React, { useState } from 'react'
import { Button, Group, Modal } from '@mantine/core';
import { API_FILE_NAME, API_POST, FILE_API_DELETE, PRIVATE_PROTECTION_LEVEL, executeApiCall } from '../helpers/APIHelper';
import { DataStore } from 'aws-amplify';
import { AnimalsDocuments } from '../models';

export default function DeleteDocumentModal({ onDeleteConfirm }) {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState({ fileKey: null, owner: null, fileId: null });

    DeleteDocumentModal.open = (fileKey, owner, fileId) => {
        setData({ fileKey, owner, fileId });
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        try {
            // delete file from s3
            await executeApiCall(API_FILE_NAME, API_POST, FILE_API_DELETE, {
                protectionLevel: PRIVATE_PROTECTION_LEVEL,
                identityId: data.owner,
                key: data.fileKey
            });
    
            // delete file from database
            const documentToDelete = await DataStore.query(AnimalsDocuments, data.fileId);
            if (documentToDelete) {
                await DataStore.delete(documentToDelete);
            }

            closeModal();
            if (onDeleteConfirm) {
                onDeleteConfirm();
            }
        } catch (error) {
            console.error('Error while deleting animal document:', error);
        } finally{
            closeModal();
        }
    };

    return (
        <Modal opened={open} onClose={closeModal} title="Dokument löschen">
            <div style={{ marginBottom: 15 }}>
                Möchten Sie dieses Dokument wirklich löschen?
            </div>
            <Group position="right" spacing="sm">
                <Button variant="default" onClick={closeModal}>
                    Abbrechen
                </Button>
                <Button color="red" onClick={confirmDelete}>
                    Entfernen
                </Button>
            </Group>
        </Modal>
    )
}
