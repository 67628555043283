export const AnimalRacesDatastructure = [
    { key: "id", title: "ID" },
    { key: "label", title: "Label" },
    { key: "labelEn", title: "Label (english)" },
    { key: "createdAt", title: "Created at", type: "timestamp" },
    { key: "updatedAt", title: "Updated at", type: "timestamp" },
    // { key: "breeds", title: "Animal breed" },
]

export const AnimalBreedsDatastructure = [
    { key: "id", title: "ID" },
    { key: "label", title: "Label" },
    { key: "labelEn", title: "Label (english)" },
    { key: "race.label", title: "Animal race" },
]

export const ApiTokenDataStructure = [
    { key: "token", title: "Token" },
    { key: "animalHomeId", title: "Animal Home ID" },
    { key: "animalhome.name", title: "Animal Home" },
    { key: "createdAt", title: "Created at", type: "timestamp" },
    { key: "updatedAt", title: "Updated at", type: "timestamp" },
]