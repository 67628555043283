import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { ROUTE_NOT_FOUND } from "../helpers/Routes";
import { useEffect, useState } from "react";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { DataStore } from "aws-amplify";
import { CREATE } from "../helpers/Constants";
import PaymentCreateTemplate from "./PaymentCreateTemplate";

/**
 * Create Form Template Page to create a new animal home form
 * @returns JSX
 */
export default function PaymentCreatePageTemplate({
    animalHomeId,
    entityId,
    entityTypeModel,
    singleEntityTypeModel,
    individualEntityTypeModel,
    animalsTypeModel,
    pageTitle,
    initialValues,
    viewRoute,
    FormComponent,
    FormName,
    animalId,
    validationSchema
}) {

    // globals
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const [entity, setEntity] = useState(null);
    const [animals, setAnimals] = useState(null);

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (entityId) {
                // get item
                setLoading(LOADING_SHOW);

                const entity = await DataStore.query(entityTypeModel, entityId);

                if (!entity) {
                    navigate(ROUTE_NOT_FOUND);
                }

                setEntity(entity);
            } else{
                // provide all entities to choose from
                const singlePersonEntities = await DataStore.query(singleEntityTypeModel, (c) => c.owner.eq(animalHomeId));
                const personGroupIndividuals = await DataStore.query(individualEntityTypeModel, (c) => c.owner.eq(animalHomeId));
                const entities = singlePersonEntities.concat(personGroupIndividuals);
                setEntity(entities.map( entity => ({
                    value: entity.id,
                    label: entity.lastName+" "+entity.firstName
                })));
            }

            if(!animalId){
                // provide all animals to choose from
                const animals = await DataStore.query(animalsTypeModel, (c) => c.owner.eq(animalHomeId));
                setAnimals(animals.map( animal => ({
                    value: animal.id,
                    label: animal.name
                })));
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entityId]
    );

    return (
        <Stack>
            <Group position="apart">
                <Title>
                    {pageTitle} {CREATE}
                </Title>
                <Group>
                    <Button
                        leftIcon={<ArrowLeft size={14} />}
                        onClick={() => navigate(-1, { replace: true })}
                        color="yellow"
                    >
                        Zurück
                    </Button>
                </Group>
            </Group>
            <Divider />

            <PaymentCreateTemplate
                animalId={animalId}
                animals={animals}
                entityId={entityId}
                entities={entity}
                animalHomeId={animalHomeId}
                FormComponent={FormComponent}
                FormName={FormName}
                initialValues={initialValues}
                viewRoute={viewRoute}
                validationSchema={validationSchema}
            />
        </Stack>
    );
}
