import React from 'react';
import { useForm } from "./Form.js";
import { useNavigate } from 'react-router-dom';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState.js';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState.js';
import { DataStore } from 'aws-amplify';
import { AnimalBreeds, AnimalRaces, Animals } from '../models/index.js';

export default function AnimalCreateTemplate({ animalHomeId, viewRoute, initialValues, FormComponent, validationSchema }) {

    // globals
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();

    const submitCallback = async (values) => {

        values.owner = animalHomeId;

        try {
            setLoading(LOADING_SHOW);

            const race = await DataStore.query(AnimalRaces, values.raceID);
            const breed = await DataStore.query(AnimalBreeds, values.breedID)


            const animal = await DataStore.save(new Animals({
                owner: animalHomeId,
                entityId: values.entityId || null,
                entityGroupId: values.entityGroupId || null,
                tassoRegNo: values.tassoRegNo || null,
                euRegNo: values.euRegNo || null,
                contractNumber: values.contractNumber || null,
                race: race,
                breed: breed,
                name: values.name || null,
                color: values.color || null,
                gender: values.gender || null,
                castratedPet: values.castratedPet || false,
                birthDate: values.birthDate || null,
                bringInDate: values.bringInDate || null,
                chip: values.chip || null,
                leftEarTattoo: values.leftEarTattoo || null,
                rightEarTattoo: values.rightEarTattoo || null,
                otherComments: values.otherComments || null,
                status: values.status || null,
                leaveDate: values.leaveDate || null,
                imageMain: values.imageMain || null,
                imagesAdditional: values.imagesAdditional || null,
                medicalHistory: values.medicalHistory || null,
                behavioralAssessment: values.behavioralAssessment || null,
                specialCareNeeds: values.specialCareNeeds || null,
            }))

            if(animal){
                navigate(`${viewRoute}/${animal.id}`, { replace: true });
            }

        } catch (error) {
            console.error('Error:', error);
            setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    };

    // creating form object
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: initialValues,
        submitCallback: submitCallback
    });

    return (
        <FormComponent form={form} animalhomeId={animalHomeId}/>
    )
}
