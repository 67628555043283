import { Animals, FormAdoptTemporarily as FormAdoptTemporarilyModel } from "../../models";
import { FORM_ADOPT_TEMPORARILY, PDF_ADOPT_TEMPORARILY } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import FormAdoptTemporarily from "../../formulars/FormAdoptTemporarily";
import { validationForAdoptTemporarilyDashboard } from "../../helpers/FormValidations";

/**
 * Page used to create a new Temporarily Adoption form from dashboard directly
 * @returns JSX
 */
export default function PageAnimalHomeFormsCreateTemporarilyAdoptionForDashboard() {
    const user = useUserState();

    return (
        <FormCreatePageTemplate
            FormComponent={FormAdoptTemporarily}
            FormName={FormAdoptTemporarilyModel.name}
            animalHomeId={user.animalHomeId}
            formTitle={FORM_ADOPT_TEMPORARILY}
            animalsTypeModel={Animals}
            initialValues={initialValuesForm(FormAdoptTemporarilyModel)}
            pdfName={PDF_ADOPT_TEMPORARILY}
            viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION}
            validationSchema={validationForAdoptTemporarilyDashboard}
        />
    )
}