import { useLocalStorage } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import GlobalUserProvider, { useUserState } from "../helpers/GlobalUserState";
import { Hub, DataStore } from 'aws-amplify';
import Broadcaster, { BC_TYPE_USERCHANGE } from "./Broadcaster";
import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import LoadingOverlay from "./LoadingOverlay";
import GlobalErrorProvider from "../helpers/GlobalErrorState";
import GlobalLoadingProvider from "../helpers/GlobalLoadingState";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "../helpers/ScrollToTop";
import ErrorOverlay from "./ErrorOverlay";
import AppShell from "./AppShell";
import * as Sentry from "@sentry/react";
import * as routes from "../helpers/Routes";
import * as pages from "../pages";
import { Accessible, Home, List, Users, DogBowl, Database, ReportMoney, PlaylistAdd, Api } from "tabler-icons-react";
import { USERGROUP_ADMIN, USERGROUP_ANIMALHOME_ADMIN, USERGROUP_ANIMALHOME_USER, USERGROUP_SUPPORT } from "../helpers/Constants";
import RouteAnimalHomeUser from "./RouteAnimalHomeUser";
import RouteSupport from "./RouteSupport";
import RouteAnimalHomeAdmin from "./RouteAnimalHomeAdmin";
import RouteGeneral from "./RouteGeneral";
import RouteAdmin from "./RouteAdmin";
import AcceptCookies from "./AcceptCookies";

// sentry integration
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://eafd1bd4a9e1478e856310ad12ef2f87@o672392.ingest.sentry.io/4505018821246976",
        normalizeDepth: 20,
    });
}

// definition of navigation items
const navigationItems = [
    // general routes, without auth
    { path: routes.ROUTE_HOME, element: <pages.general.PageGeneralHome />, routeType: null, label: "Home", icon: <Home size={16} /> },

    // pages for animal home users
    { path: routes.ROUTE_ANIMALHOME_HOME, element: <RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeHome} />, routeType: [USERGROUP_ANIMALHOME_USER, USERGROUP_ANIMALHOME_ADMIN], label: "Dashboard", icon: <Home size={16} /> },
    { path: routes.ROUTE_ANIMALHOME_ANIMALS, element: <RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeAnimals} />, routeType: [USERGROUP_ANIMALHOME_USER, USERGROUP_ANIMALHOME_ADMIN], label: "Tiere", icon: <DogBowl size={16} /> },
    { path: routes.ROUTE_ANIMALHOME_ENTITY_LISTING, element: <RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesListing} />, routeType: [USERGROUP_ANIMALHOME_USER, USERGROUP_ANIMALHOME_ADMIN], label: "Entitäten", icon: <Accessible size={16} /> },
    { path: routes.ROUTE_ANIMALHOME_PAYMENTS, element: <RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomePayments} />, routeType: [USERGROUP_ANIMALHOME_USER, USERGROUP_ANIMALHOME_ADMIN], label: "Zahlungen", icon: <ReportMoney size={16} /> },

    // pages for animal home admins
    { path: routes.ROUTE_ANIMALHOME_DETAILS, element: <RouteAnimalHomeAdmin Component={pages.support.PageSupportAnimalHomeShow} />, routeType: [USERGROUP_ANIMALHOME_ADMIN], label: "Tierheimdetails", icon: <List size={16} /> },

    // pages for support
    { path: routes.ROUTE_SUPPORT_HOME, element: <RouteSupport Component={pages.support.PageSupportHome} />, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "Dashboard", icon: <Home size={16} /> },

    // animal home listings
    { path: routes.ROUTE_SUPPORT_ANIMALHOME_LISTING, element: <RouteSupport Component={pages.support.PageSupportAnimalHomeListing} />, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "Tierheime", icon: <List size={16} /> },

    // pages for admin
    { path: routes.ROUTE_ADMIN_SUPPORTUSERS, element: <RouteAdmin Component={pages.admin.PageAdminSupportUsers} />, routeType: [USERGROUP_ADMIN], label: "Support User", icon: <Users size={16} /> },
    
    // page for race and breed CRUD
    { path: routes.ROUTE_RACE_BREED_CRUD, element: <RouteAdmin Component={pages.admin.PageAdminRaceAndBreedsCrud} />, routeType: [USERGROUP_ADMIN], label: "Races and Breeds", icon: <Database size={16} /> },

    // page for race and breed CRUD
    { path: routes.ROUTE_CSV_PROCESSOR, element: <RouteAdmin Component={pages.admin.PageCsvProcessor} />, routeType: [USERGROUP_ADMIN], label: "CSV Processor", icon: <PlaylistAdd size={16} /> },

    // page for API
    { path: routes.ROUTE_API, element: <RouteAdmin Component={pages.admin.PageAdminApi} />, routeType: [USERGROUP_ADMIN], label: "API Tokens", icon: <Api size={16} /> },
];

/**
 * implementation of main app
 * @returns JSX
 */
function App() {
    const [colorScheme, setColorScheme] = useLocalStorage({ key: 'mantine-color-scheme', defaultValue: 'light', getInitialValueInEffect: false });
    const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
    const [dataStoreReady, setDataStoreReady] = useState(false);
    const datastoreListenerRef = useRef(null);
    const datastoreInitialzedBefore = useRef(false);
    const user = useUserState();

    /**
     * before we do anything, we need to make sure that the datastore is initialized
     */
    useEffect(() => {
        // listen to Datastore events 
        datastoreListenerRef.current = Hub.listen('datastore', async hubData => {
            const { event } = hubData.payload;
            // when event === 'ready' it signals that datastore is initialized fist time during its lifecycle
            // when event includes "outbox" we have actions like LogIn
            // when event === 'syncQueriesReady' it means that the system is back online
            if (event === 'ready' || (event.toLowerCase().includes("outbox") && datastoreInitialzedBefore.current === true) || event === 'syncQueriesReady') {
                setDataStoreReady(true);
                datastoreInitialzedBefore.current = true;
            }
            else {
                setDataStoreReady(false);
            }
        });

        // listener for browser messages
        Broadcaster.onmessage = function (ev) {
            if (ev.data.type === BC_TYPE_USERCHANGE && ev.data.id !== user.id) {
                setTimeout(() => { window.location.reload(); }, 500);
            }
        }

        // init datastore
        DataStore.start();

        // unsubscribe from datastore listener when unmounting
        return () => {
            if (datastoreListenerRef.current) {
                datastoreListenerRef.current();
            }
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * callback to inform other windows of user change
     */
    useEffect(() => {
        // when the user id changed, we need to send an event to other pages to reload
        Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: user.id });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    // check if datastore loaded already
    if (!dataStoreReady) {
      return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            theme={{ 
                colorScheme, 
                fontFamily: 'Co Headline W08 Regular', 
                headings: { fontFamily: 'Co Headline W08 Regular' } 
            }}
            withGlobalStyles
            withNormalizeCSS
          >
            <LoadingOverlay loading={true} />
          </MantineProvider>
        </ColorSchemeProvider>
      );
    }

    return (
        <GlobalErrorProvider>
            <GlobalLoadingProvider>
                <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
                    <MantineProvider
                        theme={{ 
                            colorScheme, 
                            fontFamily: 'Co Headline W08 Regular', 
                            headings: { fontFamily: 'Co Headline W08 Regular' } 
                        }}
                        withGlobalStyles
                        withNormalizeCSS
                    >
                        <AcceptCookies />
                        <Notifications />
                        <ModalsProvider>
                            <Router>
                                <ScrollToTop />
                                <ErrorOverlay />
                                <LoadingOverlay>
                                    <GlobalUserProvider>
                                        <AppShell navigationItems={navigationItems}>
                                            <Routes>
                                                <Route exact path={routes.ROUTE_ROOT} element={<Navigate to={routes.ROUTE_HOME} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_ROOT} element={<Navigate to={routes.ROUTE_ANIMALHOME_HOME} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_ROOT} element={<Navigate to={routes.ROUTE_SUPPORT_HOME} />} />

                                                {navigationItems.map(function (route) {
                                                    return (<Route exact path={route.path} element={route.element} key={route.path} />);
                                                })};

                                                <Route exact path={routes.ROUTE_SIGNIN} element={<pages.general.PageGeneralSignIn />} />
                                                <Route exact path={routes.ROUTE_SIGNIN + "/:email"} element={<pages.general.PageGeneralSignIn />} />
                                                <Route exact path={routes.ROUTE_SIGNIN_CHANGEPASSWORD} element={<RouteGeneral Component={pages.general.PageGeneralChangePassword} />} />
                                                <Route exact path={routes.ROUTE_SIGNIN_CHANGEPASSWORD + "/:email"} element={<RouteGeneral Component={pages.general.PageGeneralChangePassword} />} />
                                                <Route exact path={routes.ROUTE_PASSWORD_FORGOT} element={<RouteGeneral Component={pages.general.PageGeneralResetPassword} />} />
                                                <Route exact path={routes.ROUTE_PASSWORD_FORGOT + "/:email"} element={<RouteGeneral Component={pages.general.PageGeneralResetPassword} />} />
                                                <Route exact path={routes.ROUTE_PASSWORD_FORGOT_CONFIRM} element={<RouteGeneral Component={pages.general.PageGeneralResetpasswordConfirm} />} />
                                                <Route exact path={routes.ROUTE_PASSWORD_FORGOT_CONFIRM + "/:email"} element={<RouteGeneral Component={pages.general.PageGeneralResetpasswordConfirm} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_ANIMALHOME_NEW} element={<RouteSupport Component={pages.support.PageSupportAnimalHomeForm} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_ANIMALHOME_EDIT + "/:id"} element={<RouteSupport Component={pages.support.PageSupportAnimalHomeForm} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_ANIMALHOME_SHOW + "/:animalhomeId"} element={<RouteSupport Component={pages.support.PageSupportAnimalHomeShow} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_ANIMALHOME_USERS_NEW + "/:animalhomeId"} element={<RouteSupport Component={pages.animalhome.PageAnimalHomeUserNew} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_ENTITY_NEW + "/:animalhomeId"} element={<RouteSupport Component={pages.animalhome.PageAnimalHomeEntitiesNew} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_CREATE + "/:entityId/:animalhomeId"} element={<RouteSupport Component={pages.animalhome.PageAnimalHomeEntitiesCreatePersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_EDIT + "/:id"} element={<RouteSupport Component={pages.animalhome.PageAnimalHomeEntitiesEditSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_PERSON_GROUP_ENTITY_EDIT + "/:id"} element={<RouteSupport Component={pages.animalhome.PageAnimalHomeEntitiesEditPersonGroupEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_EDIT + "/:id"} element={<RouteSupport Component={pages.animalhome.PageAnimalHomeEntitiesEditPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_VIEW + "/:id"} element={<RouteSupport Component={pages.support.PageSupportEntitiesViewSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_PERSON_GROUP_ENTITY_VIEW + "/:id"} element={<RouteSupport Component={pages.support.PageSupportEntitiesViewPersonGroupEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_PERSON_GROUP_INDIVIDUAL_VIEW + "/:id"} element={<RouteSupport Component={pages.support.PageSupportEntitiesViewPersonGroupIndividual} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_PERSON_GROUP_INDIVIDUAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateFollowupCheckForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_SINGLE_PERSON_ENTITY + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateFollowupCheckForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_VIEW_FOLLOWUP_CHECK + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsViewFollowupCheck} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_EDIT_FOLLOWUP_CHECK + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsEditFollowupCheck} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateTemporarilyAdoptionForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_SINGLE_PERSON_ENTITY + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateTemporarilyAdoptionForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsViewTemporarilyAdoption} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_EDIT_TEMPORARILY_ADOPTION + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsEditTemporarilyAdoption} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_PERSON_GROUP_INDIVIDUAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateBringInAnimalForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_SINGLE_PERSON_ENTITY + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateBringInAnimalForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_VIEW_BRING_IN_ANIMAL + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsViewBringInAnimal} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_EDIT_BRING_IN_ANIMAL + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsEditBringInAnimal} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreatePermanentlyAdoptionForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_SINGLE_PERSON_ENTITY + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreatePermanentlyAdoptionForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsViewPermanentlyAdoption} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_EDIT_PERMANENTLY_ADOPTION + "/:id"} element={<RouteSupport Component={pages.support.PageSupportFormsEditPermanentlyAdoption} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_ANIMALS_CREATE_ANIMAL + "/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportAnimalsCreateAnimal} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_ANIMALS_EDIT_ANIMAL + "/:id"} element={<RouteSupport Component={pages.support.PageSupportAnimalsEditAnimal} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_ANIMALS_VIEW_ANIMAL + "/:id"} element={<RouteSupport Component={pages.support.PageSupportAnimalsViewAnimal} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_ANIMAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateFollowupCheckForAnimal} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_ANIMAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateBringInAnimalForAnimal} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_ANIMAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreateTemporarilyAdoptionForAnimal} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_ANIMAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportFormsCreatePermanentlyAdoptionForAnimal} />} />

                                                <Route exact path={routes.ROUTE_SUPPORT_PAYMENTS_CREATE_PAYMENT_FOR_ANIMAL + "/:id/:animalHomeId"} element={<RouteSupport Component={pages.support.PageSupportPaymentsCreatePaymentForAnimal} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_PAYMENTS_EDIT_PAYMENT + "/:id"} element={<RouteSupport Component={pages.support.PageSupportPaymentsEditPayment} />} />
                                                <Route exact path={routes.ROUTE_SUPPORT_PAYMENTS_VIEW_PAYMENT + "/:id"} element={<RouteSupport Component={pages.support.PageSupportPaymentsViewPayment} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_USERS_NEW} element={<RouteAnimalHomeAdmin Component={pages.animalhome.PageAnimalHomeUserNew} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_EDIT + "/:id"} element={<RouteAnimalHomeAdmin Component={pages.support.PageSupportAnimalHomeForm} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_ENTITY_NEW} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesNew} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PERSON_GROUP_INDIVIDUAL_CREATE + "/:entityId/:animalhomeId"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesCreatePersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_SINGLE_PERSON_ENTITY_EDIT + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesEditSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PERSON_GROUP_ENTITY_EDIT + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesEditPersonGroupEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PERSON_GROUP_INDIVIDUAL_EDIT + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesEditPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_SINGLE_PERSON_ENTITY_VIEW + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesViewSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PERSON_GROUP_ENTITY_VIEW + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesViewPersonGroupEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PERSON_GROUP_INDIVIDUAL_VIEW + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeEntitiesViewPersonGroupIndividual} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_PERSON_GROUP_INDIVIDUAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateFollowupCheckForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_SINGLE_PERSON_ENTITY + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateFollowupCheckForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsViewFollowupCheck} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_FOLLOWUP_CHECK + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsEditFollowupCheck} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateTemporarilyAdoptionForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_SINGLE_PERSON_ENTITY + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateTemporarilyAdoptionForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_TEMPORARILY_ADOPTION + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsViewTemporarilyAdoption} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_TEMPORARILY_ADOPTION + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsEditTemporarilyAdoption} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_PERSON_GROUP_INDIVIDUAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateBringInAnimalForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_SINGLE_PERSON_ENTITY + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateBringInAnimalForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsViewBringInAnimal} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_BRING_IN_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsEditBringInAnimal} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_PERSON_GROUP_INDIVIDUAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreatePermanentlyAdoptionForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_SINGLE_PERSON_ENTITY + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreatePermanentlyAdoptionForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_VIEW_PERMANENTLY_ADOPTION + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsViewPermanentlyAdoption} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_EDIT_PERMANENTLY_ADOPTION + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsEditPermanentlyAdoption} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_ANIMALS_CREATE_ANIMAL} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeAnimalsCreateAnimal} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_ANIMALS_EDIT_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeAnimalsEditAnimal} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeAnimalsViewAnimal} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateFollowupCheckForAnimal} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateTemporarilyAdoptionForAnimal} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreatePermanentlyAdoptionForAnimal} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateBringInAnimalForAnimal} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_DASHBOARD} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateFollowupCheckForDashboard} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_DASHBOARD} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateTemporarilyAdoptionForDashboard} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_DASHBOARD} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreatePermanentlyAdoptionForDashboard} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_DASHBOARD} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomeFormsCreateBringInAnimalForDashboard} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_PERSON_GROUP_INDIVIDUAL + "/:entityId"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomePaymentsCreatePaymentForPersonGroupIndividual} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_SINGLE_PERSON_ENTITY + "/:entityId"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomePaymentsCreatePaymentForSinglePersonEntity} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_ANIMAL + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomePaymentsCreatePaymentForAnimal} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_DASHBOARD} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomePaymentsCreatePaymentForDashboard} />} />

                                                <Route exact path={routes.ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomePaymentsEditPayment} />} />
                                                <Route exact path={routes.ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT + "/:id"} element={<RouteAnimalHomeUser Component={pages.animalhome.PageAnimalHomePaymentsViewPayment} />} />

                                                <Route exact path={routes.ROUTE_ADMIN_SUPPORTUSERS_NEW} element={<RouteAdmin Component={pages.admin.PageAdminSupportUserNew} />} />

                                                <Route exact path={routes.ROUTE_EDIT_RACE + "/:id"} element={<RouteAdmin Component={pages.admin.PageAdminEditRace} />} />

                                                <Route exact path={routes.ROUTE_API_EDIT + "/:id"} element={<RouteAdmin Component={pages.admin.PageAdminApiEdit} />} />

                                                <Route exact path={routes.ROUTE_NOT_FOUND} element={<pages.general.PageGeneralNotFound />} />
                                                <Route path={routes.ROUTE_CATCH} element={<pages.general.PageGeneralNotFound />} />
                                            </Routes>
                                        </AppShell>
                                    </GlobalUserProvider>
                                </LoadingOverlay>
                            </Router>
                        </ModalsProvider>
                    </MantineProvider>
                </ColorSchemeProvider>
            </GlobalLoadingProvider>
        </GlobalErrorProvider>
    )
}

export default App;
