import { Button, Group, Stack } from '@mantine/core'
import React, { useState } from 'react'
import FileInput from './FileInput'
import { FILE_TYPE_PDF } from '../helpers/Constants'
import { API_ANIMALDOCUMENTS_ROUTE, API_PDF_NAME, API_POST, PRIVATE_PROTECTION_LEVEL, executeApiCall } from '../helpers/APIHelper'
import { useForm } from './Form'
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState'
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState'
import { AnimalsDocuments } from '../models'
import { animalsDocumentsValidation } from '../helpers/FormValidations'

export default function AnimalDocuments({ animal }) {

  // globals
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();
  const [documentsInit, setDocumentsInit] = useState([]);

  const submitCallback = async (values) => {

    values.owner = animal.owner;
    values.animalId = animal.id;

    try {
      setLoading(LOADING_SHOW);
      const route = API_ANIMALDOCUMENTS_ROUTE;
      const body = { 
          formData: values,
          table: AnimalsDocuments.name,
      };

      const animalDoc = await executeApiCall(API_PDF_NAME, API_POST, route, body);

      if (animalDoc) {
        form.setValues({
          ...form.values,
          scannedDocuments: '',
          updatedScannedDocuments: '',
        });
        setDocumentsInit([]);
      }

    } catch (error) {
        console.error('Error:', error);
        setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
    }
    finally {
        setLoading(LOADING_RESET);
    }
  };

  // creating form object
  const form = useForm({
    validationSchema: animalsDocumentsValidation,
    initialValues: {},
    submitCallback: submitCallback
  });

  return (
    <Stack>
      <form onSubmit={form.onSubmit()} onReset={form.onReset}>

        <FileInput
            initFiles={documentsInit}
            multiple={true}
            label="Weitere Dokumente hochladen"
            description="Wählen Sie Dokumente zum Hochladen aus."
            path="scannedDocuments"
            form={form}
            withAsterisk={false}
            fileType={FILE_TYPE_PDF}
            storagePrefix="pdfs"
            fileProtectionLevel={PRIVATE_PROTECTION_LEVEL}
            identityId={animal.owner}
            enableAdditionalDetails={true}
            pt="xs"
        />

        <Group position="right" mt="md">
            <Button type="submit">Dokumente hochladen</Button>
        </Group>

      </form>
    </Stack>
  )
}
