import { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { DataStore } from "aws-amplify";
import { ROUTE_NOT_FOUND } from "../helpers/Routes";
import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { EDIT } from "../helpers/Constants";
import { initialValuesForm } from "../helpers/FormFieldsProcessor";
import { API_PUT } from "../helpers/APIHelper";
import AnimalEditTemplate from "./AnimalEditTemplate";

/**
 * Edit Form Template Page to edit an animal home form
 * @returns JSX
 */
export default function AnimalEditPageTemplate({ id, title, AnimalModel, viewRoute, FormComponent, validationSchema}) {

  // globals
  const navigate = useNavigate();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();

  const [animalhomeId, setAnimalhomeId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  /**
   * wrapper to prepare the page
   */
  const preparePage = async () => {
    try {
        if (id) {
            // get item
            setLoading(LOADING_SHOW);

            // call function to get falues from database and set form
            await fetchDatabase();


        }
    }
    catch (e) {
        setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
    }
    finally {
        setLoading(LOADING_RESET);
    }
  }

  /**
   * Use effect hook to initially fetch data
   */
  useEffect(() => {
      preparePage();
  },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [id]
  );

  // fetch data from database
  async function fetchDatabase(){

    const animalData = await DataStore.query(AnimalModel, id);

    if (!animalData) {
        navigate(ROUTE_NOT_FOUND);
    }

    setAnimalhomeId(animalData.owner);

    // convert empty string to null
    const mapAnimalData = Object.fromEntries(
        Object.entries(animalData).map(([key, value]) => [key, value === null ? '' : value])
    );

    const initialValues = initialValuesForm(AnimalModel, mapAnimalData);

    // set data
    setInitialValues({
        ...initialValues,
        id: mapAnimalData.id,
        entityId: mapAnimalData.entityId,
        entityGroupId: mapAnimalData.entityGroupId,
        owner: mapAnimalData.owner,
    });

  }

  return (
    initialValues &&
    <Stack>
        <Group position="apart">
            <Title>{title} {EDIT}</Title>
            <Group>
                <Button
                    leftIcon={<ArrowLeft size={14} />}
                    onClick={() => navigate(-1, { replace: true })}
                    color="yellow"
                >
                    Zurück
                </Button>
            </Group>
        </Group>
        <Divider />

        <AnimalEditTemplate
            id={id}
            animalHomeId={animalhomeId}
            FormComponent={FormComponent}
            FormName={API_PUT.toLowerCase() + AnimalModel.name}
            initialValues={initialValues}
            viewRoute={viewRoute}
            validationSchema={validationSchema}
        />
    </Stack>
  );
}