import {
  Button,
  Divider,
  Group,
  Stack,
  Title,
} from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { CREATE, PERSON_GROUP_INDIVIDUAL } from "../../helpers/Constants";
import PersonGroupIndividualNew from "../../components/PersonGroupIndividualNew";

/**
 * implementation of animal home user home page
 * @returns JSX
 */
export default function PageAnimalHomeEntitiesCreatePersonGroupIndividual() {
  // globals
  const navigate = useNavigate();
  const { entityId, animalhomeId } = useParams();

  return (
    <Stack>
      <Group position="apart">
        <Title>{PERSON_GROUP_INDIVIDUAL} {CREATE}</Title>
        <Group>
          <Button
            leftIcon={<ArrowLeft size={14} />}
            onClick={() => navigate(-1, { replace: true })}
            color="yellow"
          >
            Zurück
          </Button>
        </Group>
      </Group>
      <Divider />

      <PersonGroupIndividualNew entityId={entityId} animalhomeId={animalhomeId}/>
    </Stack>
  );
}
