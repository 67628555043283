import { useParams } from "react-router-dom";
import { Animals, FormAdoptPermanently as FormAdoptPermanentlyModel, SinglePersonEntity } from "../../models";
import { FORM_ADOPT_PERMANENTLY, PDF_ADOPT_PERMANENTLY } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import FormAdoptPermanently from "../../formulars/FormAdoptPermanently";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION } from "../../helpers/Routes";

/**
 * Page used to create a new Permanent Adoption form for a Single Person Entity
 * @returns JSX
 */
export default function PageSupportFormsCreatePermanentlyAdoptionForSinglePersonEntity() {
    const { id, animalHomeId } = useParams();

    return (
        <FormCreatePageTemplate
            FormComponent={FormAdoptPermanently}
            FormName={FormAdoptPermanentlyModel.name}
            animalHomeId={animalHomeId}
            entityId={id}
            entityTypeModel={SinglePersonEntity}
            animalsTypeModel={Animals}
            formTitle={FORM_ADOPT_PERMANENTLY}
            initialValues={initialValuesForm(FormAdoptPermanentlyModel)}
            pdfName={PDF_ADOPT_PERMANENTLY}
            viewRoute={ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION}
        />
    )
}