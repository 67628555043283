import { Text, Button, Center, Stack, Title, Paper, TextInput, Container } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL } from "../../helpers/Validation";
import { useForm } from "../../components/Form";
import { ROUTE_PASSWORD_FORGOT_CONFIRM, ROUTE_SIGNIN } from "../../helpers/Routes";

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
});

/**
 * component to handle reset password
 * @returns JSX
 */
export default function PageGeneralResetPassword() {

    // globals
    const navigate = useNavigate();

    // get email if provided
    const { email } = useParams();

    // submit callback
    const submitCallback = async (values) => {
        try {
            await Auth.forgotPassword(values.email.toLowerCase());
            showNotification({ message: "Der Bestätigungscode ist unterwegs. Bitte prüfen Sie auch Ihren Spam-Ordner.", color: 'green', icon: <Check /> });
            navigate(`${ROUTE_PASSWORD_FORGOT_CONFIRM}/${values.email.toLowerCase()}`);
        }
        catch (e) {
            if (e.code === "UserNotFoundException") {
                showNotification({ message: "Kein Konto mit der angegebenen E-Mail-Adresse gefunden.", color: 'red', icon: <X /> });
            }
            else if (e.code === "LimitExceededException") {
                showNotification({ message: "Es wurde zu oft versucht das Passwort zurückzusetzen. Bitte versuchen Sie es in ein paar Minuten erneut.", color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: email ? email : "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100%" }}>
            <Container size={600} style={{ width: "600px" }}>
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center">Passwort zurücksetzen</Title>
                        <Link to={`${ROUTE_SIGNIN}/${form.values.email.toLowerCase()}`}>
                            <Text className="pointer" underline color="dimmed" size="sm" align="center" mb="md">
                                Zurück zur Anmeldung? Anmelden
                            </Text>
                        </Link>
                        <Stack>
                            <TextInput
                                withAsterisk
                                label="E-Mail-Adresse"
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                            />
                            <Button type="submit" fullWidth mt="xl">Passwort zurücksetzen</Button>
                        </Stack>
                    </Paper>
                </form>
            </Container>
        </Center>
    );
}