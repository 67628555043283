import React, { useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { DataStore } from 'aws-amplify';
import { AnimalBreeds, AnimalRaces, Animals, PersonGroupEntity, PersonGroupIndividual, SinglePersonEntity } from '../../models';
import { API_FILE_NAME, API_GET, FILE_API_URL_UPLOAD, executeApiCall } from '../../helpers/APIHelper';
import { uploadFileToUrl } from '../../helpers/Files';
import { v4 as uuidv4 } from 'uuid';

export default function PageCsvProcessor() {
    const [file, setFile] = useState(null);

    const fileProtectionLevel = 'private';

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const processFile = async () => {
        if (file) {
            const fileType = file.name.split('.').pop();
            if (fileType === 'csv') {
                Papa.parse(file, {
                    complete: async (result) => {
                        await saveData(result.data);
                    },
                    header: true
                });
            } else if (fileType === 'xlsx') {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data, { type: 'buffer' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                await saveData(jsonData);
            }
        }
    };

    const excelDateToJSDate = (excelDate) => {
        const EXCEL_BASE_DATE = new Date(1899, 11, 30); // excel base date is 30 december 1899
        const days = excelDate + 1; // plus 1 for one day diff
        return new Date(EXCEL_BASE_DATE.getTime() + days * 86400000); // 86400000 ms in one day
    };

    const ensureRaceExists = async (raceLabel) => {
        const races = await DataStore.query(AnimalRaces, (r) => r.label.eq(raceLabel));
        let race;
        if (races.length === 0) {
            race = await DataStore.save(new AnimalRaces({ label: raceLabel, labelEn: raceLabel }));
        } else {
            race = races[0];
        }
        return race.id;
    };

    const ensureBreedExists = async (raceLabel, breedLabel) => {

        const raceID = await ensureRaceExists(raceLabel);
        const existingBreeds = await DataStore.query(AnimalBreeds, (b) => b.and(b => [
            b.raceID.eq(raceID),
            b.label.eq(breedLabel)
        ]));
        let breed;
        if (existingBreeds.length === 0) {
            breed = await DataStore.save(new AnimalBreeds({
                label: breedLabel,
                labelEn: breedLabel,
                raceID: raceID
            }));
        } else {
            breed = existingBreeds[0];
        }
        return breed.id;
    
    };

    const uploadImage = async (imagePath, identityId, prefix) => {
        try {
            // Convert local path to a URL
            const localImageUrl = `/media/imagesToUpload/${imagePath}`;
            const response = await fetch(localImageUrl);
            const blob = await response.blob();

            const s3Key = `${prefix}${uuidv4()}_${imagePath}`;

            // Get a pre-signed URL and upload the file
            const url = await executeApiCall(API_FILE_NAME, API_GET, FILE_API_URL_UPLOAD, {
                protectionLevel: fileProtectionLevel,
                identityId: identityId,
                key: s3Key,
            });

            await uploadFileToUrl(blob, url);
            return s3Key;
        } catch (e) {
            console.error("Error uploading image: ", e);
            throw e;
        }
    };

    function processDataFields(rest) {
        const processed = { ...rest };
        Object.keys(processed).forEach(key => {
            if (typeof processed[key] === 'string') {
                if (processed[key] === "") {
                    processed[key] = null;
                } else if (key === "castratedPet") {
                    const value = processed[key].toLowerCase();
                    processed[key] = value === "true" ? true : (value === "false" ? false : null);
                }
            }
    
            if (key === "gender" || key === "salutation") {
                if (typeof processed[key] === 'string') {
                    const trimmedValue = processed[key].trim().toLowerCase();
                    if (trimmedValue.startsWith('m')) {
                        processed[key] = "m";
                    } else if (trimmedValue.startsWith('w') || trimmedValue.startsWith('f')) {
                        processed[key] = "f";
                    } else {
                        processed[key] = "o"; // default value "o" for other cases
                    }
                }
            }
    
            if (key === "birthDate" || key === "bringInDate" || key === "leaveDate" || key === "idReleasedOn" || key === "birthdate") {
                if (processed[key]) {
                    let date;
                    if (typeof processed[key] === 'string') {
                        // is ISO
                        date = new Date(processed[key]);
                    } else if (typeof processed[key] === 'number') {
                        // number in Excel
                        date = excelDateToJSDate(processed[key]);
                    }
                    if (!isNaN(date.getTime())) {
                        // check if we have a valid date
                        processed[key] = date.toISOString().split('T')[0];
                    } else {
                        processed[key] = null;
                    }
                }
            }
        });
        return processed;
    }

    const saveData = async (data) => {

        for (let item of data) {

            let restItem;

            if (!item.entityType){
                const raceID = await ensureRaceExists(item.raceID);
                const breedID = await ensureBreedExists(item.raceID, item.breedID);
    
                let imageMainUrl = null;
                if (item.imageMain) {
                    imageMainUrl = await uploadImage(item.imageMain, data[0].owner, 'media/animalProfilePicture/');
                }
    
                let imagesAdditionalUrls = [];
                if (item.imagesAdditional) {
                    let trimmedString = item.imagesAdditional.slice(2, -2);
                    item.imagesAdditional = trimmedString.split("', '").map(s => s.trim());
    
                    imagesAdditionalUrls = await Promise.all(
                        item.imagesAdditional.map(imagePath => uploadImage(imagePath, data[0].owner, 'media/'))
                    );
    
                    if (imagesAdditionalUrls.length === 0) {
                        imagesAdditionalUrls = '';
                    }
                }
    
                // exclude createdAt and updatedAt and add race and breed
                const { createdAt, updatedAt, raceID: _, breedID: __, ...restAnimals } = item;

                restItem = processDataFields(restAnimals);

                try {
                    const animal = await DataStore.save(new Animals({ ...restItem, raceID, breedID, imageMain: imageMainUrl, imagesAdditional: imagesAdditionalUrls }));
                    if(animal){
                        console.log(animal)
                    }
                } catch (error) {
                    console.error("Error while saving animals: ", error);
                } finally{
                    setFile(null)
                }
            } else{
                const { createdAt, updatedAt, entityType, ...restEntity } = item;

                restItem = processDataFields(restEntity);

                switch (item.entityType) {
                    case 'singlePersonEntity':
                        try {
                            const singlePersonEntity = await DataStore.save(new SinglePersonEntity({ ...restItem }));
                            if(singlePersonEntity){
                                console.log(singlePersonEntity)
                            }
                        } catch (error) {
                            console.error("Error while saving singlePersonEntity: ", error);
                        } finally{
                            setFile(null)
                        }
                        break;
                    case 'personGroupEntity':
                        try {
                            const personGroupEntity = await DataStore.save(new PersonGroupEntity({ ...restItem }));
                            if(personGroupEntity){
                                console.log(personGroupEntity)
                            }
                        } catch (error) {
                            console.error("Error while saving personGroupEntity: ", error);
                        } finally{
                            setFile(null)
                        }
                        break;
                    case 'personGroupIndividual':
                        try {
                            const personGroupIndividual = await DataStore.save(new PersonGroupIndividual({ ...restItem }));
                            if(personGroupIndividual){
                                console.log(personGroupIndividual)
                            }
                        } catch (error) {
                            console.error("Error while saving personGroupIndividual: ", error);
                        } finally{
                            setFile(null)
                        }
                        break;
                    default:
                        console.error("Unknown entity type:", item.entityType);
                }

            }
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileChange} />
            <button onClick={processFile}>Process File</button>
        </div>
    )
}
