import { API, Auth } from "aws-amplify";

// constants for api calls
export const API_GET = "GET";
export const API_POST = "POST";
export const API_PUT = "PUT";
export const API_ADMIN_NAME = "AdminQueries";
export const API_PDF_NAME = 'pdfHandler';
export const API_FILE_NAME = 'fileHandler';

// admin functions
export const ADMIN_API_ADMIN_LISTSUPPORTUSERS = "/listSupportUsers";
export const ADMIN_API_ADMIN_ENABLEUSER = "/enableUser";
export const ADMIN_API_ADMIN_DISABLEUSER = "/disableUser";
export const ADMIN_API_ADMIN_ADDUSERTOGROUP = "/addUserToGroup";
export const ADMIN_API_ADMIN_REMOVEUSERFROMGROUP = "/removeUserFromGroup";
export const ADMIN_API_ADMIN_GETUSER = "/getUser";
export const ADMIN_API_ADMIN_CREATESUPPORTUSER = "/createSupportUser";

// support functions
export const ADMIN_API_SUPPORT_CREATE_ANIMALHOME_USER = "/support/createUser";
export const ADMIN_API_SUPPORT_LIST_ANIMALHOME_USERS = "/support/listUsers";

// animal home users functions
export const ADMIN_API_ANIMALHOME_LIST_USERS = "/animalhome/listUsers";
export const ADMIN_API_ANIMALHOMEADMIN_CREATEUSER = "/animalhomeadmin/createUser";

// file api routes
export const FILE_API_URL_DOWNLOAD = "/url/download";
export const FILE_API_URL_DOWNLOAD_ALL = "/url/downloadAll";
export const FILE_API_URL_UPLOAD = "/url/upload";
export const FILE_API_DELETE = "/delete";
export const FILE_PDF_ROUTE = "/pdf";
// animal routes
export const ANIMAL_API_ROUTE = "/animal";

// animal api
export const API_ANIMAL_ROUTE = "/pdf/animal"

// animal documents api
export const API_ANIMALDOCUMENTS_ROUTE = "/pdf/animalDocuments"

// email pdf
export const FILE_PDF_EMAIL_ROUTE = "/pdf/sendMail";

// protection level
export const PRIVATE_PROTECTION_LEVEL = "private";

// file path
export const PATH_TO_PDFS = "pdfs/";
export const PATH_TO_MEDIA = "media/";
/**
 * wrapper to call REST APIs
 * @param {string} api name of the API
 * @param {string} mode HTTP mode like get, post, etc.
 * @param {string} route the route to call within the API
 * @param {object} body parameters to provide to the API call
 * @returns API response
 */
export async function executeApiCall(api, mode, route, body) {
    // construct params
    let params = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: mode === API_POST || API_PUT ? body : null,
        queryStringParameters: mode === API_GET ? body : null,
    }

    // execute call
    if (mode === API_GET) {
        return await API.get(api, route, params);
    }
    else if (mode === API_POST) {
        return await API.post(api, route, params);
    }
    else if (mode === API_PUT) {
        return await API.put(api, route, params);
    }
    else {
        throw new Error("API mode unknown");
    }
}