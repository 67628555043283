import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUserState } from '../helpers/GlobalUserState';
import { ROUTE_HOME } from '../helpers/Routes';

/**
 * Private route implementation to redirect 
 * @param {array} props The props of the component
 */
const RouteGeneral = ({ Component }) => {
    const user = useUserState();
    
    if (user.id) {
        return <Navigate to={ROUTE_HOME} />
    } 
    else {
        return <Component />
    }
}

// Default export of the class
export default RouteGeneral;