import { Button, Divider, Group, Stack, TextInput } from "@mantine/core";
import { useForm } from "./Form";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_ADDRESS, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_GROUP_ENTITY_TYPE, VALIDATION_SCHEMA_NAME, VALIDATION_SCHEMA_PHONE } from "../helpers/Validation";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { Check, File, Reload } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../helpers/GlobalUserState";
import { DataStore } from "aws-amplify";
import { PersonGroupEntity } from "../models";

// validation schema with yup
const validationSchema = Yup.object().shape({
    type: VALIDATION_SCHEMA_GROUP_ENTITY_TYPE,
    name: VALIDATION_SCHEMA_NAME,
    street: VALIDATION_SCHEMA_ADDRESS,
    zip: VALIDATION_SCHEMA_ADDRESS,
    city: VALIDATION_SCHEMA_ADDRESS,
    country: VALIDATION_SCHEMA_ADDRESS,
    phone: VALIDATION_SCHEMA_PHONE,
    email: VALIDATION_SCHEMA_EMAIL,
});

/**
 * Person Group Entity New component used to create a new person group entity
 * @returns JSX
 */
export default function PersonGroupEntityNew({ animalhomeId }) {

    // globals
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();
    const user = useUserState();

    // submit callback
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);

            // create new person group entity
            await DataStore.save(new PersonGroupEntity({
                type: values.type,
                name: values.name,
                street: values.street,
                zip: values.zip,
                city: values.city,
                country: values.country,
                phone: values.phone,
                email: values.email,
                owner: user.animalHomeId ? user.animalHomeId : animalhomeId,
                status: "ACTIVE"
            }));

            // show message
            showNotification({ message: "Personengruppe erfolgreich gespeichert.", color: 'green', icon: <Check /> });

            // redirect
            navigate(-1);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            type: "",
            name: "",
            street: "",
            zip: "",
            city: "",
            country: "",
            phone: "",
            email: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Typ"
                        placeholder="e.g. Verein, Amt, Unternehmen"
                        {...form.getInputProps('type')}
                    />
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Name"
                        {...form.getInputProps('name')}
                    />
                    <TextInput
                        withAsterisk
                        label="Adresse"
                        placeholder="Adresse"
                        {...form.getInputProps('street')}
                    />
                    <TextInput
                        withAsterisk
                        label="PLZ"
                        placeholder="PLZ"
                        {...form.getInputProps('zip')}
                    />
                    <TextInput
                        withAsterisk
                        label="Ort"
                        placeholder="Ort"
                        {...form.getInputProps('city')}
                    />
                    <TextInput
                        withAsterisk
                        label="Land"
                        placeholder="Land"
                        {...form.getInputProps('country')}
                    />
                    <TextInput
                        withAsterisk
                        label="Telefonnummer"
                        placeholder="Telefonnummer"
                        {...form.getInputProps('phone')}
                    />
                    <TextInput
                        withAsterisk
                        label="E-Mail-Adresse"
                        placeholder="E-Mail-Adresse"
                        {...form.getInputProps('email')}
                    />
                    <Divider />

                    <Group position='apart'>
                        <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                        <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}