import { Card, Divider, Group, Stack, Title } from "@mantine/core";
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { AnimalHome, AnimalHomeUser, PersonGroupEntity, PersonGroupIndividual, SinglePersonEntity } from "../../models";
import { API_ADMIN_NAME, API_GET, executeApiCall } from "../../helpers/APIHelper";

/**
 * implementation of support home page
 * @returns JSX
 */
export default function PageSupportHome() {

    const [stats, setStats] = useState({
        totalNumberOfEntities: 0,
        totalNumberOfUsers: 0,
        totalNumberOfAnimalHomeAdmins: 0,
        totalNumberOfAnimalHomes: 0,
        recentlyAddedEntities: 0,
        recentlyAddedUsers: 0,
        recentlyAddedAnimalHomeAdmins: 0,
        recentlyAddedAnimalHomes: 0,
    })

    let today = new Date();
    let oneMonthAgo = new Date(new Date().setDate(today.getDate() - 30));

    // yyyy-mm-dd
    oneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        const singlePersonEntities = await DataStore.query(SinglePersonEntity);
        const personGroupEntities = await DataStore.query(PersonGroupEntity);
        const personGroupIndividuals = await DataStore.query(PersonGroupIndividual);
        const users = await DataStore.query(AnimalHomeUser);
        const animalHomes = await DataStore.query(AnimalHome);
        const animalHomeAdminUsers = await executeApiCall(API_ADMIN_NAME, API_GET, '/listUsersInGroup', {groupname: 'AnimalHomeAdmin'});
  
        setStats(prev => ({
          ...prev,
          totalNumberOfEntities: singlePersonEntities.length + personGroupEntities.length + personGroupIndividuals.length,
          totalNumberOfUsers: users.length,
          totalNumberOfAnimalHomes: animalHomes.length,
          totalNumberOfAdmins: animalHomeAdminUsers.Users.length,
          recentlyAddedEntities: singlePersonEntities
            .concat(personGroupIndividuals)
            .concat(personGroupEntities)
            .filter(entity => new Date(entity['createdAt']).toISOString().split('T')[0] > oneMonthAgo)
            .length,
          recentlyAddedUsers: users.filter(user => new Date(user['createdAt']).toISOString().split('T')[0] > oneMonthAgo).length,
          recentlyAddedAnimalHomes: animalHomes.filter(adnimalHome => new Date(adnimalHome['createdAt']).toISOString().split('T')[0] > oneMonthAgo).length,
          recentlyAddedAdmins: animalHomeAdminUsers.Users.filter(admin => new Date(admin['UserCreateDate']).toISOString().split('T')[0] > oneMonthAgo).length
        
        }));
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const enititiesCardStyle = {
        backgroundImage: "linear-gradient(-270deg, royalBlue 0, lightSkyBlue 100%)",
        color: "white",
        fontWeight: "bold",
    }

    const usersCardStyle = {
        backgroundImage: "linear-gradient(-270deg, #FC8C0C 0, #FFAB09 100%)",
        color: "white",
        fontWeight: "bold",
    }

    const adminsCardStyle = {
        backgroundImage: "linear-gradient(-270deg, #F21616 0, #F0185C 100%)",
        color: "white",
        fontWeight: "bold",
    }

    const animalHomesCardStyle = {
        backgroundImage: "linear-gradient(-270deg, #2BDD66 0, #6BD731 100%)",
        color: "white",
        fontWeight: "bold",
    }

    return (
        <Stack style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
            <Group>
                <Title>Dashboard</Title>
            </Group>

            <Divider/>

            <Group>
                {/* entities */}
                <Card shadow="sm" padding="lg" radius="md" withBorder style={enititiesCardStyle}>
                    Recently added entities: {stats.recentlyAddedEntities}
                </Card>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={enititiesCardStyle}>
                    Total number of entities: {stats.totalNumberOfEntities}
                </Card>          
            </Group>
            
            <Group>
                {/* users */}
                <Card shadow="sm" padding="lg" radius="md" withBorder style={usersCardStyle}>
                    Recently added users: {stats.recentlyAddedUsers}
                </Card>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={usersCardStyle}>
                    Total number of users: {stats.totalNumberOfUsers}
                </Card>
            </Group>

            <Group>
                {/* admins */}
                <Card shadow="sm" padding="lg" radius="md" withBorder style={adminsCardStyle}>
                    Recently added animal home admins: {stats.recentlyAddedAdmins}
                </Card>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={adminsCardStyle}>
                    Total number of animal home admins: {stats.totalNumberOfAdmins}
                </Card>
            </Group>

            <Group>
                {/* animal homes */}
                <Card shadow="sm" padding="lg" radius="md" withBorder style={animalHomesCardStyle}>
                    Recently added animal homes: {stats.recentlyAddedAnimalHomes}
                </Card>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={animalHomesCardStyle}>
                    Total number of animal homes: {stats.totalNumberOfAnimalHomes}
                </Card>
            </Group>
        </Stack>
        
    );
}