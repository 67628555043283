import {
    Button,
    Divider,
    Group,
    Select,
    Stack,
    TextInput,
    Title,
  } from "@mantine/core";
  import { useEffect } from "react";
  import * as Yup from 'yup';
  import { ArrowLeft, Check, File, Reload } from "tabler-icons-react";
  import { useNavigate, useParams } from "react-router-dom";
  import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
  import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
  import { DataStore } from "aws-amplify";
  import { ROUTE_NOT_FOUND } from "../../helpers/Routes";
  import { PersonGroupIndividual } from "../../models";
  import { showNotification } from "@mantine/notifications";
  import { useForm } from "../../components/Form";
  import { VALIDATION_SCHEMA_DATE, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_FIRST_NAME, VALIDATION_SCHEMA_INSTITUTION, VALIDATION_SCHEMA_LAST_NAME, VALIDATION_SCHEMA_PHONE, VALIDATION_SCHEMA_ROLE, VALIDATION_SCHEMA_SALUTATION, VALIDATION_SCHEMA_TITLE } from "../../helpers/Validation";
  import { EDIT } from "../../helpers/Constants";
import DatePickerInput from "../../components/DatePickerInput";
  
  // validation schema with yup
  const validationSchema = Yup.object().shape({
    salutation: VALIDATION_SCHEMA_SALUTATION,
    title: VALIDATION_SCHEMA_TITLE,
    firstName: VALIDATION_SCHEMA_FIRST_NAME,
    lastName: VALIDATION_SCHEMA_LAST_NAME,
    birthdate: VALIDATION_SCHEMA_DATE,
    role: VALIDATION_SCHEMA_ROLE,
    phone: VALIDATION_SCHEMA_PHONE,
    email: VALIDATION_SCHEMA_EMAIL,
    idNumber: VALIDATION_SCHEMA_TITLE,
    idReleasedOn: VALIDATION_SCHEMA_DATE,
    idReleasedBy: VALIDATION_SCHEMA_INSTITUTION
});
  
  /**
   * implementation of animal home user home page
   * @returns JSX
   */
  export default function PageAnimalHomeEntitiesEditPersonGroupIndividual() {
  
    // globals
    const navigate = useNavigate();
    const { id } = useParams();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
  
    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
      try {
          if (id) {
              // get item
              setLoading(LOADING_SHOW);
  
              const groupEntityIndividuals = await DataStore.query(PersonGroupIndividual, id);
  
              if (!groupEntityIndividuals) {
                  navigate(ROUTE_NOT_FOUND);
              }
  
              // set data
              form.setInitialValues({
                salutation: groupEntityIndividuals.salutation,
                title: groupEntityIndividuals.title,
                firstName: groupEntityIndividuals.firstName,
                lastName: groupEntityIndividuals.lastName,
                additionalName: groupEntityIndividuals.additionalName,
                birthdate: groupEntityIndividuals.birthdate,
                role: groupEntityIndividuals.role,
                phone: groupEntityIndividuals.phone,
                email: groupEntityIndividuals.email,
                idNumber: groupEntityIndividuals.idNumber,
                idReleasedOn: groupEntityIndividuals.idReleasedOn,
                idReleasedBy: groupEntityIndividuals.idReleasedBy,
                status: groupEntityIndividuals.status
              });
        }
      }
      catch (e) {
          setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
      }
      finally {
          setLoading(LOADING_RESET);
      }
    }
  
    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );
  
      // submit callback
      const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);
  
            // update item
            const original = await DataStore.query(PersonGroupIndividual, id);
            await DataStore.save(PersonGroupIndividual.copyOf(original, updated => {
                updated.salutation = values.salutation;
                updated.title = values.title;
                updated.firstName = values.firstName;
                updated.lastName = values.lastName;
                updated.additionalName = values.additionalName;
                updated.birthdate = values.birthdate;
                updated.role = values.role;
                updated.phone = values.phone;
                updated.email = values.email;
                updated.idNumber = values.idNumber;
                updated.idReleasedOn = values.idReleasedOn;
                updated.idReleasedBy = values.idReleasedBy;
                updated.status = values.status;
            }));
  
            // show message
            showNotification({ message: "Daten erfolgreich aktualisiert.", color: 'green', icon: <Check /> });
  
            // redirect
            navigate(-1);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }
  
    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            salutation: "",
            title: "",
            firstName: "",
            lastName: "",
            additionalName: "",
            birthdate: "",
            role: "",
            phone: "",
            email: "",
            idNumber: "",
            idReleasedOn: "",
            idReleasedBy: "",
            status: ""
        },
        submitCallback: submitCallback
    });
  
  
    return (
      <Stack>
        <Group position="apart">
          <Title>Personen {EDIT}</Title>
          <Group>
            <Button
              leftIcon={<ArrowLeft size={14} />}
              onClick={() => navigate(-1, { replace: true })}
              color="yellow"
            >
              Zurück
            </Button>
          </Group>
        </Group>
        <Divider />
  
        <Stack>
              <form
                  onSubmit={form.onSubmit()}
                  onReset={form.onReset}
              >
                  <Stack>

                        {/* status */}
                        <Select
                            withAsterisk
                            label="Status"
                            placeholder="Status"
                            data={[
                                { value: 'ACTIVE', label: 'Aktiv' },
                                { value: 'INACTIVE', label: 'Inaktiv' },
                            ]}
                            {...form.getInputProps('status')}
                        />

                        {/* salutation */}
                        <Select
                            withAsterisk
                            label="Anrede"
                            placeholder="Anrede"
                            data={[
                                { value: 'm', label: 'Herr' },
                                { value: 'f', label: 'Frau' },
                                { value: 'o', label: 'Divers' }
                            ]}
                            {...form.getInputProps('salutation')}
                        />

                        {/* title */}
                        <TextInput
                            withAsterisk
                            label="Titel"
                            placeholder="Titel"
                            {...form.getInputProps('title')}
                        />

                        {/* first name */}
                        <TextInput
                            withAsterisk
                            label="Vorname"
                            placeholder="Vorname"
                            {...form.getInputProps('firstName')}
                        />

                        {/* last name */}
                        <TextInput
                            withAsterisk
                            label="Nachname"
                            placeholder="Nachname"
                            {...form.getInputProps('lastName')}
                        />

                        {/* aditional name */}
                        <TextInput label="Namenszusatz" placeholder="Namenszusatz" {...form.getInputProps('additionalName')} />

                        {/* birthdate */}
                        <DatePickerInput 
                            withAsterisk
                            label="Geburtsdatum" 
                            placeholder="Geburtsdatum" 
                            form={form} 
                            inputKey="birthdate"                       
                        />

                        {/* role */}
                        <TextInput
                            withAsterisk
                            label="Rolle/Position"
                            placeholder="Rolle/Position"
                            {...form.getInputProps('role')}
                        />

                        {/* phone */}
                        <TextInput
                            withAsterisk
                            label="Telefonnummer"
                            placeholder="+XX XXXX(X) XXXXXXXX"
                            {...form.getInputProps('phone')}
                        />

                        {/* email */}
                        <TextInput
                            withAsterisk
                            label="E-Mail-Adresse"
                            placeholder="name@email.com"
                            {...form.getInputProps('email')}
                        />

                        {/* id number */}
                        <TextInput 
                            withAsterisk
                            label="Personalausweis-/Reisepass-Nummer" 
                            placeholder="Personalausweis-/Reisepass-Nummer" 
                            {...form.getInputProps('idNumber')} 
                        />

                        {/* id released on */}
                        <DatePickerInput
                            withAsterisk
                            label="Personalausweis/Reisepass ausgestellt am" 
                            placeholder="Personalausweis/Reisepass ausgestellt am" 
                            form={form} 
                            inputKey="idReleasedOn" 
                        />

                        {/* id released by */}
                        <TextInput 
                            withAsterisk
                            label="Personalausweis/Reisepass ausgestellt durch" 
                            placeholder="Personalausweis/Reisepass ausgestellt durch" 
                            {...form.getInputProps('idReleasedBy')}
                        />
                        <Divider />
  
                        <Group position='apart'>
                            <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                            <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                        </Group>
                  </Stack>
              </form>
          </Stack>
      </Stack>
    );
  }
  