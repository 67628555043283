import { useParams } from "react-router-dom";
import * as Routes from "../../helpers/Routes";
import { SinglePersonEntity } from "../../models";
import { SINGLE_PERSON_ENTITY } from "../../helpers/Constants";
import EntityViewPageTemplate from "../../components/EntityViewPageTemplate";
import { titleSinglePersonEntity } from "../../helpers/Functions";
import { fieldsEntity } from "../../helpers/FormFieldsProcessor";

/**
 * Page used to View the details of an existing Single Person entity for admin and support
 * @returns JSX
 */
export default function PageSupportEntitiesViewSinglePersonEntity() {
    const { id } = useParams();

    const singlePersonEntityFields = fieldsEntity(SinglePersonEntity);

    const routes = {
        entityEdit: Routes.ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_EDIT,
        FORM_CHECK_ANIMALCREATE: Routes.ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_SINGLE_PERSON_ENTITY,
        FORM_ADOPT_TEMPORARILYCREATE: Routes.ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_SINGLE_PERSON_ENTITY,
        FORM_ADOPT_PERMANENTLYCREATE: Routes.ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_SINGLE_PERSON_ENTITY,
        FORM_BRING_IN_ANIMALCREATE: Routes.ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_SINGLE_PERSON_ENTITY,
        FORM_CHECK_ANIMALVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALVIEW: Routes.ROUTE_SUPPORT_FORMULARS_VIEW_BRING_IN_ANIMAL,
        FORM_CHECK_ANIMALEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_FOLLOWUP_CHECK,
        FORM_ADOPT_TEMPORARILYEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_TEMPORARILY_ADOPTION,
        FORM_ADOPT_PERMANENTLYEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_PERMANENTLY_ADOPTION,
        FORM_BRING_IN_ANIMALEDIT: Routes.ROUTE_SUPPORT_FORMULARS_EDIT_BRING_IN_ANIMAL,
    };

    return (
        <EntityViewPageTemplate
            id={id}
            isAdmin={true}
            EntityType={SinglePersonEntity}
            entityFields={singlePersonEntityFields}
            filterKey='entityId'
            routes={routes}
            title={SINGLE_PERSON_ENTITY}
            getEntityTitle={titleSinglePersonEntity}
        />
    );
}
