import { Accordion, Button, Group, Stack, TextInput } from "@mantine/core";
import { DataStore, SortDirection } from "aws-amplify";
import { Filter, Reload } from "tabler-icons-react";
import { PREDICATE_CONTAINS, PREDICATE_EQUALS } from "../helpers/Constants";
import { useUserState } from "../helpers/GlobalUserState";
import { getFilterPredicates } from "../helpers/Predicates";
import { useForm } from "./Form";
import List from "./List";
import SortDirectionInput from "./SortDirectionInput";
import SortFieldInput from "./SortFieldInput";
import SelectAnimal from "./SelectAnimal";
import { useEffect, useState } from "react";
import { Animals } from "../models";

/**
 * Form listing template component
 * @returns JSX
 */
export default function FormListingTemplate({ animalHomeId, entityId, entityEmail, filterKey, viewRoute, editRoute, FormTypeModel }) {

    // globals
    const user = useUserState();
    const [animals, setAnimals] = useState(null);

    // form hook
    const form = useForm({
        initialValues: {
            customPdfName: "",
            animalId: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        if (!user.animalHomeId && !animalHomeId) {
            // throw new Error("no animal home id provided for form listing");
        }

        return getFilterPredicates([
            { key: "customPdfNameNormalized", value: form.values.customPdfName.toLowerCase(), type: PREDICATE_CONTAINS },
            { key: "owner", value: animalHomeId ? animalHomeId : user.animalHomeId, type: PREDICATE_EQUALS },
            { key: "animalId", value: form.values.animalId, type: PREDICATE_EQUALS },
            { key: filterKey, value: entityId, type: PREDICATE_EQUALS },
        ]);
    }

    const emailNameMap = {
        FormCheckAnimal: 'emailCheckAnimal',
        FormAdoptTemporarily: 'emailAdoptTemporarily',
        FormAdoptPermanently: 'emailAdoptPermanently',
        FormBringInAnimal: 'emailBringInAnimal',
    };

    useEffect(() => {
        async function prepareFilters(){
            
            const animals = await DataStore.query(Animals, (c) => c.owner.eq(animalHomeId ? animalHomeId : user.animalHomeId));
            setAnimals(animals.map( animal => ({
                value: animal.id,
                label: animal.name
            })));
        }

        prepareFilters();
    },
        [animalHomeId, user.animalHomeId]
    );
    
    return (
        animals &&
        <Stack>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>

                                <SelectAnimal form={form} animalhomeId={animalHomeId ? animalHomeId : user.animalHomeId} data={animals}/>

                                <TextInput
                                    label="Dateiname"
                                    placeholder="Dateiname"
                                    {...form.getInputProps('customPdfName')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'customPdfName', label: 'Dateiname' },
                                        { value: 'createdAt', label: 'Erstellzeit' },
                                        { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group position='apart'>
                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={FormTypeModel}
                id="id"
                dataStructure={[
                    { key: "customPdfName", title: "Dateiname" },
                    { key: "animalId", title: "Tier"},
                    { key: "createdAt", title: "erstellt am", type: "timestamp" },
                    { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
                ]}
                showRoute={viewRoute}
                editRoute={editRoute}
                entityEmail={entityEmail}
                emailName={emailNameMap[FormTypeModel.name]}
                filter={getFilter()}
                sort={s => s[form.values.sortField](form.values.sortDirection)}
            />
        </Stack>
    );
}