import {
  Button,
  Divider,
  Group,
  Select,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useEffect } from "react";
import * as Yup from 'yup';
import { ArrowLeft, Check, File, Reload } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { DataStore } from "aws-amplify";
import { ROUTE_NOT_FOUND } from "../../helpers/Routes";
import { SinglePersonEntity } from "../../models";
import { showNotification } from "@mantine/notifications";
import { useForm } from "../../components/Form";
import { VALIDATION_SCHEMA_ADDRESS, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_NAME, VALIDATION_SCHEMA_PHONE } from "../../helpers/Validation";
import { EDIT } from "../../helpers/Constants";
import DatePickerInput from "../../components/DatePickerInput";

// validation schema with yup
const validationSchema = Yup.object().shape({
  salutation: VALIDATION_SCHEMA_NAME,
  title: VALIDATION_SCHEMA_NAME,
  firstName: VALIDATION_SCHEMA_NAME,
  lastName: VALIDATION_SCHEMA_NAME,
  street: VALIDATION_SCHEMA_ADDRESS,
  zip: VALIDATION_SCHEMA_ADDRESS,
  city: VALIDATION_SCHEMA_ADDRESS,
  country: VALIDATION_SCHEMA_ADDRESS,
  birthdate: VALIDATION_SCHEMA_PHONE,
  phone: VALIDATION_SCHEMA_PHONE,
  email: VALIDATION_SCHEMA_EMAIL,
  idNumber: VALIDATION_SCHEMA_PHONE,
  idReleasedOn: VALIDATION_SCHEMA_PHONE,
  idReleasedBy: VALIDATION_SCHEMA_NAME,
});

/**
 * implementation of animal home user home page
 * @returns JSX
 */
export default function PageAnimalHomeEntitiesEditSinglePersonEntity() {

  // globals
  const navigate = useNavigate();
  const { id } = useParams();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();

  /**
   * wrapper to prepare the page
   */
  const preparePage = async () => {
    try {
        if (id) {
            // get item
            setLoading(LOADING_SHOW);

            const singleEntity = await DataStore.query(SinglePersonEntity, id);

            if (!singleEntity) {
                navigate(ROUTE_NOT_FOUND);
            }

            // set data
            form.setInitialValues({
                salutation: singleEntity.salutation,
                title: singleEntity.title,
                firstName: singleEntity.firstName,
                lastName: singleEntity.lastName,
                additionalName: singleEntity.additionalName,
                street: singleEntity.street,
                zip: singleEntity.zip,
                city: singleEntity.city,
                country: singleEntity.country,
                birthdate: singleEntity.birthdate,
                phone: singleEntity.phone,
                email: singleEntity.email,
                idNumber: singleEntity.idNumber,
                idReleasedOn: singleEntity.idReleasedOn,
                idReleasedBy: singleEntity.idReleasedBy,
                status: singleEntity.status
            });
        }
    }
    catch (e) {
        setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
    }
    finally {
        setLoading(LOADING_RESET);
    }
  }

  /**
   * Use effect hook to initially fetch data
   */
  useEffect(() => {
      preparePage();
  },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [id]
  );

    // submit callback
    const submitCallback = async (values) => {
      try {
          setLoading(LOADING_SHOW);

          // update item
          const original = await DataStore.query(SinglePersonEntity, id);
          await DataStore.save(SinglePersonEntity.copyOf(original, updated => {
              updated.salutation = values.salutation;
              updated.title = values.title;
              updated.firstName = values.firstName;
              updated.lastName = values.lastName;
              updated.additionalName = values.additionalName;
              updated.street = values.street;
              updated.zip = values.zip;
              updated.city = values.city;
              updated.country = values.country;
              updated.birthdate = values.birthdate;
              updated.phone = values.phone;
              updated.email = values.email;
              updated.idNumber = values.idNumber;
              updated.idReleasedOn = values.idReleasedOn;
              updated.idReleasedBy = values.idReleasedBy;
              updated.status = values.status;
          }));

          // show message
          showNotification({ message: "Daten erfolgreich aktualisiert.", color: 'green', icon: <Check /> });

          // redirect
          navigate(-1);
      }
      catch (e) {
          setError({ action: ERROR_SHOW, error: e });
      }
      finally {
          setLoading(LOADING_RESET);
      }
  }

  // form hook
  const form = useForm({
      validationSchema: validationSchema,
      initialValues: {
          salutation: "",
          title: "",
          firstName: "",
          lastName: "",
          additionalName: "",
          street: "",
          zip: "",
          city: "",
          country: "",
          birthdate: "",
          phone: "",
          email: "",
          idNumber: "",
          idReleasedOn: "",
          idReleasedBy: "",
          status: ""
      },
      submitCallback: submitCallback
  });


  return (
    <Stack>
      <Group position="apart">
        <Title>Einzelperson Entität {EDIT}</Title>
        <Group>
          <Button
            leftIcon={<ArrowLeft size={14} />}
            onClick={() => navigate(-1, { replace: true })}
            color="yellow"
          >
            Zurück
          </Button>
        </Group>
      </Group>
      <Divider />

      <Stack>
            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>

                    {/* entity status */}
                    <Select
                        withAsterisk
                        label="Status"
                        placeholder="Status"
                        data={[
                            { value: 'ACTIVE', label: 'Aktiv' },
                            { value: 'INACTIVE', label: 'Inaktiv' },
                        ]}
                        {...form.getInputProps('status')}
                    />

                    {/* salutation */}
                    <Select
                        withAsterisk
                        label="Anrede"
                        placeholder="Anrede"
                        data={[
                            { value: 'm', label: 'Herr' },
                            { value: 'f', label: 'Frau' },
                            { value: 'o', label: 'Divers' }
                        ]}
                        {...form.getInputProps('salutation')}
                    />
                    <TextInput
                        withAsterisk
                        label="Titel"
                        placeholder="Titel"
                        {...form.getInputProps('title')}
                    />
                    <TextInput
                        withAsterisk
                        label="Vorname"
                        placeholder="Vorname"
                        {...form.getInputProps('firstName')}
                    />
                    <TextInput
                        withAsterisk
                        label="Nachname"
                        placeholder="Nachname"
                        {...form.getInputProps('lastName')}
                    />
                    <TextInput
                        label="Name zusatz"
                        placeholder="Johnass"
                        {...form.getInputProps('additionalName')}
                    />
                    <TextInput
                        withAsterisk
                        label="Adresse"
                        placeholder="Adresse"
                        {...form.getInputProps('street')}
                    />
                    <TextInput
                        withAsterisk
                        label="PLZ"
                        placeholder="PLZ"
                        {...form.getInputProps('zip')}
                    />
                    <TextInput
                        withAsterisk
                        label="Ort"
                        placeholder="Ort"
                        {...form.getInputProps('city')}
                    />
                    <TextInput
                        withAsterisk
                        label="Land"
                        placeholder="Land"
                        {...form.getInputProps('country')}
                    />
                    <DatePickerInput
                        label="Geburtsdatum" 
                        placeholder="Geburtsdatum" 
                        form={form} 
                        inputKey="birthdate" 
                        pt="xs"
                    />
                    <TextInput
                        withAsterisk
                        label="Telefonnummer"
                        placeholder="+XX XXXX(X) XXXXXXXX"
                        {...form.getInputProps('phone')}
                    />
                    <TextInput
                        withAsterisk
                        label="E-Mail-Adresse"
                        placeholder="name@email.com"
                        {...form.getInputProps('email')}
                    />
                    <TextInput 
                        label="Personalausweis-/Reisepass-Nummer" 
                        placeholder="Personalausweis-/Reisepass-Nummer" 
                        {...form.getInputProps('idNumber')} 
                        pt="xs"
                    />
                    <DatePickerInput 
                        label="Personalausweis/Reisepass ausgestellt am" 
                        placeholder="Personalausweis/Reisepass ausgestellt am" 
                        form={form} 
                        inputKey="idReleasedOn" 
                        pt="xs"
                    />
                    <TextInput 
                        label="Personalausweis/Reisepass ausgestellt durch" 
                        placeholder="Personalausweis/Reisepass ausgestellt durch" 
                        {...form.getInputProps('idReleasedBy')} 
                        pt="xs"
                    />
                    <Divider />

                    <Group position='apart'>
                        <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                        <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    </Stack>
  );
}
