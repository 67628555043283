import React, { useEffect, useState } from "react";
import { ActionIcon, Badge, Button, Grid, Group, Paper, Stack, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Edit, ReportMoney } from "tabler-icons-react";
import DisplayImagesAndDocumentsInView from "./DisplayImagesAndDocumentsInView";
import { CREATE, PAYMENT } from "../helpers/Constants";
import * as Routes from "../helpers/Routes";
import AnimalTimeline from "./AnimalTimeline";
import ActionsMenu from "./ActionsMenu";
import t from "../helpers/Translations";
import { DataStore } from "aws-amplify";
import { AnimalBreeds, AnimalRaces } from "../models";
import { displayDate } from "../helpers/Functions";

export default function AnimalDetails({ animal, animalFields, editRoute, isAdmin }) {
    const navigate = useNavigate();
    const [race, setRace] = useState(null);
    const [breed, setBreed] = useState(null);

    const routes = {
        FORM_CHECK_ANIMALCREATE: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_ANIMAL : Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_FOLLOWUP_CHECK_FOR_ANIMAL,
        FORM_ADOPT_TEMPORARILYCREATE: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_ANIMAL : Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_TEMPORARILY_ADOPTION_FOR_ANIMAL,
        FORM_ADOPT_PERMANENTLYCREATE: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_ANIMAL : Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_PERMANENTLY_ADOPTION_FOR_ANIMAL,
        FORM_BRING_IN_ANIMALCREATE: isAdmin ? Routes.ROUTE_SUPPORT_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_ANIMAL : Routes.ROUTE_ANIMALHOME_FORMULARS_CREATE_BRING_IN_ANIMAL_FOR_ANIMAL,
        FORM_PAYMENTCREATE: isAdmin ? Routes.ROUTE_SUPPORT_PAYMENTS_CREATE_PAYMENT_FOR_ANIMAL : Routes.ROUTE_ANIMALHOME_PAYMENTS_CREATE_PAYMENT_FOR_ANIMAL,
    };

    useEffect(() => {
        async function fetchData() {
            const race = await DataStore.query(AnimalRaces, animal.raceID);
            const breed = await DataStore.query(AnimalBreeds, animal.breedID);
            setRace(race);
            setBreed(breed);
          }
        fetchData();
    }, [animal.breedID, animal.raceID])

    const renderField = (field, animal) => {
        
        switch (field) {
            case "scannedDocuments":
            case "imageMain":
            case "imagesAdditional":
                return (
                    <Grid.Col span={6}>
                        <DisplayImagesAndDocumentsInView
                            imageMain={field === "imageMain" ? animal[field] : ""}
                            petImages={field === "imagesAdditional" ? animal[field] : []}
                            scannedDocuments={field === "scannedDocuments" ? animal[field] : []}
                            owner={animal.owner}
                        />
                    </Grid.Col>
                );
            case "status":
                return ( <Grid.Col span={6}><Badge variant="outline">{t(animal[field], field)}</Badge></Grid.Col> );
            case "raceID":
                return race && ( <Grid.Col span={6}><Text>{race.label}</Text></Grid.Col> );
            case "breedID":
                return breed && ( <Grid.Col span={6}><Text>{breed.label}</Text></Grid.Col> );
            case "castratedPet":
                return ( <Grid.Col span={6}><Badge variant="outline">{animal[field] ? "Ja" : "Nej"}</Badge></Grid.Col> );
            case "gender":
                return ( <Grid.Col span={6}><Badge variant="outline">{t(animal[field], field)}</Badge></Grid.Col> );
            case "birthDate":
            case "bringInDate":
            case "leaveDate":
                return ( <Grid.Col span={6}><Text variant="outline">{displayDate(animal[field])}</Text></Grid.Col> );
            case "nameNormalized":
                return;
            default:
                return ( <Grid.Col span={6}><Text>{animal[field]}</Text></Grid.Col> );
        }
    }

    return (
        <Stack>
            <Paper shadow="xs" p="xl">
                <Group>
                    <ActionIcon color="blue" onClick={() => navigate(`${editRoute}/${animal.id}`)}>
                        <Edit />
                    </ActionIcon>

                    {/* all 4 clasic forms buttons */}
                    <ActionsMenu modelObjectData={animal} isAdmin={isAdmin} routes={routes}/>

                    {/* payment button */}
                    <Button
                        leftIcon={<ReportMoney size={24} />}
                        onClick={() => navigate(`${routes['FORM_PAYMENTCREATE']}/${animal.id}/${isAdmin ? animal.owner : ''}`)}
                        color="green"
                        key={'FORM_PAYMENTCREATE'}
                    >
                        {PAYMENT} {CREATE}
                    </Button>
                </Group>

                <Grid mt={5}>
                    <Grid.Col span={12} md={8}>
                        <Grid grow>
                            {animalFields.map(field => (
                                <React.Fragment key={field} >
                                    <Grid.Col span={12} sm={6}><Text fw={700}>{t(field, "animal")}</Text></Grid.Col>
                                    {renderField(field, animal)}
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid.Col>
                    <Grid.Col span={12} md={4}>
                        <AnimalTimeline animalId={animal.id} />
                    </Grid.Col>
                </Grid>
            </Paper>
        </Stack>
    );
}
