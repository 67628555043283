import { useParams } from "react-router-dom";
import { ROUTE_ANIMALHOME_FORMULARS_EDIT_TEMPORARILY_ADOPTION } from "../../helpers/Routes";
import { FormAdoptTemporarily } from "../../models";
import FormViewTemplate from "../../components/FormViewTemplate";
import { FORM_ADOPT_TEMPORARILY } from "../../helpers/Constants";
import { fieldsForm } from "../../helpers/FormFieldsProcessor";

/**
 * Page used to View the details of an existing Temporary Adoption form
 * @returns JSX
 */
export default function PageAnimalHomeFormsViewTemporarilyAdoption() {

  const { id } = useParams();

  const formAdoptPermanentlyFields = fieldsForm(FormAdoptTemporarily);

  return (
    <FormViewTemplate
      id={id}
      formModel={FormAdoptTemporarily}
      formName={FORM_ADOPT_TEMPORARILY}
      formFields={formAdoptPermanentlyFields}
      editFormRoute={ROUTE_ANIMALHOME_FORMULARS_EDIT_TEMPORARILY_ADOPTION}
    />
  );
}
