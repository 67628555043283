import {
  Button,
  Divider,
  Group,
  Select,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useEffect } from "react";
import * as Yup from 'yup';
import { ArrowLeft, Check, File, Reload } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { DataStore } from "aws-amplify";
import { ROUTE_NOT_FOUND } from "../../helpers/Routes";
import { PersonGroupEntity } from "../../models";
import { showNotification } from "@mantine/notifications";
import { useForm } from "../../components/Form";
import { VALIDATION_SCHEMA_ADDRESS, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_GROUP_ENTITY_TYPE, VALIDATION_SCHEMA_NAME, VALIDATION_SCHEMA_PHONE } from "../../helpers/Validation";
import { EDIT } from "../../helpers/Constants";

// validation schema with yup
const validationSchema = Yup.object().shape({
  type: VALIDATION_SCHEMA_GROUP_ENTITY_TYPE,
  name: VALIDATION_SCHEMA_NAME,
  street: VALIDATION_SCHEMA_ADDRESS,
  zip: VALIDATION_SCHEMA_ADDRESS,
  city: VALIDATION_SCHEMA_ADDRESS,
  country: VALIDATION_SCHEMA_ADDRESS,
  phone: VALIDATION_SCHEMA_PHONE,
  email: VALIDATION_SCHEMA_EMAIL,
});

/**
 * implementation of animal home user home page
 * @returns JSX
 */
export default function PageAnimalHomeEntitiesEditPersonGroupEntity() {

  // globals
  const navigate = useNavigate();
  const { id } = useParams();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();

  /**
   * wrapper to prepare the page
   */
  const preparePage = async () => {
    try {
        if (id) {
            // get item
            setLoading(LOADING_SHOW);

            const groupEntity = await DataStore.query(PersonGroupEntity, id);

            if (!groupEntity) {
                navigate(ROUTE_NOT_FOUND);
            }

            // set data
            form.setInitialValues({
              type: groupEntity.type,
              name: groupEntity.name,
              street: groupEntity.street,
              zip: groupEntity.zip,
              city: groupEntity.city,
              country: groupEntity.country,
              phone: groupEntity.phone,
              email: groupEntity.email,
              status: groupEntity.status
            });
      }
    }
    catch (e) {
        setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
    }
    finally {
        setLoading(LOADING_RESET);
    }
  }

  /**
   * Use effect hook to initially fetch data
   */
  useEffect(() => {
      preparePage();
  },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [id]
  );

    // submit callback
    const submitCallback = async (values) => {
      try {
          setLoading(LOADING_SHOW);

          // update item
          const original = await DataStore.query(PersonGroupEntity, id);
          await DataStore.save(PersonGroupEntity.copyOf(original, updated => {
              updated.type = values.type;
              updated.name = values.name;
              updated.street = values.street;
              updated.zip = values.zip;
              updated.city = values.city;
              updated.country = values.country;
              updated.phone = values.phone;
              updated.email = values.email;
              updated.status = values.status;
          }));

          // show message
          showNotification({ message: "Personengruppe aktualisiert.", color: 'green', icon: <Check /> });

          // redirect
          navigate(-1);
      }
      catch (e) {
          setError({ action: ERROR_SHOW, error: e });
      }
      finally {
          setLoading(LOADING_RESET);
      }
  }

  // form hook
  const form = useForm({
      validationSchema: validationSchema,
      initialValues: {
          type: "",
          name: "",
          street: "",
          zip: "",
          city: "",
          country: "",
          phone: "",
          email: "",
          status: ""
      },
      submitCallback: submitCallback
  });


  return (
    <Stack>
      <Group position="apart">
        <Title>Personengruppe {EDIT}</Title>
        <Group>
          <Button
            leftIcon={<ArrowLeft size={14} />}
            onClick={() => navigate(-1, { replace: true })}
            color="yellow"
          >
            Zurück
          </Button>
        </Group>
      </Group>
      <Divider />

      <Stack>
            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>

                    {/* entity status */}
                    <Select
                        withAsterisk
                        label="Status"
                        placeholder="Status"
                        data={[
                            { value: 'ACTIVE', label: 'Aktiv' },
                            { value: 'INACTIVE', label: 'Inaktiv' },
                        ]}
                        {...form.getInputProps('status')}
                    />

                    <TextInput
                        withAsterisk
                        label="Typ"
                        placeholder="e.g. Verein, Amt, Unternehmen"
                        {...form.getInputProps('type')}
                    />
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Name"
                        {...form.getInputProps('name')}
                    />
                    <TextInput
                        withAsterisk
                        label="Adresse"
                        placeholder="Adresse"
                        {...form.getInputProps('street')}
                    />
                    <TextInput
                        withAsterisk
                        label="PLZ"
                        placeholder="PLZ"
                        {...form.getInputProps('zip')}
                    />
                    <TextInput
                        withAsterisk
                        label="Ort"
                        placeholder="Ort"
                        {...form.getInputProps('city')}
                    />
                    <TextInput
                        withAsterisk
                        label="Land"
                        placeholder="Land"
                        {...form.getInputProps('country')}
                    />
                    <TextInput
                        withAsterisk
                        label="Telefonnummer"
                        placeholder="+XX XXXX(X) XXXXXXXX"
                        {...form.getInputProps('phone')}
                    />
                    <TextInput
                        withAsterisk
                        label="E-Mail-Adresse"
                        placeholder="name@email.com"
                        {...form.getInputProps('email')}
                    />
                    <Divider />

                    <Group position='apart'>
                        <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                        <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    </Stack>
  );
}
