import { DataStore } from "aws-amplify";
import { AnimalHomeUser, Animals, Donations, PersonGroupEntity, PersonGroupIndividual, SinglePersonEntity } from "../models";
import { useEffect, useState } from "react";
import { Card, Divider, Group, Stack, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { ROUTE_ANIMALHOME_ANIMALS, ROUTE_ANIMALHOME_DETAILS, ROUTE_ANIMALHOME_ENTITY_LISTING, ROUTE_ANIMALHOME_PAYMENTS } from "../helpers/Routes";
import { useUserState } from "../helpers/GlobalUserState";
import { USERGROUP_ANIMALHOME_ADMIN } from "../helpers/Constants";

export default function Statistics({animalHomeUser, animalHomeAdmin, support, admin, animalHomeId}) {

    const [stats, setStats] = useState({
      totalNumberOfEntities: 0,
      totalNumberOfAnimals: 0,
      totalNumberOfUsers: 0,
      totalNumberOfPayments: 0,
      totalAmountOfPayments: 0,
      recentlyAddedEntities: 0,
      recentlyAddedAnimals: 0,
      recentlyAddedUsers: 0,
    })

    const user = useUserState();
    
    const navigate = useNavigate();

    let today = new Date();
    let oneMonthAgo = new Date(new Date().setDate(today.getDate() - 30));

    // yyyy-mm-dd
    oneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
      const singlePersonEntities = await DataStore.query(SinglePersonEntity, (c) => c.owner.eq(animalHomeId));
      const personGroupEntities = await DataStore.query(PersonGroupEntity, (c) => c.owner.eq(animalHomeId));
      const personGroupIndividuals = await DataStore.query(PersonGroupIndividual, (c) => c.owner.eq(animalHomeId));
      const animals = await DataStore.query(Animals, (c) => c.owner.eq(animalHomeId));
      const users = await DataStore.query(AnimalHomeUser, (c) => c.animalHomeId.eq(animalHomeId));
      const payments = await DataStore.query(Donations, (c) => c.owner.eq(animalHomeId));

      setStats(prev => ({
        ...prev,
        totalNumberOfEntities: singlePersonEntities.length + personGroupEntities.length + personGroupIndividuals.length,
        totalNumberOfAnimals: animals.length,
        totalNumberOfUsers: users.length,
        totalNumberOfPayments: payments.length,
        totalAmountOfPayments: payments.reduce((sum, payment) => {
          const amountNumber = parseFloat(payment.amount);
          return sum + (isNaN(amountNumber) ? 0 : amountNumber);
        }, 0),
        recentlyAddedEntities: singlePersonEntities
          .concat(personGroupIndividuals)
          .concat(personGroupEntities)
          .filter(entity => new Date(entity['createdAt']).toISOString().split('T')[0] > oneMonthAgo)
          .length,
        recentlyAddedAnimals: animals.filter(animal => new Date(animal['createdAt']).toISOString().split('T')[0] > oneMonthAgo).length,
        recentlyAddedUsers: users.filter(user => new Date(user['createdAt']).toISOString().split('T')[0] > oneMonthAgo).length
      }));
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const numberFormat = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });
    const formattedTotalAmountOfPayments = numberFormat.format(stats.totalAmountOfPayments);

    // background-image: linear-gradient(-60deg,var(--mantine-color-blue-4) 0,var(--mantine-color-blue-7) 100%);
    const enititiesCardStyle = {
      backgroundImage: "linear-gradient(-270deg, royalBlue 0, lightSkyBlue 100%)",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer"
    }

    const animalsCardStyle = {
      backgroundImage: "linear-gradient(-270deg, #FC8C0C 0, #FFAB09 100%)",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer"
    }

    const usersCardStyle = {
      backgroundImage: "linear-gradient(-270deg, #F21616 0, #F0185C 100%)",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer"
    }

    const paymentsCardStyle = {
      backgroundImage: "linear-gradient(-270deg, #2BDD66 0, #6BD731 100%)",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer"
    }

    return (
      <Stack style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
        <Group>
              <Title order={3} mb='md'>Statistiken</Title>
        </Group>
        <Group>
          {/* entities */}
          <Card shadow="sm" padding="lg" radius="md" withBorder style={enititiesCardStyle} onClick={() => {navigate(ROUTE_ANIMALHOME_ENTITY_LISTING)}}>
            Zuletzt hinzugefügte Entitäten: {stats.recentlyAddedEntities}
          </Card>
          <Card shadow="sm" padding="lg" radius="md" withBorder style={enititiesCardStyle} onClick={() => {navigate(ROUTE_ANIMALHOME_ENTITY_LISTING)}}>
            Anzahl an Entitäten: {stats.totalNumberOfEntities}
          </Card>

          {/* animals */}
          <Card shadow="sm" padding="lg" radius="md" withBorder style={animalsCardStyle} onClick={() => {navigate(ROUTE_ANIMALHOME_ANIMALS)}}>
            Zuletzt hinzugefügte Tiere: {stats.recentlyAddedAnimals}
          </Card>
          <Card shadow="sm" padding="lg" radius="md" withBorder style={animalsCardStyle} onClick={() => {navigate(ROUTE_ANIMALHOME_ANIMALS)}}>
            Anzahl an Tieren: {stats.totalNumberOfAnimals}
          </Card>

          { user && user.userGroups.includes(USERGROUP_ANIMALHOME_ADMIN) &&
            <>
              {/* users */}
              <Card shadow="sm" padding="lg" radius="md" withBorder style={usersCardStyle} onClick={() => {navigate(ROUTE_ANIMALHOME_DETAILS)}}>
                Zuletzt hinzugefügte Benutzer: {stats.recentlyAddedUsers}
              </Card>
              <Card shadow="sm" padding="lg" radius="md" withBorder style={usersCardStyle} onClick={() => {navigate(ROUTE_ANIMALHOME_DETAILS)}}>
                Anzahl an Benutzern: {stats.totalNumberOfUsers}
              </Card>
            </>
          }
        </Group>

        <Group>
          {/* payments */}
          <Card shadow="sm" padding="lg" radius="md" withBorder style={paymentsCardStyle}>
            <Group direction="row">
                <Text onClick={() => {navigate(ROUTE_ANIMALHOME_PAYMENTS)}}>Anzahl an Zahlungen: {stats.totalNumberOfPayments}</Text>

                <Divider orientation="vertical" color="white" />
              
                <Text onClick={() => {navigate(ROUTE_ANIMALHOME_PAYMENTS)}}>Summe der Zahlungen: {formattedTotalAmountOfPayments}</Text>
            </Group>
          </Card>
        </Group>
      </Stack>
    )
}