import { useParams } from "react-router-dom";
import { Animals, FormAdoptTemporarily as FormAdoptTemporarilyModel, SinglePersonEntity } from "../../models";
import { FORM_ADOPT_TEMPORARILY, PDF_ADOPT_TEMPORARILY } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import FormAdoptTemporarily from "../../formulars/FormAdoptTemporarily";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION } from "../../helpers/Routes";

/**
 * Page used to create a new Temporary Adoption form for a Single Person Entity
 * @returns JSX
 */
export default function PageSupportFormsCreateTemporarilyAdoptionForSinglePersonEntity() {
    const { id, animalHomeId } = useParams();

    return (
        <FormCreatePageTemplate
            FormComponent={FormAdoptTemporarily}
            FormName={FormAdoptTemporarilyModel.name}
            animalHomeId={animalHomeId}
            entityId={id}
            entityTypeModel={SinglePersonEntity}
            animalsTypeModel={Animals}
            formTitle={FORM_ADOPT_TEMPORARILY}
            initialValues={initialValuesForm(FormAdoptTemporarilyModel)}
            pdfName={PDF_ADOPT_TEMPORARILY}
            viewRoute={ROUTE_SUPPORT_FORMULARS_VIEW_TEMPORARILY_ADOPTION}
        />
    )
}