import React from 'react';
import { useForm } from "./Form";
import { executeApiCall, API_POST, API_PDF_NAME, FILE_PDF_ROUTE} from '../helpers/APIHelper.js';
import { useUserState } from "../helpers/GlobalUserState";
import { useNavigate } from 'react-router-dom';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState';
import { ACTION_ADOPT_PERMANENTLY, ACTION_ADOPT_TEMPORARILY, ACTION_BRING_IN, ACTION_CHECK_ANIMAL, ADOPT_PERMANENTLY, ADOPT_TEMPORARILY, BRING_IN, CHECK_ANIMAL, STATUS_PERMANENTLY_ADOPTED, STATUS_TEMPORARILY_ADOPTED } from '../helpers/Constants';
import { DataStore } from 'aws-amplify';
import { Actions, Animals, Donations, PersonGroupIndividual } from '../models';

export default function FormCreateTemplate({ animalHomeId, entityId, animals, pdfName, FormName, viewRoute, initialValues, FormComponent, animalId, entities, defaultEntitySelectValue, validationSchema }) {

    // globals
    const user = useUserState();
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const animalhomeId = animalHomeId ? animalHomeId : user.animalHomeId;

    const actionType = {
        [CHECK_ANIMAL]: ACTION_CHECK_ANIMAL,
        [ADOPT_TEMPORARILY]: ACTION_ADOPT_TEMPORARILY,
        [ADOPT_PERMANENTLY]: ACTION_ADOPT_PERMANENTLY,
        [BRING_IN]: ACTION_BRING_IN
    };

    const newStatus = {
        [ACTION_ADOPT_TEMPORARILY]: STATUS_TEMPORARILY_ADOPTED,
        [ACTION_ADOPT_PERMANENTLY]: STATUS_PERMANENTLY_ADOPTED
    }

    const submitCallback = async (values) => {

        values.owner = animalhomeId;

        if(entityId){
            values.entityId = entityId;
        }

        if(!values.entityId && defaultEntitySelectValue){
            values.entityId = defaultEntitySelectValue
        }

        try {
            setLoading(LOADING_SHOW);
            const route = FILE_PDF_ROUTE;
            const body = { 
                formData: values, 
                userData: user,
                pdfName: pdfName,
                table: FormName,
            };
            
            const response = await executeApiCall(API_PDF_NAME, API_POST, route, body);

            if(response){

                // save action in database
                await DataStore.save(new Actions({
                    owner: values.owner,
                    animalId: values.animalId,
                    entityId: values.entityId,
                    actionType: actionType[FormName]
                }));

                const entityIndividual = await DataStore.query(PersonGroupIndividual, values.entityId);

                const animal = await DataStore.query(Animals, values.animalId);
                await DataStore.save(Animals.copyOf(animal, updated => {
                    // set entity id to animal
                    updated.entityId = values.entityId;

                    // set the group id of individual if entity is PersonGroupIndividual
                    if(entityIndividual){
                        updated.entityGroupId = entityIndividual.entityGroupId;
                    } else{
                        updated.entityGroupId = "";
                    }

                    // update the status if necesary
                    if(actionType[FormName] === ACTION_ADOPT_TEMPORARILY || actionType[FormName] === ACTION_ADOPT_PERMANENTLY){
                        updated.status = newStatus[actionType[FormName]];
                    }
                }));

                // save donation in database if petAdoptionFeeNo is set
                if(values.petAdoptionFeeNo && values.petAdoptionFeeNo !== ''){
                    await DataStore.save(new Donations({
                        expenseType: 'petAdoptionFee',
                        amount: values.petAdoptionFeeNo,
                        expensePeriod: '0',
                        startDate: values.performedActionDate,
                        endDate: null,
                        entityId: values.entityId,
                        animalId: values.animalId,
                        owner: values.owner
                    }));
                }

                // redirect
                navigate(`${viewRoute}/${response.pdf.id}`, { replace: true });
            }

        } catch (error) {
            console.error('Error:', error);
            setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    };

    // creating form object
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: initialValues,
        submitCallback: submitCallback
    });

    return (
        <FormComponent
            form={form}
            animalhomeId={animalhomeId}
            entities={entities || []}
            entityId={entityId}
            animals={animals || []}
            animalId={animalId}
            defaultEntitySelectValue={defaultEntitySelectValue}
        />
    )
}
