import { Accordion, ActionIcon, Box, Button, Divider, Grid, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { ArrowLeft, ClipboardList, Edit, Plus, DogBowl, ReportMoney } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { ROUTE_NOT_FOUND } from "../helpers/Routes";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import React, { useEffect, useState } from "react";
import { DataStore, SortDirection } from "aws-amplify";
import { CREATE, FORM_NAMES, PERSON_GROUP_INDIVIDUAL, ANIMALS, PAYMENT } from "../helpers/Constants";
import FormListingTemplate from "./FormListingTemplate";
import { FormAdoptPermanently, FormAdoptTemporarily, FormBringInAnimal, FormCheckAnimal, PersonGroupIndividual } from "../models";
import ListingTemplate from "./ListingTemplate";
import { singleEntityDataStructure, animalsDataStructure } from "./ListingDataStructures";
import { PREDICATE_EQUALS } from "../helpers/Predicates";
import { Animals } from "../models";
import ActionsMenu from "./ActionsMenu";
import t from "../helpers/Translations";
import { displayDate } from "../helpers/Functions";

/**
 * Entity View Template page to view an entity
 * @returns JSX
 */
export default function EntityViewPageTemplate({ id, EntityType, entityFields, filterKey, routes, title, getEntityTitle, isAdmin }) {

    // globals
    const navigate = useNavigate();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const [entity, setEntity] = useState(null);

    const formTypes = {
        FORM_CHECK_ANIMAL: FormCheckAnimal,
        FORM_ADOPT_TEMPORARILY: FormAdoptTemporarily,
        FORM_ADOPT_PERMANENTLY: FormAdoptPermanently,
        FORM_BRING_IN_ANIMAL: FormBringInAnimal,
    }
    const filterPredicates = [
        { key: "owner", value: entity?.owner, type: PREDICATE_EQUALS },
        { key: "entityGroupId", value: entity?.id, type: PREDICATE_EQUALS },
    ]
    const filterAnimalPredicates = [
        { key: "entityId", value: entity?.id, type: PREDICATE_EQUALS },
    ]

    const sortFieldInputData = [
        { value: 'createdAt', label: 'Erstellzeit' },
        { value: 'updatedAt', label: 'Aktualisierungszeit' },
    ]

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);

                const entity = await DataStore.query(EntityType, id);

                if (!entity) {
                    navigate(ROUTE_NOT_FOUND);
                }

                setEntity(entity);
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    return (
        entity &&
        <Stack>
            <Group position="apart">
                <Title>{title} {getEntityTitle(entity)}</Title>
                <Group>
                    <Button
                        leftIcon={<ArrowLeft size={14} />}
                        onClick={() => navigate(-1, { replace: true })}
                        color="yellow"
                    >
                        Zurück
                    </Button>
                </Group>
            </Group>
            <Divider />

            <Stack>
                <Paper shadow="xs" p="xl">
                    <Group>
                        <ActionIcon
                            color="blue"
                            onClick={() => navigate(`${routes.entityEdit}/${entity.id}`)}
                        >
                            <Edit />
                        </ActionIcon>
                        <Group>
                            {/* only person group entity has type */}
                            <ActionsMenu modelObjectData={entity} isAdmin={isAdmin} routes={routes}/>
                            {!entity.type &&
                                <Button
                                    leftIcon={<ReportMoney size={24} />}
                                    onClick={() => navigate(`${routes.paymentCreate}/${entity.id}/${isAdmin ? entity.owner : ''}`)}
                                    color="green"
                                >
                                    {PAYMENT} {CREATE}
                                </Button>
                            }
                        </Group>
                    </Group>

                    <Grid mt={5} grow>
                        {entityFields.map(field => {
                            if(["firstNameNormalized", "lastNameNormalized", "nameNormalized", "typeNormalized", "emailNormalized"].includes(field)){
                                return false;
                            }

                            return <React.Fragment key={field} >
                                <Grid.Col span={12} sm={6}><Text fw={700}>{t(field, "entity")}</Text></Grid.Col>
                                <Grid.Col span={12} sm={6}>
                                    <Text>
                                    {
                                        field === "salutation" ? t(entity[field], field) :
                                        field === "birthdate" || field === "idReleasedOn" ? displayDate(entity[field]) :
                                        entity[field]
                                    }
                                    </Text>
                                </Grid.Col>
                            </React.Fragment>
                        })}
                    </Grid>
                </Paper>
            </Stack>
            {/* list all individuals of a group */}
            {entity.type &&
                <Accordion variant="separated" defaultValue="forms">
                    <Accordion.Item value="forms">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Accordion.Control icon={<ClipboardList />}>
                                <Group>
                                    {PERSON_GROUP_INDIVIDUAL}
                                </Group>
                            </Accordion.Control>
                            <Group position="right" mr={18} style={{}}>
                                <Button color="green" leftIcon={<Plus />} onClick={() => navigate(`${routes.individualCreate}/${entity.id}/${entity.owner}`)}>
                                    {PERSON_GROUP_INDIVIDUAL} {CREATE}
                                </Button>
                            </Group>
                        </Box>
                        
                        
                        <Accordion.Panel>
                            <ListingTemplate
                                animalhomeId={entity.owner}
                                dataStructure={singleEntityDataStructure}
                                editRoute={routes.individualEdit}
                                filterPredicates={filterPredicates}
                                listTypeModel={PersonGroupIndividual}
                                showRoute={routes.individualView}
                                sortDirection={SortDirection.DESCENDING}
                                sortField="createdAt"
                                sortFieldInputData={sortFieldInputData}
                                sortByStatus={SortDirection.ASCENDING}
                                filterFields={['firstName', 'lastName', 'phone', 'email']}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            }

            <Accordion variant="separated">
                <Accordion.Item value="forms">
                    <Accordion.Control icon={<DogBowl />}>{ANIMALS}</Accordion.Control>
                    <Accordion.Panel>
                        <ListingTemplate
                            animalhomeId={entity.owner}
                            dataStructure={animalsDataStructure}
                            editRoute={routes.animalEdit}
                            filterPredicates={entity.type ? filterPredicates : filterAnimalPredicates}
                            listTypeModel={Animals}
                            showRoute={routes.animalView}
                            sortDirection={SortDirection.ASCENDING}
                            sortField="updatedAt"
                            sortFieldInputData={sortFieldInputData}
                            filterFields={['name', 'raceID', 'breedID']}
                        />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            {/* forms listing */}
            {Object.keys(FORM_NAMES).map(formName => (
                <Accordion key={formName} variant="separated">
                    <Accordion.Item value="forms">
                        <Accordion.Control icon={<ClipboardList />}>{FORM_NAMES[formName]}</Accordion.Control>
                        <Accordion.Panel>
                            <FormListingTemplate
                                animalHomeId={entity.owner}
                                entityId={entity.id}
                                filterKey={filterKey}
                                entityEmail={entity.email}
                                editRoute={routes[formName + 'EDIT']}
                                viewRoute={routes[formName + 'VIEW']}
                                FormTypeModel={formTypes[formName]}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            ))}
        </Stack>
    )
}