import { Accordion, Button, Group, Select, Stack, TextInput } from "@mantine/core";
import { Filter, Reload } from "tabler-icons-react";
import { PREDICATE_CONTAINS, getFilterPredicates } from "../helpers/Predicates";
import { useForm } from "./Form";
import List from "./List";
import SortDirectionInput from "./SortDirectionInput";
import SortFieldInput from "./SortFieldInput";
import { translateTitle } from "../helpers/Functions";
import { EMAIL_CHECK_ANIMAL } from "../helpers/Constants";
import { useEffect, useState } from "react";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { AnimalBreeds, AnimalRaces } from "../models";
/**
 * listing of animal homes
 * @returns JSX
 */
export default function ListingTemplate({
    animalhomeId,
    sortField,
    sortDirection,
    filterPredicates,
    sortFieldInputData,
    listTypeModel,
    dataStructure,
    showRoute,
    editRoute,
    entityEmail,
    isAnimalDocument,
    onRowSelect,
    sortByStatus,
    withDelete,
    filterFields
}) {

    const [races, setRaces] = useState(null);
    const [race, setRace] = useState(null);
    const [breeds, setBreeds] = useState([]);

    // form hook
    const form = useForm({
        initialValues: {
            ...filterFields?.reduce((value, key) => {
                value[key] = '';
                return value;
            }, {}),
            [sortField]: "",
            sortField: sortField,
            sortDirection: sortDirection,
        },
    });

    useEffect(() => {
        async function fetchOptions(){
            const races = await DataStore.query(AnimalRaces, Predicates.ALL,{
                sort: (s) => s.label(SortDirection.ASCENDING)
            });
            setRaces(races)

            if(form.values.raceID){
                const breeds = await DataStore.query(AnimalBreeds, (c) => c.raceID.eq(form.values.raceID), {
                    sort: (c) => c.label(SortDirection.ASCENDING)
                });
                setBreeds(breeds)
            }

        }

        fetchOptions()
    }, [form.values.raceID])

    async function getBreeds(raceId) {
        setBreeds([]);
        form.setValues({
            ...form.values,
            breedID: '',
        });

        const breeds = await DataStore.query(AnimalBreeds, (c) => c.raceID.eq(raceId), {
            sort: (c) => c.label(SortDirection.ASCENDING)
        });
        setRace(raceId);
        form.setValues({
            ...form.values,
            raceID: raceId,
        });
        if(breeds){
            setBreeds(breeds);
        }
    }

    const normalizedStrings = ["name", "firstName", "lastName", "originalName", "documentDescription", "customPdfName", "type", "email"];

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        if (!animalhomeId) {
            throw new Error("Error: No Animal Home ID provided");
        }

        const dynamicFilterPredicates = filterFields?.map(field => {
            if(normalizedStrings.includes(field)) {
                return {
                    key: field + "Normalized",
                    value: form.values[field].toLowerCase(),
                    type: PREDICATE_CONTAINS
                }
            }
            return {
                key: field,
                value: form.values[field],
                type: PREDICATE_CONTAINS
            }
        });
        // console.log("dynamicFilterPredicates", dynamicFilterPredicates);

        if(dynamicFilterPredicates){
            return getFilterPredicates([
                { key: sortField, value: form.values[sortField], type: PREDICATE_CONTAINS },
                ...filterPredicates,
                ...dynamicFilterPredicates
            ]);
        }
        return getFilterPredicates([
            { key: sortField, value: form.values[sortField], type: PREDICATE_CONTAINS },
            ...filterPredicates,
        ]);
    };

    function renderFilterInputs(filterFields){
       

        if(Array.isArray(filterFields) && filterFields.length > 0 ){
            return filterFields.map(filter  => {
                if(filter === 'raceID'){
                    return (races &&
                        <Select
                            label="Tierart"
                            placeholder="Tierart"
                            data={
                                races.map(race => ({
                                    value: race.id,
                                    label: race.label
                                }))
                            }
                            {...form.getInputProps("raceID")}
                            value={race || form.values.raceID || ''}
                            onChange={(e) => getBreeds(e)}
                            pt="xs"
                            key={filter}
                            clearable
                        />
                    )
                }
                if(filter === 'breedID'){
                    return (
                        races &&
                        <Select
                            label="Rasse"
                            placeholder="Rasse"
                            data={
                                breeds.map(breed => ({
                                    value: breed.id,
                                    label: breed.label
                                }))
                            }
                            {...form.getInputProps("breedID")}
                            value={form.values.breedID || ''}
                            pt="xs"
                            key={filter}
                            clearable
                        />
                    )
                }
                return <TextInput label={translateTitle(filter)} placeholder={translateTitle(filter)} {...form.getInputProps(filter)} key={filter}/>
            })
        } else{
            return <TextInput label={translateTitle(filterFields)} placeholder={translateTitle(filterFields)} {...form.getInputProps(filterFields)} />
        }
    }

    return (
        <Stack>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                    <Accordion.Panel>
                        <form onReset={form.onReset}>
                            <Stack>

                                {filterFields && renderFilterInputs(filterFields)}

                                <SortFieldInput
                                    data={[
                                        // { value: sortField, label: translateTitle(sortField) },
                                        ...sortFieldInputData,
                                    ]}
                                    {...form.getInputProps("sortField")}
                                />

                                <SortDirectionInput {...form.getInputProps("sortDirection")} />

                                <Group position="apart">
                                    <Button
                                        variant="outline"
                                        leftIcon={<Reload size={14} />}
                                        type="reset"
                                        color="red"
                                    >
                                        Zurücksetzen
                                    </Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={listTypeModel}
                id="id"
                dataStructure={dataStructure}
                showRoute={showRoute}
                editRoute={editRoute}
                entityEmail={entityEmail}
                emailName = {EMAIL_CHECK_ANIMAL}
                filter={getFilter()}
                sort={
                    sortByStatus ?
                    (s => s.status(sortByStatus)[form.values.sortField](form.values.sortDirection)) :
                    (s => s[form.values.sortField](form.values.sortDirection))
                }
                isAnimalDocument={isAnimalDocument}
                onRowSelect={onRowSelect}
                withDelete={withDelete}
            />
        </Stack>
    );
}
