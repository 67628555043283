import { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { DataStore } from "aws-amplify";
import { ROUTE_NOT_FOUND } from "../helpers/Routes";
import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { EDIT } from "../helpers/Constants";
import FormEditTemplate from "./FormEditTemplate";
import { initialValuesForm } from "../helpers/FormFieldsProcessor";
import { API_PUT } from "../helpers/APIHelper";
import { PersonGroupIndividual, SinglePersonEntity } from "../models";

/**
 * Edit Form Template Page to edit an animal home form
 * @returns JSX
 */
export default function FormEditPageTemplate({ id, pdfName, formTitle, FormModel, animalsTypeModel, viewRoute, FormComponent, validationSchema}) {

  // globals
  const navigate = useNavigate();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();

  const [animalhomeId, setAnimalhomeId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [animals, setAnimals] = useState(null);
  const [entity, setEntity] = useState(null);

  /**
   * wrapper to prepare the page
   */
  const preparePage = async () => {
    try {
        if (id) {
            // get item
            setLoading(LOADING_SHOW);

            // call function to get falues from database and set form
            await fetchDatabase();


        }
    }
    catch (e) {
        setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
    }
    finally {
        setLoading(LOADING_RESET);
    }
  }

  /**
   * Use effect hook to initially fetch data
   */
  useEffect(() => {
      preparePage();
  },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [id]
  );

  // fetch data from database
  async function fetchDatabase(){

    const formData = await DataStore.query(FormModel, id);
    const animals = await DataStore.query(animalsTypeModel, (c) => c.owner.eq(formData.owner));

    const singlePersonEntities = await DataStore.query(SinglePersonEntity, (c) => c.owner.eq(formData.owner));
    const personGroupIndividuals = await DataStore.query(PersonGroupIndividual, (c) => c.owner.eq(formData.owner));
    const entities = singlePersonEntities.concat(personGroupIndividuals);


    if (!formData) {
        navigate(ROUTE_NOT_FOUND);
    }

    setAnimalhomeId(formData.owner);
    setAnimals(animals.map( animal => ({
        value: animal.id,
        label: animal.name
    })));

    setEntity(entities.map( entity => ({
        value: entity.id,
        label: entity.lastName+" "+entity.firstName
    })));

    // convert empty string to null
    const mapFormData = Object.fromEntries(
        Object.entries(formData).map(([key, value]) => [key, value === null ? '' : value])
    );

    const initialValues = initialValuesForm(FormModel, mapFormData);

    // set data
    setInitialValues({
        ...initialValues,
        id: mapFormData.id,
        entityId: mapFormData.entityId,
        fileName: mapFormData.fileName,
        owner: mapFormData.owner
    });

  }

  return (
    initialValues && animals &&
    <Stack>
        <Group position="apart">
            <Title>{formTitle} {EDIT}</Title>
            <Group>
                <Button
                    leftIcon={<ArrowLeft size={14} />}
                    onClick={() => navigate(-1, { replace: true })}
                    color="yellow"
                >
                    Zurück
                </Button>
            </Group>
        </Group>
        <Divider />

        <FormEditTemplate
            entities={entity}
            animals={animals}
            animalHomeId={animalhomeId}
            FormComponent={FormComponent}
            FormName={API_PUT.toLowerCase() + FormModel.name}
            initialValues={initialValues}
            defaultAnimalSelectValue={initialValues.animalId}
            defaultEntitySelectValue={initialValues.entityId}
            pdfName={pdfName}
            viewRoute={viewRoute}
            validationSchema={validationSchema}
        />
    </Stack>
  );
}