import React from "react";
import { Animals } from "../../models";
import { ANIMAL } from "../../helpers/Constants";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import FormAnimal from "../../formulars/FormAnimal";
import { ROUTE_SUPPORT_ANIMALS_VIEW_ANIMAL } from "../../helpers/Routes";
import { useParams } from "react-router-dom";
import AnimalCreatePageTemplate from "../../components/AnimalCreatePageTemplate";

export default function PageSupportAnimalsCreateAnimal() {
    const { animalHomeId } = useParams();

    return (
        <AnimalCreatePageTemplate
            formTitle={`${ANIMAL}`}
            animalsTypeModel={Animals}
            FormComponent={FormAnimal}
            viewRoute={ROUTE_SUPPORT_ANIMALS_VIEW_ANIMAL}
            animalHomeId={animalHomeId}
            initialValues={initialValuesForm(Animals)}
        />
    );
}
