import { useParams } from "react-router-dom";
import { Animals, FormCheckAnimal as FormCheckAnimalModel } from "../../models";
import { ROUTE_SUPPORT_FORMULARS_VIEW_FOLLOWUP_CHECK } from "../../helpers/Routes";
import { FORM_CHECK_ANIMAL, PDF_CHECK_ANIMAL } from "../../helpers/Constants";
import FormCheckAnimal from "../../formulars/FormCheckAnimal";
import FormEditPageTemplate from "../../components/FormEditPageTemplate";

/**
 * Page used to Edit a Follow up Check form
 * @returns JSX
 */
export default function PageSupportFormsEditFollowupCheck() {

  const { id } = useParams();

  return (
    <FormEditPageTemplate
      id={id}
      pdfName={PDF_CHECK_ANIMAL}
      formTitle={FORM_CHECK_ANIMAL}
      FormModel={FormCheckAnimalModel}
      animalsTypeModel={Animals}
      FormComponent={FormCheckAnimal}
      viewRoute={ROUTE_SUPPORT_FORMULARS_VIEW_FOLLOWUP_CHECK}
    />
  );
}
