import { Animals, FormAdoptPermanently as FormAdoptPermanentlyModel } from "../../models";
import { FORM_ADOPT_PERMANENTLY, PDF_ADOPT_PERMANENTLY } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_PERMANENTLY_ADOPTION } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import FormAdoptPermanently from "../../formulars/FormAdoptPermanently";
import { validationForAdoptPermanentlyDashboard } from "../../helpers/FormValidations";

/**
 * Page used to create a new Permanently Adoption form from dashboard directly
 * @returns JSX
 */
export default function PageAnimalHomeFormsCreatePermanentlyAdoptionForDashboard() {
    const user = useUserState();

    return (
        <FormCreatePageTemplate
            FormComponent={FormAdoptPermanently}
            FormName={FormAdoptPermanentlyModel.name}
            animalHomeId={user.animalHomeId}
            formTitle={FORM_ADOPT_PERMANENTLY}
            animalsTypeModel={Animals}
            initialValues={initialValuesForm(FormAdoptPermanentlyModel)}
            pdfName={PDF_ADOPT_PERMANENTLY}
            viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_PERMANENTLY_ADOPTION}
            validationSchema={validationForAdoptPermanentlyDashboard}
        />
    )
}