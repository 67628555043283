
const actionType = {
  followUpCheck: "Nachkontrolle",
  temporarilyAdopt: "Pflegevertrag",
  permanentlyAdopt: "Schutzvertrag",
  bringIn: "Aufnahme",
  sendEmail: "E-Mail senden",
  downloadPdf: "PDF herunterladen",
  castration: "Kastration",
  vaccination: "Impfung",
  doctorAppointment: "Arzttermin",
  animalDeath: "Verstorben",
  treatmentStart: "Behandlungsbeginn",
  treatmentEnd: "Behandlungsende",
  donationReceived: "Spende erhalten",
}

const status = {
  inShelterAdoptionAvailable: 'Verfügbar',
  inShelterAdoptionNotAvailable: 'Nicht verfügbar',
  temporarilyAdopted: 'Vorübergehend übernommen',
  permanentlyAdopted: 'Dauerhaft übernommen',
  atVet: 'Tierarzt',
  deadAnimal: 'Verstorben',
}

const expenseType = {
  donationOnce: 'Einmalige Spende',
  donationRecurent: 'Wiederkehrende Spende',
  membershipFee: 'Mitgliedsbeitrag',
  petAdoptionFee: 'Adoptionsgebühr',
  sponsorshipOnce: 'Einmalige Patenschaft',
  sponsorshipRecurent: 'Wiederkehrendes Sponsoring',
}

const documentType = {
  birthCertificate: "Geburtsurkunde",
  vaccination: "Impfung",
  castration: "Kastration",
  vetControll: "Kontrolle beim Tierarzt",
  passport: "Reisepass",
  animalBook: "Tierbuch",
  followUpCheck: "Nachkontrolle",
  otherDocs: "Sonstige Unterlagen",
}

const typeOfKeeping = {
  houseApartment: "Im Haus/In der wohnung",
  houseApartmentOutside: "Im Haus/In der Wohnung/Im Freien",
  freeRun: "Freilauf, Zwinger",
  water: "Wasser",
  feed: "Futter",
  litterbox: "Katzentoilette",
}

const bringInType = {
  foundAnimal: "Fundtier",
  taxAnimal: "Abgabe",
  insuranceAnimal: "Sicherstellung",
  seizureAnimal: "Beschlagnahmung",
  custodyAnimal: "Verwahrung",
  anotherCountryAnimal: "Übernahme aus dem Ausland",
  NGOAnimal: "Übernahme aus einem Tierschutzverein",
}

const animalPartners = {
  dog: "Hund",
  cat: "Katze",
  otherAnimal: "Sonstige Tiere",
  noAnimals: "Keine weiteren Tiere",
  children: "Kinder",
}

const petBehaviorOwner = {
  trustingFamiliar: "Zutraulich/Vertraut",
  afraid: "Ängstlich/eingeschüchtert",
}

const petBehaviorStrangers = {
  approachesStrangers: "Geht offen und ohne Scheu auf Fremde zu",
  reserved: "Zurückhaltend/abwartend, aber nicht ängstlich",
  fearfullyIntimidated: "Ängstlich/eingeschüchtert",
  aggresive: "Aggressiv/Bissig",
}

const overallImpression = {
  veryGood: "Sehr gut",
  good: "Gut",
  satisfactory: "Befriedigend",
  unsatisfactory: "Unbefriedigend",
}

const needsAnotherCheck = {
  true: "Ja",
  false: "Nein",
}

const complaint = {
  noComplaint: "Keine Beanstandung",
  complaint: "Beanstandung",
}

const inspectionType = {
  initialInspection: "Erstkontrolle",
  followUpInspection: "Folgekontrolle",
}

const isOwnedByShelter = {
  ownedByShelter: "Besitzertier",
  foundAnimal: "Fundtier",
}

const gender = {
  m: 'Männlich',
  f: 'Weiblich',
  o: 'Unbekannt',
}

const salutation = {
  m: 'Herr',
  f: 'Frau',
  o: 'Divers',
}

const animal = {
  tassoRegNo: "Tasso-Registrierungsnummer",
  euRegNo: "EU-Ausweisnummer",
  contractNumber: "Vertragsnummer",
  raceID: "Tierart",
  breedID: "Rasse",
  name: "Name",
  color: "Farbe",
  gender: "Geschlecht",
  castratedPet: "Kastriert",
  birthDate: "Geburtsdatum",
  bringInDate: "Aufnahmedatum",
  chip: "Chipnummer",
  leftEarTattoo: "Tattoo Ohr links",
  rightEarTattoo: "Tattoo Ohr Rechts",
  otherComments: "Sonstige Bemerkungen",
  status: "Status",
  leaveDate: "Abgangsdatum",
  imageMain: "Bild",
  imagesAdditional: "Weitere Bilder",
  medicalHistory: "Krankengeschichte",
  behavioralAssessment: "Verhaltensbeurteilung",
  specialCareNeeds: "Besondere Pflegebedürfnisse",
};

const entity = {
  salutation: "Anrede",
  title: "Titel",
  firstName: "Vorname",
  lastName: "Nachname",
  additionalName: "Namenszusatz",
  birthdate: "Geburtsdatum",
  role: "Rolle/Position",
  type: "Typ",
  name: "Name",
  street: "Adresse",
  zip: "PLZ",
  city: "Ort",
  country: "Land",
  phone: "Telefonnummer",
  email: "E-Mail-Adresse",
  idNumber: "Personalausweis/Reisepass-Nummer",
  idReleasedOn: "Personalausweis/Reisepass-Nummer ausgestellt am",
  idReleasedBy: "Personalausweis/Reisepass-Nummer durch",
};

const formCheck = {
  entityId: "Entitäten",
  animalId: "Tier",
  controllNumber: "Kontrollberichtsnummer",
  deliveredOn: "Vermittelt am",
  contractNumber: "Vertragsnummer",
  inspectionType: "Kontrollart",
  petOtherComments: "Sonstige Bemerkungen",
  typeOfKeeping: "Art der Haltung",
  lookingImpressions: "Optischer Eindruck",
  environment: "Umfeld",
  animalPartners: "Das Tier lebt zusammen mit",
  petBehaviorOwner: "Verhalten des Tieres gegenüber dem Halter",
  petBehaviorStrangers: "Verhalten des Tieres gegenüber Fremden",
  overallImpression: "Gesamteindruck",
  needsAnotherCheck: "Weitere Kontrollen erforderlich",
  controlMadeBy: "Kontrolle durchgeführt durch",
  dateOfControl: "Kontrolle durchgeführt am",
  timeOfControl: "Uhrzeit",
  petImages: "Bilder",
  scannedDocuments: "Weitere Dokumente",
  customPdfName: "Name des Dokuments",
};

const formTemp = {
  entityId: "Entitäten",
  animalId: "Tier",
  animalNumber: "Tiernummer",
  petAdoptedUntill: "Der Pflegezeitraum ist befristet bis",
  petOtherComments: "Sonstiges",
  performedActionDate: "Eingangsdatum",
  customPdfName: "Name des Dokuments",
};

const formPerm = {
  entityId: "Entitäten",
  animalId: "Tier",
  animalNumber: "Tiernummer",
  adoptionContractNo: "Vertrags-/Hand.-Nummer",
  isOwnedByShelter: "Eigentum",
  petAdoptionFeeNo: "Betrag zur Adoption (€)",
  petAdoptionFeeText: "Betrag zur Adoption (in Worten)",
  performedActionDate: "Eingangsdatum",
  customPdfName: "Name des Dokuments",
};

const animalhome = {
  name: "Name",
  street: "Adresse",
  zip: "PLZ",
  city: "Ort",
  country: "Land",
  phone: "Telefonnummer",
  email: "E-Mail-Adresse",
  website: "Website",
}

const formBring = {
  entityId: "Entitäten",
  animalId: "Tier",
  animalNumber: "Tiernummer",
  adoptionContractNo: "Vertrags-/Hand.-Nummer",
  bringInType: "Wie wurde das Tier eingebracht",
  petOtherComments: "Sonstige Bemerkungen",
  petAdoptionFeeNo: "Betrag zur Adoption (€)",
  petAdoptionFeeText: "Betrag zur Adoption (in Worten)",
  performedActionDate: "Eingangsdatum",
  customPdfName: "Name des Dokuments",
};

const donation = {
  animalId: "Tier",
  expenseType: "Zahlungsart",
  amount: "Betrag",
  expensePeriod: "Zeitraum",
  startDate: "Startdatum",
  endDate: "Enddatum",
  entityId: "Entitäten"
};

const groups = {
  AnimalHomeUser: "Benutzer",
  AnimalHomeAdmin: "Administrator"
}

export default function t(word, type) {
  switch (type) {
    case "animal":
      return animal[word];
    case "entity":
      return entity[word];
    case "FormCheckAnimal":
      return formCheck[word];
    case "FormAdoptTemporarily":
      return formTemp[word];
    case "FormAdoptPermanently":
      return formPerm[word];
    case "FormBringInAnimal":
      return formBring[word];
    case "Donations":
      return donation[word];
    case "actionType":
      return actionType[word];
    case "status":
      return status[word];
    case "expenseType":
      return expenseType[word];
    case "gender":
      return gender[word];
    case "documentType":
      return documentType[word];
    case "typeOfKeeping":
      return typeOfKeeping[word];
    case "bringInType":
      return bringInType[word];
    case "inspectionType":
      return inspectionType[word];
    case "isOwnedByShelter":
      return isOwnedByShelter[word];
    case "animalPartners":
      return animalPartners[word];
    case "petBehaviorOwner":
      return petBehaviorOwner[word];
    case "petBehaviorStrangers":
      return petBehaviorStrangers[word];
    case "overallImpression":
      return overallImpression[word];
    case "needsAnotherCheck":
      return needsAnotherCheck[word];
    case "salutation":
      return salutation[word];
    case "lookingImpressions":
    case "environment":
      return complaint[word];
    case "animalHome":
      return animalhome[word];
    case "groups":
      return groups[word];
    default:
      return "...";
  }
}