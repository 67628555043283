import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Plus } from "tabler-icons-react";
import { ROUTE_SUPPORT_ANIMALHOME_NEW } from "../../helpers/Routes";
import AnimalHomeListing from "../../components/AnimalHomeListing";
import { CREATE } from "../../helpers/Constants";

/**
 * implementation of support animal home listing page
 * @returns JSX
 */
export default function PageSupportAnimalHomeListing() {
  // globals
  const navigate = useNavigate();

  return (
    <Stack>
      <Group position="apart">
        <Title>Tierheim</Title>
        <Button color="green" leftIcon={<Plus />} onClick={() => navigate(ROUTE_SUPPORT_ANIMALHOME_NEW)}>
          Neues Tierheim {CREATE}
        </Button>
      </Group>
      <Divider />

      <AnimalHomeListing />
    </Stack>
  );
}
