import { useForm } from "../components/Form";
import { useNavigate } from "react-router-dom";
import { DataStore } from 'aws-amplify';
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";

/**
 * Form Edit Template component to edit an animal home form
 * @returns JSX
 */
export default function PaymentEditTemplate({ animalHomeId, animals, payment, FormName, viewRoute, initialValues, defaultAnimalSelectValue, defaultEntitySelectValue, FormComponent, entities, validationSchema }) {

  // globals
  const navigate = useNavigate();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();

  const submitCallback = async (values) => {

    try {
      setLoading(LOADING_SHOW);

      // update item
      const updatedPayment = await DataStore.save(FormName.copyOf(payment, updated => {
          updated.expenseType = values.expenseType;
          updated.amount = values.amount;
          updated.expensePeriod = values.expensePeriod;
          updated.startDate = values.startDate;
          updated.endDate = values.endDate ? values.endDate : null;
          updated.entityId = values.entityId;
          updated.animalId = values.animalId ? values.animalId : null;
          updated.scannedDocuments = values.scannedDocuments ? values.scannedDocuments : null;
      }));

      // redirect
      navigate(`${viewRoute}/${updatedPayment.id}`, { replace: true });

    } catch (error) {
      console.error("Error:", error);
      setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
    }
    finally {
      setLoading(LOADING_RESET);
    }
  };

  // creating form object
  const form = useForm({
    validationSchema: validationSchema,
    initialValues: initialValues,
    submitCallback: submitCallback
  });
  
  return (
    <FormComponent
      form={form}
      animalhomeId={animalHomeId}
      entities={entities}
      animals={animals}
      defaultAnimalSelectValue={defaultAnimalSelectValue}
      defaultEntitySelectValue={defaultEntitySelectValue}
      disableSelect={["donationOnce", "sponsorshipOnce"].includes(form.values.expenseType)}
    />
  );
}