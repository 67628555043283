import { Animals, FormBringInAnimal as FormBringInAnimalModel } from "../../models";
import { FORM_BRING_IN_ANIMAL, PDF_BRING_IN_ANIMAL } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import FormBringInAnimal from "../../formulars/FormBringInAnimal";
import { validationForBringInAnimalDashboard } from "../../helpers/FormValidations";

/**
 * Page used to create a new Follow up Check form from dashboard directly
 * @returns JSX
 */
export default function PageAnimalHomeFormsCreateBringInAnimalForDashboard() {
    const user = useUserState();

    return (
        <FormCreatePageTemplate
            FormComponent={FormBringInAnimal}
            FormName={FormBringInAnimalModel.name}
            animalHomeId={user.animalHomeId}
            formTitle={FORM_BRING_IN_ANIMAL}
            animalsTypeModel={Animals}
            initialValues={initialValuesForm(FormBringInAnimalModel)}
            pdfName={PDF_BRING_IN_ANIMAL}
            viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_BRING_IN_ANIMAL}
            validationSchema={validationForBringInAnimalDashboard}
        />
    )
}