import React from "react";
import { Animals } from "../../models";
import { ANIMAL } from "../../helpers/Constants";
import { fieldsForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_SUPPORT_ANIMALS_EDIT_ANIMAL } from "../../helpers/Routes";
import { useParams } from "react-router-dom";
import AnimalViewTemplate from "../../components/AnimalViewTemplate";

export default function PageSupportAnimalsViewAnimal() {
    const { id } = useParams();

    return (
      <AnimalViewTemplate
        id={id}
        animalFields={fieldsForm(Animals)}
        animalModel={Animals}
        editRoute={ROUTE_SUPPORT_ANIMALS_EDIT_ANIMAL}
        title={ANIMAL}
        isAdmin={true}
      />
    );
}
