import React from 'react'
import { Link } from 'react-router-dom';
import NotFoundImage from '../../images/404.png';
import { Button, Container, createStyles, Image, SimpleGrid, Text, Title } from "@mantine/core";
import { Home } from 'tabler-icons-react';
import { ROUTE_HOME } from '../../helpers/Routes';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    title: {
        fontWeight: 800,
        fontSize: 34,
        marginBottom: theme.spacing.md,

        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    control: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

    mobileImage: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    desktopImage: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },
}));

/**
 * 404 page
 * @returns JSX
 */
export default function PageGeneralNotFound() {
    const { classes } = useStyles();

    return (
        <Container className={classes.root}>
            <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
                <Image src={NotFoundImage} className={classes.mobileImage} />
                <div>
                    <Title className={classes.title}>Seite existiert nicht...</Title>
                    <Text color="dimmed" size="lg">
                        Die aufgerufene Seite existiert nicht. Bitte prüfen Sie die aufgerufene URL,
                        möglicherweise ist diese nicht korrekt, oder die Seite wurde verschoben.
                        Sollte dies ein Fehler sein, bitte kontaktieren Sie unseren Support.
                    </Text>
                    <Link to={ROUTE_HOME}><Button variant="outline" size="md" mt="xl" className={classes.control} leftIcon={<Home size={14} />}>Zurück zur Startseite</Button></Link>
                </div>
                <Image src={NotFoundImage} className={classes.desktopImage} />
            </SimpleGrid>
        </Container>
    );
}