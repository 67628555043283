import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, ActionIcon, Button, Divider, Grid, Group, Menu, Select, Stack, Table, Text, TextInput, Title, UnstyledButton } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { Check, DogBowl, Edit, Filter, List as ListIcon, Plus, Reload, Settings, Star, User, UserCheck, UserOff, Users } from "tabler-icons-react";
import { ROUTE_ANIMALHOME_EDIT, ROUTE_ANIMALHOME_USERS_NEW, ROUTE_SUPPORT_ANIMALHOME_EDIT, ROUTE_SUPPORT_ANIMALHOME_USERS_NEW, ROUTE_SUPPORT_ANIMALS_CREATE_ANIMAL, ROUTE_SUPPORT_ANIMALS_EDIT_ANIMAL, ROUTE_SUPPORT_ANIMALS_VIEW_ANIMAL, ROUTE_SUPPORT_ENTITY_NEW, ROUTE_SUPPORT_PERSON_GROUP_ENTITY_EDIT, ROUTE_SUPPORT_PERSON_GROUP_ENTITY_VIEW, ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_EDIT, ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_VIEW } from "../../helpers/Routes";
import { useForm } from "../../components/Form";
import BadgeTimestamp from '../../components/BadgeTimestamp';
import { VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL } from '../../helpers/Validation';
import { ANIMAL, CREATE, OPTIONS_YESNO_WITHALL, OPTION_ALL, OPTION_NO, OPTION_YES, USERGROUPS_ALL, USERGROUP_ANIMALHOME_ADMIN, USERGROUP_ANIMALHOME_USER } from '../../helpers/Constants';
import { LOADING_RESET, LOADING_SHOW, LOADING_SHOW_AND_HIDE_CHILDREN, useLoadingDispatch } from '../../helpers/GlobalLoadingState';
import { ERROR_SHOW, useErrorDispatch } from '../../helpers/GlobalErrorState';
import { ADMIN_API_ADMIN_ADDUSERTOGROUP, ADMIN_API_ADMIN_DISABLEUSER, ADMIN_API_ADMIN_ENABLEUSER, ADMIN_API_SUPPORT_LIST_ANIMALHOME_USERS, ADMIN_API_ADMIN_REMOVEUSERFROMGROUP, API_GET, API_POST, executeApiCall, ADMIN_API_ANIMALHOME_LIST_USERS, API_ADMIN_NAME } from '../../helpers/APIHelper';
import { showNotification } from '@mantine/notifications';
import { DataStore, SortDirection } from 'aws-amplify';
import { AnimalHome, Animals, PersonGroupEntity, SinglePersonEntity } from '../../models';
import { useUserState } from '../../helpers/GlobalUserState';
import { PREDICATE_EQUALS } from '../../helpers/Predicates';
import ListingTemplate from '../../components/ListingTemplate';
import { animalsDataStructure, groupEntityDataStructure, singleEntityDataStructure } from '../../components/ListingDataStructures';
import { fieldsAnimalHome } from '../../helpers/FormFieldsProcessor';
import t from '../../helpers/Translations';

// validation schema with yup for user filter
const validationSchemaUserFilter = Yup.object().shape({
    enabled: VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL,
});

// initial values for user filter
const initialValuesUserFilter = {
    name: "",
    email: "",
    enabled: OPTION_ALL,
    group: USERGROUPS_ALL,
};


/**
 * implementation of support animal home new page
 * @returns JSX
 */
export default function PageSupportAnimalHomeShow() {


    // globals
    const usersRef = useRef([]);
    const user = useUserState();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const navigate = useNavigate();
    const { animalhomeId } = useParams();
    const [animalHome, setAnimalHome] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const routeForNewUser = animalhomeId ? 
                                `${ROUTE_SUPPORT_ANIMALHOME_USERS_NEW}/${animalhomeId}` :
                                ROUTE_ANIMALHOME_USERS_NEW
    const routeForAnimalHomeEdit = animalhomeId ?
                                    `${ROUTE_SUPPORT_ANIMALHOME_EDIT}/${animalhomeId}` :
                                    `${ROUTE_ANIMALHOME_EDIT}/${user.animalHomeId}`;
    const filterPredicates = [
        { key: "owner", value: animalhomeId, type: PREDICATE_EQUALS },
    ]

    const sortFieldInputData = [
        { value: 'createdAt', label: 'Erstellzeit' },
        { value: 'updatedAt', label: 'Aktualisierungszeit' },
    ]

    const animalHomeFields = fieldsAnimalHome(AnimalHome);

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        setLoading(LOADING_SHOW_AND_HIDE_CHILDREN);

        try {

            // get animal home data
            const animalHome = await DataStore.query(AnimalHome, animalhomeId ? animalhomeId : user.animalHomeId);
            if (!animalHome) {
                throw new Error("no animal home with id found");
            }

            // set fetched data
            setAnimalHome(animalHome);

            let animalHomeUsers;
            if (animalhomeId) {
                animalHomeUsers = await executeApiCall(API_ADMIN_NAME, API_GET, ADMIN_API_SUPPORT_LIST_ANIMALHOME_USERS, { animalhomeId });
            } else {
                animalHomeUsers = await executeApiCall(API_ADMIN_NAME, API_GET, ADMIN_API_ANIMALHOME_LIST_USERS);
            }

            usersRef.current = animalHomeUsers;
            filterUsers();
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * submit callback for user filter form
     * @param {object} values form values
     */
    const filterUserSubmitCallback = async () => {
        try {
            setLoading(LOADING_SHOW);
            filterUsers();

        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * filters users depending on set filter in filter form
     */
    const filterUsers = () => {
        // start with all users
        var filteredUsers = [...usersRef.current];
        const values = form.values;

        // filter email
        if (values.email) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.email.toLowerCase().includes(values.email.toLowerCase());
            });
        }

        // filter enabled state
        if (values.enabled === OPTION_YES) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === true;
            });
        }
        else if (values.enabled === OPTION_NO) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === false;
            });
        }

        // filter group
        if (values.group !== USERGROUPS_ALL) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.groups.includes(values.group);
            });
        }

        setFilteredUsers(filteredUsers);
    }

    /**
     * reset callback for user filter
     */
    const resetUserFilterCallback = () => {
        setFilteredUsers(usersRef.current);
    }

    /**
     * changes the users enabled status
     * @param {string}  username    username to update
     * @param {boolean} enabled     enabled status
     */
    const changeUserEnabled = async (username, enabled) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // update user
            await executeApiCall(
                API_ADMIN_NAME,
                API_POST,
                enabled ? ADMIN_API_ADMIN_ENABLEUSER : ADMIN_API_ADMIN_DISABLEUSER,
                {
                    "username": username,
                }
            )
            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                newUsers[index].enabled = enabled;
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            showNotification({ message: enabled ? "User aktiviert." : "User deaktiviert", color: 'green', icon: <Check /> });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * changes the user group of a user
     * @param {string}  username  username to update
     * @param {boolean} groupname  groupname to update
     */
    const changeAdminPermission = async (username, groupname) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            const isUserAnimalHomeAdmin = groupname === USERGROUP_ANIMALHOME_ADMIN;
            // change user group permissions
            await executeApiCall(
                API_ADMIN_NAME,
                API_POST,
                ADMIN_API_ADMIN_ADDUSERTOGROUP,
                {
                    "username": username,
                    "groupname": groupname
                }
            )
            await executeApiCall(
                API_ADMIN_NAME,
                API_POST,
                ADMIN_API_ADMIN_REMOVEUSERFROMGROUP,
                {
                    "username": username,
                    "groupname": isUserAnimalHomeAdmin 
                                    ? USERGROUP_ANIMALHOME_USER
                                    : USERGROUP_ANIMALHOME_ADMIN
                }
            )

            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                newUsers[index].groups = [groupname];
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            if (groupname === USERGROUP_ANIMALHOME_ADMIN) {
                showNotification({ message: "Benutzergruppe geändert zu Adminstrator", color: 'green', icon: <Check /> });
            } else {
                showNotification({ message: "Benutzergruppe geändert zu Benutzer", color: 'green', icon: <Check /> });
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook for user filter
    const form = useForm({
        validationSchema: validationSchemaUserFilter,
        initialValues: initialValuesUserFilter,
        submitCallback: filterUserSubmitCallback,
        resetCallback: resetUserFilterCallback,
    });

    /**
     * wrapper to map users in table
     */
    const rows = filteredUsers.map((element) => {
        return (
            <tr key={element.username}>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {element.name}
                    </div>
                </td>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {element.email}
                    </div>
                </td>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {element.enabled ? "Ja" : "Nein"}
                    </div>
                </td>
                <td>
                    <div style={{ minWidth: '100px' }}>                    
                        {element.groups.map(group => t(group, "groups")).join(", ")}
                    </div>
                </td>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {element.status}
                    </div>
                </td>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {<BadgeTimestamp time={element.createdAt} />}
                    </div>
                </td>
                <td>
                    <div style={{ minWidth: '100px' }}>
                        {<BadgeTimestamp time={element.updatedAt} />}
                    </div>       
                </td>
                <td>
                    <Menu position="bottom-end" width={220} withinPortal>
                        <Menu.Target>
                            <ActionIcon><Settings size={18} /></ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {element.enabled === true ?
                                <Menu.Item
                                    icon={<UserOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, false)}
                                >
                                    User deaktivieren
                                </Menu.Item>
                                :
                                <Menu.Item
                                    icon={<UserCheck size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, true)}
                                >
                                    User aktivieren
                                </Menu.Item>
                            }

                            {element.groups.includes(USERGROUP_ANIMALHOME_ADMIN) ?
                                <Menu.Item
                                    icon={<User size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, USERGROUP_ANIMALHOME_USER)}
                                >
                                    Berechtigung zu Benutzer ändern
                                </Menu.Item>
                                :
                                <Menu.Item
                                    icon={<Star size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, USERGROUP_ANIMALHOME_ADMIN)}
                                >
                                    Berechtigung zu Administator ändern
                                </Menu.Item>
                            }
                        </Menu.Dropdown>
                    </Menu>
                </td>
            </tr>
        )
    });

  return (
    <Stack>
        <Group position="apart">
            <Title>{animalHome?.name} Tierheim</Title>
            <Group position="right">
                <Button
                    color="green"
                    leftIcon={<Plus />}
                    onClick={() => navigate(routeForNewUser)}
                >
                    Neuen User einladen
                </Button>
                {animalhomeId &&
                    <>
                        <Button color="green" leftIcon={<Plus />} onClick={() => navigate(`${ROUTE_SUPPORT_ENTITY_NEW}/${animalhomeId}`)}>
                            Neue Entität {CREATE}
                        </Button>
                        <Button color="green" leftIcon={<Plus />} onClick={() => navigate(`${ROUTE_SUPPORT_ANIMALS_CREATE_ANIMAL}/${animalhomeId}`)}>
                            Neues {ANIMAL} {CREATE}
                        </Button>
                    </>
                }
            </Group>
        </Group>
        <Divider />

        <Accordion multiple variant="separated"  defaultValue={["details", "users"]}>

            <Accordion.Item value="details">
                <Accordion.Control icon={<ListIcon />}>Details</Accordion.Control>
                <Accordion.Panel>
                    <ActionIcon
                            color="blue"
                            onClick={() => navigate(routeForAnimalHomeEdit)}
                        >
                        <Edit />
                    </ActionIcon>
                    <Grid mt={5} grow>
                        {animalHomeFields.map(field => (
                             <React.Fragment key={field}>
                                <Grid.Col span={12} sm={6}>
                                    <Text fz="md" fw={700}>{t(field, "animalHome")}</Text>
                                </Grid.Col>
                                <Grid.Col span={12} sm={6}>
                                    <Text>{animalHome[field]}</Text>
                                </Grid.Col>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="users">
                <Accordion.Control icon={<Users />}>Benutzer</Accordion.Control>
                <Accordion.Panel>

                    <Stack>
                        <Accordion variant="separated">
                            <Accordion.Item value="filter">
                                <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                                <Accordion.Panel>
                                    <form
                                        onSubmit={form.onSubmit()}
                                        onReset={form.onReset}
                                    >
                                        <Stack>
                                            <TextInput
                                                label="E-Mail-Adresse"
                                                placeholder="E-Mail-Adresse"
                                                {...form.getInputProps('email')}
                                            />

                                            <Select
                                                label="Aktiv"
                                                placeholder="..."
                                                data={OPTIONS_YESNO_WITHALL}
                                                {...form.getInputProps('enabled')}
                                            />

                                            <Select
                                                label="Berechtigungsgruppe"
                                                placeholder="Berechtigungsgruppe wählen..."
                                                data={[
                                                    { value: USERGROUPS_ALL, label: 'Alle' },
                                                    { value: USERGROUP_ANIMALHOME_USER, label: 'Benutzer' },
                                                    { value: USERGROUP_ANIMALHOME_ADMIN, label: 'Administrator' },
                                                ]}
                                                {...form.getInputProps('group')}
                                            />

                                            <Group mt="sm" position="apart">
                                                <Button variant="outline" color="yellow" leftIcon={<Reload />} type="reset">Zurücksetzen</Button>
                                                <Button variant="outline" color="green" leftIcon={<Filter />} type="submit">Filtern</Button>
                                            </Group>
                                        </Stack>
                                    </form>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                        <div style={{ overflowX: 'auto' }}>                           
                            <Table striped highlightOnHover withBorder>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: '100px' }}>Name</th>
                                        <th style={{ minWidth: '100px' }}>E-Mail</th>
                                        <th style={{ minWidth: '100px' }}>Aktiv</th>
                                        <th style={{ minWidth: '100px' }}>Berechtigungsgruppen</th>
                                        <th style={{ minWidth: '100px' }}>Status</th>
                                        <th style={{ minWidth: '100px' }}>Erstellt am</th>
                                        <th style={{ minWidth: '100px' }}>Zuletzt geändert am</th>
                                        <th ></th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        </div>
                    </Stack>

                </Accordion.Panel>
            </Accordion.Item>

            {animalhomeId && (
                <>
                    <Accordion.Item value="single">
                        <Accordion.Control icon={<User />}>Einzelpersonen</Accordion.Control>
                        <Accordion.Panel>
                            <ListingTemplate
                                animalhomeId={animalhomeId}
                                dataStructure={singleEntityDataStructure}
                                editRoute={ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_EDIT}
                                filterPredicates={filterPredicates}
                                listTypeModel={SinglePersonEntity}
                                showRoute={ROUTE_SUPPORT_SINGLE_PERSON_ENTITY_VIEW}
                                sortDirection={SortDirection.DESCENDING}
                                sortField="createdAt"
                                sortFieldInputData={sortFieldInputData}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="group">
                        <Accordion.Control icon={<Users />}>Personengruppen</Accordion.Control>
                        <Accordion.Panel>
                            <ListingTemplate
                                animalhomeId={animalhomeId}
                                dataStructure={groupEntityDataStructure}
                                editRoute={ROUTE_SUPPORT_PERSON_GROUP_ENTITY_EDIT}
                                filterPredicates={filterPredicates}
                                listTypeModel={PersonGroupEntity}
                                showRoute={ROUTE_SUPPORT_PERSON_GROUP_ENTITY_VIEW}
                                sortDirection={SortDirection.DESCENDING}
                                sortField="createdAt"
                                sortFieldInputData={sortFieldInputData}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="animals">
                        <Accordion.Control icon={<DogBowl />}>Tiere</Accordion.Control>
                        <Accordion.Panel>
                        <ListingTemplate
                            animalhomeId={animalhomeId}
                            dataStructure={animalsDataStructure}
                            editRoute={ROUTE_SUPPORT_ANIMALS_EDIT_ANIMAL}
                            filterPredicates={filterPredicates}
                            listTypeModel={Animals}
                            showRoute={ROUTE_SUPPORT_ANIMALS_VIEW_ANIMAL}
                            sortDirection={SortDirection.DESCENDING}
                            sortField="createdAt"
                            sortFieldInputData={sortFieldInputData}
                        />
                        </Accordion.Panel>
                    </Accordion.Item>
                </>
            )}

        </Accordion>

    </Stack>
  )
}
