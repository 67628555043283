import { DataStore } from 'aws-amplify';
import React, { useEffect, useState } from 'react'
import { Actions } from '../models';
import { Button, Text, Timeline } from '@mantine/core';
import { CalendarEvent } from 'tabler-icons-react';
import t from '../helpers/Translations';

export default function AnimalTimeline({ animalId }) {

    const [events, setEvents] = useState([]);
    const [showAll, setShowAll] = useState(false);

    const MAX_VISIBLE_EVENTS = 10;

    useEffect(() => {

        let subscription;

        const fetchEvents = async () => {
            try {
                subscription = DataStore.observeQuery(Actions, (c) => c.animalId.eq(animalId)).subscribe(({ items, isSynced }) => {
                    // sort by 'createdAt' from oldest to newest
                    const sortedActions = items.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                    setEvents(sortedActions);
                    console.log(`Data is ${isSynced ? 'synced' : 'syncing'} with the cloud.`);
                });
            } catch (error) {
                console.error('Error fetching actions:', error);
            }
        };

        fetchEvents();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };

    }, [animalId]);

    const displayedEvents = showAll ? events : events.slice(-MAX_VISIBLE_EVENTS);

    return (

        <div>

            {events.length > MAX_VISIBLE_EVENTS && (
                <Button onClick={() => setShowAll(!showAll)} mb={15}>
                    {showAll ? "Verbergen" : "Alle anzeigen"}
                </Button>
            )}

            <Timeline active={displayedEvents.length} lineWidth={2}>
                {displayedEvents.map((event, index) => {
                    const isLastEvent = index === displayedEvents.length - 1;
                    const bulletSize = isLastEvent ? 30 : 24;
                    const calendarEventSize = isLastEvent ? 18 : 12;

                    return (
                        <Timeline.Item 
                            key={index} 
                            title={t(event.actionType, "actionType")}
                            bullet={<CalendarEvent size={calendarEventSize} />}
                            bulletSize={bulletSize}
                        >
                            <Text size="xs" mt={4}>{new Date(event.createdAt).toLocaleString()}</Text>
                        </Timeline.Item>
                    );
                })}
            </Timeline>

        </div>
    )
}