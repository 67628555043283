import { useParams } from "react-router-dom";
import { ROUTE_SUPPORT_FORMULARS_EDIT_FOLLOWUP_CHECK } from "../../helpers/Routes";
import { FormCheckAnimal } from "../../models";
import FormViewTemplate from "../../components/FormViewTemplate";
import { FORM_CHECK_ANIMAL } from "../../helpers/Constants";
import { fieldsForm } from "../../helpers/FormFieldsProcessor";

/**
 * Page used to View the details of an existing Follow up Check form
 * @returns JSX
 */
export default function PageSupportFormsViewFollowupCheck() {

  const { id } = useParams();

  const formCheckAnimalFields = fieldsForm(FormCheckAnimal);

  return (
    <FormViewTemplate
      id={id}
      formModel={FormCheckAnimal}
      formName={FORM_CHECK_ANIMAL}
      formFields={formCheckAnimalFields}
      editFormRoute={ROUTE_SUPPORT_FORMULARS_EDIT_FOLLOWUP_CHECK}
    />
  );
}
