import { Accordion, Button, Group, Modal, Stack, TextInput } from '@mantine/core'
import React, { useState } from 'react'
import { AnimalRaces } from '../../models'
import { AnimalRacesDatastructure } from '../../components/AdminDatastructure';
import { ClipboardList, Filter, Plus, Reload } from 'tabler-icons-react';
import { useForm } from '../../components/Form';
import { DataStore, SortDirection } from 'aws-amplify';
import SortFieldInput from '../../components/SortFieldInput';
import SortDirectionInput from '../../components/SortDirectionInput';
import List from '../../components/List';
import { getFilterPredicates } from '../../helpers/Predicates';
import { PREDICATE_CONTAINS } from '../../helpers/Constants';
import { ROUTE_EDIT_RACE } from '../../helpers/Routes';

export default function PageAdminRaceAndBreedsCrud() {

    const [isCreateRaceModalOpen, setCreateRaceModalOpen] = useState(false);

    const handleCreate = async (values) => {
        try {
            await DataStore.save(new AnimalRaces({ ...values }));
        } catch (error) {
            console.error('Error saving new race:', error);
        }

        handleCloseModal()
    }

    // form hook
    const form = useForm({
        initialValues: {
            id: "",
            label: "",
            labelEn: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    const createForm = useForm({
        initialValues: {
            label: "",
            labelEn: ""
        },
        submitCallback: handleCreate,
    });

    const getFilter = () => {
        return getFilterPredicates([
            { key: "id", value: form.values.id, type: PREDICATE_CONTAINS },
            { key: "label", value: form.values.label, type: PREDICATE_CONTAINS },
            { key: "labelEn", value: form.values.labelEn, type: PREDICATE_CONTAINS },
        ]);
    }

    const handleCloseModal = () => {
        createForm.reset();
        setCreateRaceModalOpen(false);
    }

    return (
        <>
            <Accordion variant="separated" defaultValue="forms">
                <Accordion.Item value="forms">
                    <Accordion.Control icon={<ClipboardList />}>Races (Tierarten)</Accordion.Control>
                    <Accordion.Panel>
                        <Group mb={20}>
                            <Button color="green" leftIcon={<Plus />} onClick={() => setCreateRaceModalOpen(true)}>Add new race</Button>
                        </Group>

                        <Stack>
                            <Accordion variant="separated">
                                <Accordion.Item value="filter">
                                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                                    <Accordion.Panel>
                                        <form
                                            onReset={form.onReset}
                                        >
                                            <Stack>
                                                <TextInput
                                                    label="ID"
                                                    placeholder="ID"
                                                    {...form.getInputProps('id')}
                                                />

                                                <TextInput
                                                    label="Label"
                                                    placeholder="Label"
                                                    {...form.getInputProps('label')}
                                                />

                                                <TextInput
                                                    label="Label (english)"
                                                    placeholder="Label (english)"
                                                    {...form.getInputProps('labelEn')}
                                                />

                                                <SortFieldInput
                                                    data={[
                                                        { value: 'id', label: 'ID' },
                                                        { value: 'label', label: 'Label' },
                                                        { value: 'labelEn', label: 'Label (english)' },
                                                        { value: 'createdAt', label: 'Erstellzeit' },
                                                        { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                                    ]}
                                                    {...form.getInputProps('sortField')}
                                                />

                                                <SortDirectionInput
                                                    {...form.getInputProps('sortDirection')}
                                                />

                                                <Group position='apart'>
                                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                                </Group>
                                            </Stack>
                                        </form>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                            <List
                                type={AnimalRaces}
                                id="id"
                                dataStructure={AnimalRacesDatastructure}
                                filter={getFilter()}
                                sort={s => s[form.values.sortField](form.values.sortDirection)}
                                editRoute={ROUTE_EDIT_RACE}
                                withDelete
                            />
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Modal opened={isCreateRaceModalOpen} onClose={handleCloseModal} title="Add New Race">
                <form onSubmit={createForm.onSubmit()}>
                    <TextInput
                        label="Label"
                        placeholder="Label"
                        {...createForm.getInputProps('label')}
                    />

                    <TextInput
                        label="Label (english)"
                        placeholder="Label (english)"
                        {...createForm.getInputProps('labelEn')}
                    />

                    <Group position="right" mt="md">
                        <Button type="submit">Create</Button>
                    </Group>
                </form>
            </Modal>
        </>
    )
}