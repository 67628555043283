import { useParams } from "react-router-dom";
import { Animals, FormCheckAnimal as FormCheckAnimalModel, PersonGroupIndividual } from "../../models";
import { FORM_CHECK_ANIMAL, PDF_CHECK_ANIMAL } from "../../helpers/Constants";
import FormCreatePageTemplate from "../../components/FormCreatePageTemplate";
import FormCheckAnimal from "../../formulars/FormCheckAnimal";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import { ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import { validationForFolowUpCheckEntity } from "../../helpers/FormValidations";

/**
 * Page used to create a new Follow up Check form for a Person Group Entity
 * @returns JSX
 */
export default function PageAnimalHomeFormsCreateFollowupCheckForPersonGroupIndividual() {
    const { id } = useParams();
    const user = useUserState();

    return (
        <FormCreatePageTemplate
            FormComponent={FormCheckAnimal}
            FormName={FormCheckAnimalModel.name}
            animalHomeId={user.animalHomeId}
            entityId={id}
            entityTypeModel={PersonGroupIndividual}
            animalsTypeModel={Animals}
            formTitle={FORM_CHECK_ANIMAL}
            initialValues={initialValuesForm(FormCheckAnimalModel)}
            pdfName={PDF_CHECK_ANIMAL}
            viewRoute={ROUTE_ANIMALHOME_FORMULARS_VIEW_FOLLOWUP_CHECK}
            validationSchema={validationForFolowUpCheckEntity}
        />
    );
}
