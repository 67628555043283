import React from 'react';
import { useParams } from 'react-router-dom';
import { fieldsModel } from '../../helpers/FormFieldsProcessor';
import { Donations } from '../../models';
import FormViewTemplate from '../../components/FormViewTemplate';
import { PAYMENT } from '../../helpers/Constants';
import { ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT } from '../../helpers/Routes';

export default function PageAnimalHomePaymentsViewPayment() {
    const { id } = useParams();

    const formPayment = fieldsModel(Donations);
  
    return (
      <FormViewTemplate
        id={id}
        formModel={Donations}
        formName={PAYMENT}
        formFields={formPayment}
        editFormRoute={ROUTE_ANIMALHOME_PAYMENTS_EDIT_PAYMENT}
        onlyEditable={true}
      />
    );
}
