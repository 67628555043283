import { useNavigate } from "react-router-dom";
import { useUserState } from "../helpers/GlobalUserState";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { API_FILE_NAME, API_PDF_NAME, API_POST, API_PUT, FILE_API_DELETE, FILE_PDF_ROUTE, PRIVATE_PROTECTION_LEVEL, executeApiCall } from "../helpers/APIHelper";
import { useForm } from "../components/Form";

/**
 * Form Edit Template component to edit an animal home form
 * @returns JSX
 */
export default function FormEditTemplate({ animalHomeId, animals, pdfName, FormName, viewRoute, initialValues, defaultAnimalSelectValue, defaultEntitySelectValue, FormComponent, entities, validationSchema }) {

  // globals
  const navigate = useNavigate();
  const user = useUserState();
  const setError = useErrorDispatch();
  const setLoading = useLoadingDispatch();

  const submitCallback = async (values) => {

    try {
      setLoading(LOADING_SHOW);
      const route = FILE_PDF_ROUTE;
      const body = { 
        formData: values, 
        userData: user,
        pdfName: pdfName,
        table: FormName
      };

      const response = await executeApiCall(
        API_PDF_NAME,
        API_PUT,
        route,
        body,
      );

      if(response.url) {
        setLoading(LOADING_SHOW);

        await executeApiCall(API_FILE_NAME, API_POST, FILE_API_DELETE, {
            protectionLevel: PRIVATE_PROTECTION_LEVEL,
            identityId: values.owner,
            key: "pdfs/"+values.fileName,
        })

        // redirect
        navigate(`${viewRoute}/${response.pdf.id}`, { replace: true });
      }

    } catch (error) {
      console.error("Error:", error);
      setError({ action: ERROR_SHOW, error, callback: () => window.location.reload(false) });
    }
    finally {
      setLoading(LOADING_RESET);
    }
  };

  // creating form object
  const form = useForm({
    validationSchema: validationSchema,
    initialValues: initialValues,
    submitCallback: submitCallback
  });
  
  return (
    <FormComponent
      form={form}
      animalhomeId={animalHomeId}
      entities={entities}
      animals={animals}
      defaultAnimalSelectValue={defaultAnimalSelectValue}
      defaultEntitySelectValue={defaultEntitySelectValue}
    />
  );
}