import { Button, Group, Modal, Text } from '@mantine/core'
import React from 'react'
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState';
import { SendMailToEntity } from '../helpers/SendMailToEntity';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';

export default function ConfirmEmailSendModal({item, entityEmail, emailName, onClose, open}) {

    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();

    const closeModal = () => {
        onClose();
    };

    const handleSendMail = async (item, entityEmail, emailName) => {    
        try {
            setLoading(LOADING_SHOW);
            await SendMailToEntity(item, entityEmail, emailName);
        } catch (error) {
            setError({ action: ERROR_SHOW, error });
        } finally {
            closeModal();
            setLoading(LOADING_RESET);
        }
    };

    return (
        <Modal opened={open} onClose={closeModal} title="E-Mail senden">
            <Text>Möchten Sie das E-Mail an <b>{entityEmail}</b> wirklich senden?</Text>
            <Group position="right" mt="md">
                <Button onClick={() => closeModal()} color="gray">Abbrechen</Button>
                <Button onClick={() => handleSendMail(item, entityEmail, emailName)}>Senden</Button>
            </Group>
        </Modal>
    )
}
