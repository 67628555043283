import { Button, Divider, Group, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "./Form";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_ADDRESS, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_NAME, VALIDATION_SCHEMA_PHONE } from "../helpers/Validation";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { Check, File, Reload } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../helpers/GlobalUserState";
import { DataStore } from "aws-amplify";
import { SinglePersonEntity } from "../models";
import DatePickerInput from "./DatePickerInput";


// validation schema with yup
const validationSchema = Yup.object().shape({
    salutation: VALIDATION_SCHEMA_NAME,
    title: VALIDATION_SCHEMA_NAME,
    firstName: VALIDATION_SCHEMA_NAME,
    lastName: VALIDATION_SCHEMA_NAME,
    street: VALIDATION_SCHEMA_ADDRESS,
    zip: VALIDATION_SCHEMA_ADDRESS,
    city: VALIDATION_SCHEMA_ADDRESS,
    country: VALIDATION_SCHEMA_ADDRESS,
    birthdate: VALIDATION_SCHEMA_PHONE,
    phone: VALIDATION_SCHEMA_PHONE,
    email: VALIDATION_SCHEMA_EMAIL,
    idNumber: VALIDATION_SCHEMA_PHONE,
    idReleasedOn: VALIDATION_SCHEMA_PHONE,
    idReleasedBy: VALIDATION_SCHEMA_NAME,
});

/**
 * Single Person Entity New componenet used to create a new single person entity
 * @returns JSX
 */
export default function SinglePersonEntityNew({ animalhomeId }) {

    // globals
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();
    const user = useUserState();

    // submit callback
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);

            // create new person group entity
            await DataStore.save(new SinglePersonEntity({
                salutation: values.salutation,
                title: values.title,
                firstName: values.firstName,
                lastName: values.lastName,
                additionalName: values.additionalName,
                street: values.street,
                zip: values.zip,
                city: values.city,
                country: values.country,
                birthdate: values.birthdate,
                phone: values.phone,
                email: values.email,
                idNumber: values.idNumber,
                idReleasedOn: values.idReleasedOn,
                idReleasedBy: values.idReleasedBy,
                owner: user.animalHomeId ? user.animalHomeId : animalhomeId,
                status: "ACTIVE"
            }));

            // show message
            showNotification({ message: "Einzelperson erfolgreich hinzufügt.", color: 'green', icon: <Check /> });

            // redirect
            navigate(-1);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            salutation: "",
            title: "",
            firstName: "",
            lastName: "",
            additionalName: "",
            street: "",
            zip: "",
            city: "",
            country: "",
            birthdate: "",
            phone: "",
            email: "",
            idNumber: "",
            idReleasedOn: "",
            idReleasedBy: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    {/* salutation */}
                    <Select
                        withAsterisk
                        label="Anrede"
                        placeholder="Anrede"
                        data={[
                            { value: 'm', label: 'Herr' },
                            { value: 'f', label: 'Frau' },
                            { value: 'o', label: 'Divers' }
                        ]}
                        {...form.getInputProps('salutation')}
                    />
                    <TextInput
                        withAsterisk
                        label="Titel"
                        placeholder="Titel"
                        {...form.getInputProps('title')}
                    />
                    <TextInput
                        withAsterisk
                        label="Vorname"
                        placeholder="Vorname"
                        {...form.getInputProps('firstName')}
                    />
                    <TextInput
                        withAsterisk
                        label="Nachname"
                        placeholder="Nachname"
                        {...form.getInputProps('lastName')}
                    />
                    <TextInput
                        label="Namenszusatz"
                        placeholder="Namenszusatz"
                        {...form.getInputProps('additionalName')}
                    />
                    <TextInput
                        withAsterisk
                        label="Adresse"
                        placeholder="Adresse"
                        {...form.getInputProps('street')}
                    />
                    <TextInput
                        withAsterisk
                        label="PLZ"
                        placeholder="PLZ"
                        {...form.getInputProps('zip')}
                    />
                    <TextInput
                        withAsterisk
                        label="Ort"
                        placeholder="Ort"
                        {...form.getInputProps('city')}
                    />
                    <TextInput
                        withAsterisk
                        label="Land"
                        placeholder="Land"
                        {...form.getInputProps('country')}
                    />
                    <DatePickerInput
                        withAsterisk
                        label="Geburtsdatum" 
                        placeholder="Geburtsdatum" 
                        form={form} 
                        inputKey="birthdate" 
                        pt="xs"
                    />
                    <TextInput
                        withAsterisk
                        label="Telefonnummer"
                        placeholder="Telefonnummer"
                        {...form.getInputProps('phone')}
                    />
                    <TextInput
                        withAsterisk
                        label="E-Mail-Adresse"
                        placeholder="E-Mail-Adresse"
                        {...form.getInputProps('email')}
                    />
                    <TextInput 
                        withAsterisk
                        label="Personalausweis-/Reisepassnummer" 
                        placeholder="Personalausweis-/Reisepassnummer" 
                        {...form.getInputProps('idNumber')} 
                    />
                    <DatePickerInput
                        withAsterisk
                        label="Personalausweis/Reisepass ausgestellt am" 
                        placeholder="Personalausweis/Reisepass ausgestellt am" 
                        form={form} 
                        inputKey="idReleasedOn"
                    />
                    <TextInput 
                        withAsterisk
                        label="Personalausweis/Reisepass ausgestellt durch" 
                        placeholder="Personalausweis/Reisepass ausgestellt durch" 
                        {...form.getInputProps('idReleasedBy')} 
                    />
                    <Divider />

                    <Group position='apart'>
                        <Button leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                        <Button leftIcon={<File size={14} />} type="submit" color="green">Speichern</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}