import {
  Button,
  Divider,
  Group,
  SegmentedControl,
  Stack,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import PersonGroupEntityNew from "../../components/PersonGroupEntityNew";
import SinglePersonEntityNew from "../../components/SinglePersonEntityNew";
import { ArrowLeft } from "tabler-icons-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CREATE } from "../../helpers/Constants";

/**
 * implementation of animal home user home page
 * @returns JSX
 */
export default function PageAnimalHomeEntitiesNew() {
  // globals
  const navigate = useNavigate();
  const { animalhomeId } = useParams();
  const [value, setValue] = useState("single");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    if (type) {
      setValue(type);
    }
  }, [location.search]);

  return (
    <Stack>
      <Group position="apart">
        <Title>Neue Entität {CREATE}</Title>
        <Group>
          <Button
            leftIcon={<ArrowLeft size={14} />}
            onClick={() => navigate(-1, { replace: true })}
            color="yellow"
          >
            Zurück
          </Button>
        </Group>
      </Group>
      <Divider />

      <SegmentedControl
        fullWidth
        value={value}
        onChange={setValue}
        data={[
          {
            label: "Einzelperson",
            value: "single",
          },
          { label: "Personengruppe", value: "group" },
        ]}
      />

      {value === "group" ? (
        <PersonGroupEntityNew animalhomeId={animalhomeId}/>
      ) : (
        <SinglePersonEntityNew animalhomeId={animalhomeId}/>
      )}
    </Stack>
  );
}
