import React, { useState } from 'react';
import { Modal, Button, Group, Select, Textarea } from '@mantine/core';
import { DataStore } from 'aws-amplify';
import { AnimalsDocuments } from '../models';
import { useForm } from './Form';
import FileInput from './FileInput';
import { FILE_TYPE_PDF } from '../helpers/Constants';
import { PRIVATE_PROTECTION_LEVEL } from '../helpers/APIHelper';

export default function EditDocumentModal({ onDocumentUpdate }) {
    const [open, setOpen] = useState(false);

    const form = useForm({
        initialValues: {
            id: null,
            documentDescription: '',
            documentType: '',
            owner: '',
            fileKey: '',
            originalName: ''
        },
        submitCallback: async (values) => {
            try {
                const documentToUpdate = await DataStore.query(AnimalsDocuments, values.id);
                if (documentToUpdate) {
                    if (values.fileKey){
                        // if there is a file key that means we have the same doc or a new one
                        await DataStore.save(
                            AnimalsDocuments.copyOf(documentToUpdate, updated => {
                                updated.documentType = values.documentType;
                                updated.documentDescription = values.documentDescription;
                                updated.fileKey = values.fileKey;
                                updated.originalName = values.originalName;
                            })
                        );    
                    } else{
                        // erase the document from database
                        await DataStore.delete(documentToUpdate);
                    }
                }

                closeModal();
                if (onDocumentUpdate) {
                    onDocumentUpdate();
                }
            } catch (error) {
                console.error('Error while updating the document:', error);
            }
        }
    });

    EditDocumentModal.open = (id, documentDescription, documentType, owner, fileKey, originalName) => {
        form.setValues({ id, documentDescription, documentType, owner, fileKey, originalName });
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };
    
    return (
        <Modal opened={open} onClose={closeModal} title={form.values.originalName} size={'xl'}>
            <form onSubmit={form.onSubmit()}>
                <FileInput
                    initFiles={form.values.fileKey || []}
                    multiple={false}
                    label="Dokument"
                    description="Wählen Sie das Dokument aus, das Sie hochladen möchten."
                    path="fileKey"
                    form={form}
                    withAsterisk={false}
                    fileType={FILE_TYPE_PDF}
                    storagePrefix="pdfs"
                    fileProtectionLevel={PRIVATE_PROTECTION_LEVEL}
                    identityId={form.values.owner}
                    provideFilename={true}
                    pt="xs"
                />
                <Select
                    label="Art des Dokuments"
                    placeholder="Art des Dokuments"
                    {...form.getInputProps("documentType")}
                    data={[
                        { value: "birthCertificate", label: "Geburtsurkunde" },
                        { value: "vaccination", label: "Impfung" },
                        { value: "castration", label: "Kastration" },
                        { value: "vetControll", label: "Kontrolle beim Tierarzt" },
                        { value: "passport", label: "Reisepass" },
                        { value: "animalBook", label: "Tierbuch" },
                        { value: "followUpCheck", label: "Nachkontrolle" },
                        { value: "otherDocs", label: "Sonstige Unterlagen" },
                    ]}
                />
                <Textarea
                    placeholder="Beschreibung"
                    label="Beschreibung"
                    {...form.getInputProps("documentDescription")}
                    minRows={10}
                />
                <Group position="right" mt="md">
                    <Button type="submit">Aktualisieren</Button>
                </Group>
            </form>
        </Modal>
    );
}