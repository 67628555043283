import { DatePickerInput as DatePickerInputMantine } from "@mantine/dates";
import { Calendar } from "tabler-icons-react";
import moment from "../helpers/Moment";


/**
 * wrapper for date input in forms
 * @param {object} props component props
 * @returns JSX
 */
export default function DatePickerInput(props) {

    /**
     * handler for date changes, automatically formats date
     * @param {date} e 
     */
    const onChange = (e) => {
        var values = {};

        if (!e) {
            // if no date was selected return empty string
            values[props.inputKey] = "";
        }
        else {
            // if a date was selected, format it
            values[props.inputKey] = moment(e).local().format("YYYY-MM-DD");
        }

        props.form.setValues({ ...values });
    }

    /**
     * maps the value from the field to a date for the picker
     * @returns Date or empty string
     */
    const getValueAsDate = () => {
        const value = props.form.values[props.inputKey];

        // check if not set
        if (!value) {
            return null;
        }

        // otherwise format date and reutn
        return moment(value).toDate();
    }

    return (
        <DatePickerInputMantine
            withAsterisk={props.withAsterisk}
            label={props.label ? props.label : "Datum"}
            placeholder={props.placeholder ? props.placeholder : "Datum wählen..."}
            locale="de"
            valueFormat="D. MMMM YYYY"
            icon={<Calendar size={16} />}
            {...props.form.getInputProps(props.inputKey)}
            onChange={(e) => onChange(e)}
            value={props.disabled ? null : getValueAsDate()}
            defaultLevel="year"
            disabled={props.disabled}
            clearable
        />
    )
}