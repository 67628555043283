import { Card, Container, Divider, Group, Image, Loader, SimpleGrid, Text, Title } from "@mantine/core";
import { DataStore } from "aws-amplify";
import { Animals } from "../models";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSingleFileUrl } from '../helpers/Files';

export default function RecentlyUpdatedAnimals({ animalHomeId }) {

    const [animals, setAnimals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const NUMBER_OF_ANIMALS = 5;

    const getAnimalImages = async (updatedAnimals) => {
        return Promise.all(updatedAnimals.map(async animal => {
            const imageUrl = animal.imageMain ? await getSingleFileUrl(animal.imageMain, animalHomeId) : '/media/images/noMainImageThumbnail.png';
            return { ...animal, imageUrl };
        }));
    };

    const preparePage = async () => {
        setIsLoading(true);

        let allAnimals = await DataStore.query(Animals, (c) => c.owner.eq(animalHomeId));
        let sortedAnimals = allAnimals.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        const updatedAnimals = sortedAnimals.slice(0, NUMBER_OF_ANIMALS);

        if(updatedAnimals){
            const animalsWithImages = await getAnimalImages(updatedAnimals);

            setAnimals(animalsWithImages);
        }

        setIsLoading(false);
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const cardStyle = {
        cursor: 'pointer',
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    };

    return (
        animals.length > 0 &&
        <Container sx={{ marginLeft: 0, marginRight: 0, maxWidth: "100%" }}>
            <Divider />
                            
            <Group>
                <Title order={3} mb='md'>Aktuelle Tiere</Title>
            </Group>
            <SimpleGrid
                cols={5}
                spacing="lg"
                breakpoints={[
                    { maxWidth: 'lg', cols: 3, spacing: 'md' },
                    { maxWidth: 'md', cols: 2, spacing: 'md' },
                    { maxWidth: 'sm', cols: 1, spacing: 'sm' },
                ]}
            >
                {isLoading ? (
                    new Array(NUMBER_OF_ANIMALS).fill(0).map((_, index) => (
                        <Card key={index} style={cardStyle} shadow="sm" padding="lg" radius="md" withBorder>
                            <Card.Section style={{ height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Loader />
                            </Card.Section>
                        </Card>
                    ))
                ) : (
                    animals.map(animal => (
                        <Card 
                            key={animal.id} 
                            onClick={() => navigate(`/animals/view/animal/${animal.id}`)}
                            style={cardStyle} 
                            shadow="sm" 
                            padding="lg" 
                            radius="md" 
                            withBorder
                        >
                            <Card.Section>
                                <Image 
                                    src={animal.imageUrl} 
                                    alt={animal.name} 
                                    height={160}
                                    style={{ width: '100%', objectFit: 'cover' }}
                                />
                            </Card.Section>
                            <Text weight={500} style={{ marginTop: '1.25rem', marginBottom: 'xs' }}>
                                {animal.name}
                            </Text>
                            <Text size="sm" color="dimmed" style={{ wordWrap: 'break-word' }}>
                                {formatDate(animal.updatedAt)}
                            </Text>
                        </Card>
                    ))
                )}
            </SimpleGrid>
        </Container>
    )
}
