import React, { useEffect, useState } from 'react'
import { Button, Group, Modal, Select } from '@mantine/core'
import { DogBowl } from 'tabler-icons-react'
import ListingTemplate from './ListingTemplate';
import { animalsDataStructure } from './ListingDataStructures';
import { PREDICATE_EQUALS } from '../helpers/Constants';
import { Animals } from '../models';
import { SortDirection } from 'aws-amplify';

export default function SelectAnimal({ form, animalhomeId, data, defaultValue, withAsterisk }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAnimalId, setSelectedAnimalId] = useState();

  useEffect(() => {
    if (defaultValue) {
      setSelectedAnimalId(defaultValue);
      form.setValues({
        ...form.values,
        animalId: defaultValue
      });
    }
  },
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [defaultValue]);

  const handleAnimalSelect = (animalId) => {
      setSelectedAnimalId(animalId);
      form.setValues({
        ...form.values,
        animalId: animalId
      });
    setIsModalOpen(false);
  };

  const filterPredicates = [
    { key: "owner", value: animalhomeId, type: PREDICATE_EQUALS },
  ]

  const sortFieldInputData = [
    { value: 'createdAt', label: 'Erstellzeit' },
    { value: 'updatedAt', label: 'Aktualisierungszeit' },
  ]

  return (
    <>
      <Group>
        <Select
          withAsterisk={withAsterisk}
          label="Tier auswählen"
          placeholder="Tier auswählen"
          nothingFound="Keine Ergebnisse gefunden"
          searchable
          clearable
          data={data}
          {...form.getInputProps("animalId")}
          value={selectedAnimalId}
          onChange={(e) => {handleAnimalSelect(e)}}
          icon={<DogBowl size="1rem" />}
          style={{ flex: 1 }}
          pt="xs"
        />
        <Button 
          label="Tier auswählen"
          onClick={() => setIsModalOpen(true)}
          style={{"marginTop" : "32px"}}
        >
          Erweiterte Suche
        </Button>
      </Group>

      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Tier suchen"
        size="90%"
      >
        <ListingTemplate
            animalhomeId={animalhomeId}
            dataStructure={animalsDataStructure}
            filterPredicates={filterPredicates}
            listTypeModel={Animals}
            sortDirection={SortDirection.DESCENDING}
            sortField="updatedAt"
            sortFieldInputData={sortFieldInputData}
            onRowSelect={handleAnimalSelect}
            filterFields={['name', 'raceID', 'breedID']}
        />
      </Modal>
    </>
  )
}
