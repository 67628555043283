import React, { useState } from 'react';
import { Modal, Button, Group } from '@mantine/core';
import { DataStore } from 'aws-amplify';
import { Actions } from '../models';

export default function AdminDeleteFromDatabase(props) {
    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState(null);

    AdminDeleteFromDatabase.open = (dbId) => {
        setOpen(true);
        setItemId(dbId);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        try {
            // delete from database
            const itemToDelete = await DataStore.query(props.type, itemId);
            if (itemToDelete) {
                await DataStore.delete(itemToDelete);
            }

            if(props.type.name === 'Donations'){
                const actionToDelete = await DataStore.query(Actions, (c) => c.formId.eq(itemToDelete.id));
                if(actionToDelete){
                    await DataStore.delete(actionToDelete[0]);
                }
            }

            closeModal();
        } catch (error) {
            console.error('Error while deleting from database:', error);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal opened={open} onClose={closeModal} title="Eintrag löschen">
            <div style={{ marginBottom: 15 }}>
            Möchten Sie den Eintrag wirklich löschen?
            </div>
            <Group position="right" spacing="sm">
                <Button variant="default" onClick={closeModal}>
                    Cancel
                </Button>
                <Button color="red" onClick={() => confirmDelete()}>
                    Delete
                </Button>
            </Group>
        </Modal>
    )
}