import React from "react";
import { Animals, Donations, PersonGroupIndividual, SinglePersonEntity } from "../../models";
import { PAYMENT } from "../../helpers/Constants";
import { initialValuesForm } from "../../helpers/FormFieldsProcessor";
import FormPayment from "../../formulars/FormPayment";
import { ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT } from "../../helpers/Routes";
import { useUserState } from "../../helpers/GlobalUserState";
import PaymentCreatePageTemplate from "../../components/PaymentCreatePageTemplate";
import { useParams } from "react-router-dom";
import { validationForDonationsEntity } from "../../helpers/FormValidations";

export default function PageAnimalHomePaymentsCreatePaymentForPersonGroupIndividual() {
    const user = useUserState();
    const { entityId } = useParams();

    return (
        <PaymentCreatePageTemplate
            pageTitle={`${PAYMENT}`}
            FormComponent={FormPayment}
            FormName={Donations}
            viewRoute={ROUTE_ANIMALHOME_PAYMENTS_VIEW_PAYMENT}
            animalHomeId={user.animalHomeId}
            entityId={entityId}
            entityTypeModel={PersonGroupIndividual}
            animalsTypeModel={Animals}
            singleEntityTypeModel={SinglePersonEntity}
            individualEntityTypeModel={PersonGroupIndividual}
            initialValues={initialValuesForm(Donations)}
            validationSchema={validationForDonationsEntity}
        />
    );
}