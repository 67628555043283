import { createStyles, useMantineTheme, AppShell as MantineAppShell, Navbar, ActionIcon, Text, Divider, ScrollArea, UnstyledButton, Group, ThemeIcon, Header as AppShellHeader, Burger, Container, Anchor, Image } from "@mantine/core";
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { Auth, Hub } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Check, Login, Logout, MoonStars, Sun } from "tabler-icons-react";
import { clearDataStore } from "../helpers/Datastore";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { USER_RESET, USER_SET, useUserDispatch, useUserState } from "../helpers/GlobalUserState";
import { ROUTE_SIGNIN } from "../helpers/Routes";
import Broadcaster, { BC_TYPE_USERCHANGE } from "./Broadcaster";
import LoadingOverlay from "./LoadingOverlay";

// styles for page
const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.md,

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            marginTop: theme.spacing.md,
            flexDirection: 'column',
        },
    },
}));

// footer links
const footerLinks = [
    { label: "Datenschutzerklärung", link: "https://tiever.de/datenschutzerklaerung" },
    { label: "AGB", link: "https://tiever.de/agb" },
    { label: "Impressum", link: "https://tiever.de/impressum" },
    { label: "Support", link: "https://genius-tech.atlassian.net/servicedesk/customer/portal/15" },
];

/**
 * implementation of app shell wrapper
 * @param {object} props component props
 * @returns JSX
 */
export default function AppShell(props) {
    // globals
    const theme = useMantineTheme();
    const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`);
    const [colorScheme, setColorScheme] = useLocalStorage({ key: 'mantine-color-scheme', defaultValue: 'light', getInitialValueInEffect: false });
    const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
    const { classes } = useStyles();
    const location = useLocation();
    const user = useUserState();
    const setError = useErrorDispatch();
    const setUser = useUserDispatch();
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const [opened, setOpened] = useState(false);
    const [appLoading, setAppLoading] = useState(true);
    const authListenerRef = useRef(null);

    /**
     * Use effect hook to fetch data if user changes
     */
    useEffect(() => {
        // listen to auth events
        authListenerRef.current = Hub.listen('auth', async (data) => {
            if (data.payload.event === 'signIn') {
                await fetchUserDetails();
            }
        });

        // fetch user details
        fetchUserDetails().finally(() => {
            setAppLoading(false);
        });

        // unsubscribe from listeners when unmounting
        return () => {
            if (authListenerRef.current) {
                authListenerRef.current();
            }
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to get user details
     */
    const fetchUserDetails = async () => {
        // get user
        var authenticatedUser = null;
        var userInfo = null;
        try {
            authenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            userInfo = await Auth.currentUserInfo();
        }
        catch {
            // an exception is thrown if no user is logged in, in that case just return;#
            return;
        }

        // get user details
        try {
            // check if user is part of a group
            if (!authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"]) {
                throw new Error("The user is not part of any user group.");
            }
            // set new user data
            setUser({
                action: USER_SET,
                values: {
                    id: authenticatedUser.attributes.sub,
                    identityId: userInfo.id,
                    name: authenticatedUser.attributes["custom:user_name"],
                    email: authenticatedUser.attributes.email,
                    userGroups: authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"],
                    animalHomeId: authenticatedUser.attributes["custom:auth_animalHome_id"],
                }
            });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
    }

    /**
     * gets the header logo and text
     */
    function getHeader() {

        // mediaQueryLargerSm on mobile === false

        return (
            <>
                <Image
                    src={'/media/images/Tiever_Logo.png'}
                    maw={240} mx="auto" radius="md"
                />
            </>
        );
    }

    // generate footer links
    const footerItems = footerLinks.map((link) => (
        <Anchor
            as={"a"}
            color="white"
            key={link.label}
            href={link.link}
            sx={{ lineHeight: 1 }}
            style={{ textDecoration: "underline" }}
            target="_blank"
        >
            {link.label}
        </Anchor>
    ));

    /**
     * generates a menu item
     * @param {Icon} icon the icon to show 
     * @param {string} color the color for the menu item
     * @param {string} label text to show for the menu item
     * @param {string} to the route to move to
     * @returns JSX for menu item
     */
    function MainLink({ icon, color, label, to }) {
        var currentPath = location.pathname.startsWith(to);

        return (
            <UnstyledButton
                component={Link}
                to={to}
                sx={(theme) => ({
                    display: 'block',
                    width: '100%',
                    padding: theme.spacing.xs,
                    marginTop: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color: !currentPath ? 'white' : '#004289',
                    backgroundColor: currentPath ? theme.colors.blue[1] : theme.colors.transparent,

                    '&:hover': {
                        backgroundColor: theme.colors.gray[0],
                        color: '#004289'
                    },
                })}
                onClick={() => { setOpened(false); }}
            >
                <Group>
                    <ThemeIcon color={color} style={{color: '#004289'}} variant="filled">{icon}</ThemeIcon>
                    <Text  size="sm">{label}</Text>
                </Group>
            </UnstyledButton>
        );
    }

    /**
     * gets the navigation items for the navigation depending on users group
     */
    function getNavigationItems() {
        var filtered = props.navigationItems.filter(item => {
            if (!user.id && !item.routeType) {
                return true;
            }

            // if no group is set, but we do have a user, don't show it
            if (item.routeType === null) {
                return false;
            }

            // check if a user has any group set for the menu item to show
            for (let index = 0; index < user.userGroups.length; index++) {
                const userGroup = user.userGroups[index];
                if (item.routeType.includes(userGroup)) {
                    return true;
                }
            }

            return false;
        }

        );
        return filtered.map((e) => <MainLink icon={e.icon} color="#07dfb8" key={e.path} label={e.label} to={e.path}/>)
    }

    /**
     * handling sign out of the user
     */
    const signOut = async () => {
        setLoading(LOADING_SHOW);
        Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: user.id });
        await clearDataStore();
        Auth.signOut().then(async () => {
            setUser(USER_RESET);
            navigate(ROUTE_SIGNIN);
            showNotification({ message: "Erfolgreich abgemeldet.", color: 'green', icon: <Check /> });
        }).finally(() => {
            setLoading(LOADING_RESET);
        });
    }

    // if the app is loading, show loading screen
    if (appLoading) {
        return (
            <LoadingOverlay loading={true} />
        );
    }

    return (
        <>
            <MantineAppShell
                styles={(theme) => ({
                    main: {
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                        paddingTop: mediaQueryLargerSm ? "1rem !important" : null,
                        paddingRight: mediaQueryLargerSm ? "1rem !important" : "0.5rem !important",
                        paddingLeft: mediaQueryLargerSm ? null : "0.5rem !important",
                        paddingBottom: "1rem !important",
                    },
                })}
                navbarOffsetBreakpoint="sm"
                asideOffsetBreakpoint="sm"
                fixed
                navbar={
                    <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 300, lg: 300 }} style={{ background: '#004289', border: 'none' }} >
                        {mediaQueryLargerSm ?
                            <Navbar.Section>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {getHeader()}
                                </div>
                                <Divider mt={20} mb="xs" color="#07dfb8" />
                            </Navbar.Section>
                            : null
                        }

                        <Navbar.Section grow component={ScrollArea}>
                            {getNavigationItems()}
                        </Navbar.Section>
                        <Divider mt="xs" pb="xs" color="#07dfb8" />

                        {!user.id ?
                            <Navbar.Section>
                                <UnstyledButton
                                    component={Link}
                                    to={ROUTE_SIGNIN}
                                    sx={(theme) => ({
                                        display: 'block',
                                        width: '100%',
                                        padding: theme.spacing.xs,
                                        borderRadius: theme.radius.sm,
                                        color: "white",

                                        '&:hover': {
                                            backgroundColor: theme.colors.gray[0],
                                            color: '#004289'
                                        },
                                    })}
                                    onClick={() => { setOpened(false); }}
                                >
                                    <Group>
                                        <ThemeIcon color="green" variant="filled"><Login size={16} /></ThemeIcon>
                                        <Text size="sm">Anmelden</Text>
                                    </Group>
                                </UnstyledButton>
                            </Navbar.Section>
                            :
                            <Navbar.Section>
                                <UnstyledButton
                                    sx={(theme) => ({
                                        display: 'block',
                                        width: '100%',
                                        padding: theme.spacing.xs,
                                        borderRadius: theme.radius.sm,
                                        color: 'white',

                                        '&:hover': {
                                            backgroundColor: theme.colors.gray[0],
                                            color: '#004289'
                                        },
                                    })}
                                    onClick={async () => {
                                        setOpened(false);
                                        signOut();
                                    }}
                                >
                                    <Group>
                                        <ThemeIcon color="red" variant="filled"><Logout size={16} /></ThemeIcon>
                                        <Text size="sm">Abmelden</Text>
                                    </Group>
                                </UnstyledButton>
                                <Text pt="xs" pl="xs" pr="xs" color="white" size="xs">Angemeldet als:</Text>
                                <Text pl="xs" pr="xs" color="white" size="xs">{user.email}</Text>
                            </Navbar.Section>
                        }

                        <Navbar.Section>
                            <Divider mt="xs" pb="xs" color="#07dfb8" />
                            <Group position="apart" pl="xs">
                                <Text size="xs" color="white">{colorScheme === 'dark' ? "Zu hellem Design wechseln" : "Zu dunklem Design wechseln"}</Text>
                                <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={25}>
                                    {colorScheme === 'dark' ? <Sun color="gray" size={16} /> : <MoonStars color="gray" size={16} />}
                                </ActionIcon>
                            </Group>
                        </Navbar.Section>
                    </Navbar>
                }
                header={!mediaQueryLargerSm ?
                    <AppShellHeader height={70} p="md" style={{ overflow: 'hidden', backgroundColor: '#07dfb8' }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            {getHeader()}
                            <Burger
                                opened={opened}
                                onClick={() => setOpened((o) => !o)}
                                size="sm"
                                color='#004289'
                                ml="auto"
                            />
                        </div>
                    </AppShellHeader>
                    : null
                }
            >
                {props.children}
            </MantineAppShell>
            <footer
                style={{
                    marginLeft: mediaQueryLargerSm ? "300px" : "0px",
                    backgroundColor: '#004289',
                    color: 'white'
                }}
            >
                <div>
                    <Container className={classes.inner}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {getHeader()}
                        </div>
                        <Group className={classes.links}>{footerItems}</Group>
                    </Container>
                    <Container pb="md">
                        <Text size="xs">
                            © Tiever. All rights reserved.
                        </Text>
                    </Container>
                </div>
            </footer>
        </>
    )
}