import { Accordion, Button, Group, Stack, TextInput } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { useParams } from "react-router-dom";
import { Filter, Reload } from "tabler-icons-react";
import { ROUTE_SUPPORT_ANIMALHOME_EDIT, ROUTE_SUPPORT_ANIMALHOME_SHOW } from "../helpers/Routes";
import { PREDICATE_CONTAINS, PREDICATE_EQUALS } from "../helpers/Constants";
import { useUserState } from "../helpers/GlobalUserState";
import { getFilterPredicates } from "../helpers/Predicates";
import { AnimalHome } from "../models";
import { useForm } from "./Form";
import List from "./List";
import SortDirectionInput from "./SortDirectionInput";
import SortFieldInput from "./SortFieldInput";

/**
 * listing of animal homes
 * @returns JSX
 */
export default function AnimalHomeListing() {

    // globals
    const user = useUserState();
    const { animalHomeId } = useParams();

    // form hook
    const form = useForm({
        initialValues: {
            name: "",
            sortField: "name",
            sortDirection: SortDirection.ASCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        if (!user.animalHomeId && !animalHomeId) {
            // throw new Error("no animal home id provided for animal home listing");
        }

        return getFilterPredicates([
            { key: "name", value: form.values.name, type: PREDICATE_CONTAINS },
            { key: "owner", value: animalHomeId ? animalHomeId : user.animalHomeId, type: PREDICATE_EQUALS },
        ]);
    }

    return (
        <Stack>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>Filter</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label="Name"
                                    placeholder="Name"
                                    {...form.getInputProps('name')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'name', label: 'Name' },
                                        { value: 'createdAt', label: 'Erstellzeit' },
                                        { value: 'updatedAt', label: 'Aktualisierungszeit' },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group position='apart'>
                                    <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={AnimalHome}
                id="id"
                dataStructure={[
                    { key: "name", title: "Name" },
                    { key: "street", title: "Straße", type: "address" },
                    { key: "phone", title: "Telefonnummer", type: "phone" },
                    { key: "email", title: "E-Mail-Adresse", type: "email" },
                    { key: "createdAt", title: "erstellt am", type: "timestamp" },
                    { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
                ]}
                showRoute={ROUTE_SUPPORT_ANIMALHOME_SHOW}
                editRoute={ROUTE_SUPPORT_ANIMALHOME_EDIT}
                filter={getFilter()}
                sort={s => s[form.values.sortField](form.values.sortDirection)}
            />
        </Stack>
    );
}