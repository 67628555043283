import { Button, Group, NumberInput, Select, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import SelectAnimal from '../components/SelectAnimal';
import SelectEntity from '../components/SelectEntity';
import DatePickerInput from '../components/DatePickerInput';
import FileInput from '../components/FileInput';
import { FILE_TYPE_PDF } from '../helpers/Constants';
import { PRIVATE_PROTECTION_LEVEL } from '../helpers/APIHelper';

export default function FormPayment({ form, animalhomeId, animals, defaultAnimalSelectValue, defaultEntitySelectValue, animalId, entityId, entities, disableSelect }) {

    const [isDisabled, setIsDisabled] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(form.values.expensePeriod);

    useEffect(() => {
        if (disableSelect) {
            setIsDisabled(true);
        }
    }, [disableSelect]);

    if(animalId){
        form.values.animalId = animalId;
    }

    if(entityId){
        form.values.entityId = entityId;
    }

    const getMonths = () => {

        let months = [];
        let monthName;

        for(let i=1; i < 13; i++){
            if(i === 1){
                monthName = 'Monat';
            } else{
                monthName = 'Monate';
            }
            months.push({ value: ""+i+"", label: ""+i+" "+monthName+"" })
        }
        return months;
    }

    function disableFields(value) {
        let preselectedPeriod,
            preselectedEndDate;
        if(value === "donationOnce" || value === "sponsorshipOnce"){
            setIsDisabled(true);
            setSelectedPeriod('0');
            preselectedPeriod = '0';
            preselectedEndDate = null;
        } else{
            setIsDisabled(false);
            setSelectedPeriod(form.values.expensePeriod);
            preselectedPeriod = form.values.expensePeriod;
            preselectedEndDate = form.values.endDate;
        }
        form.setValues({
            ...form.values,
            expenseType: value,
            expensePeriod: preselectedPeriod,
            endDate: preselectedEndDate
        });
    }

    const reselectPeriod = (value) => {
        setSelectedPeriod(value)
        form.setValues({
            ...form.values,
            expensePeriod: value
        });
    }
    
    return (
        <Stack>
            <form onSubmit={form.onSubmit()}>
                {!animalId &&
                    <SelectAnimal form={form} animalhomeId={animalhomeId} data={animals} defaultValue={defaultAnimalSelectValue}/>
                }

                {!entityId &&
                    <SelectEntity form={form} animalhomeId={animalhomeId} data={entities} defaultValue={defaultEntitySelectValue} withAsterisk={true}/>
                }

                {/* expenseType */}
                <Select
                    label="Zahlungsart"
                    placeholder="Zahlungsart"
                    data={[
                        { value: "donationOnce", label: "Einmalige Spende" },
                        { value: "donationRecurent", label: "Wiederkehrende Spende" },
                        { value: "membershipFee", label: "Mitgliedsbeitrag" },
                        { value: "sponsorshipOnce", label: "Einmalige Patenschaft" },
                        { value: "sponsorshipRecurent", label: "Wiederkehrendes Sponsoring" },
                    ]}
                    {...form.getInputProps("expenseType")}
                    onChange={(e) => disableFields(e)}
                    pt="xs"
                    withAsterisk
                />

                {/* expensePeriod */}
                <Select
                    label="Intervall"
                    placeholder="Intervall"
                    data={[
                        { value: "0", label: "Einmalig" },
                        ...getMonths()
                    ]}
                    {...form.getInputProps("expensePeriod")}
                    onChange={(e) => reselectPeriod(e)}
                    value={form.values.expensePeriod || selectedPeriod}
                    disabled={isDisabled}
                    pt="xs"
                    withAsterisk
                />

                <DatePickerInput
                    label="Startdatum"
                    placeholder="Startdatum"
                    form={form}
                    inputKey="startDate"
                    pt="xs"
                    withAsterisk
                />

                <DatePickerInput
                    label="Enddatum"
                    placeholder="Enddatum"
                    form={form}
                    inputKey="endDate"
                    disabled={isDisabled}
                    pt="xs"
                    withAsterisk={!isDisabled}
                />

                {/* ammount */}
                <NumberInput
                    decimalSeparator=","
                    thousandsSeparator="."
                    label="Betrag"
                    placeholder="Betrag"
                    precision={2}
                    min={0}
                    defaultValue={0.00}
                    parser={(value) => value.replace(/€\s?/g, '')}
                    formatter={(value) =>
                        !Number.isNaN(parseFloat(value))
                            ? `€ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
                            : '€ '
                    }
                    {...form.getInputProps("amount")}
                    pt="xs"
                    withAsterisk
                />

                {/* scanned document uploader */}
                <FileInput
                    initFiles={form.values.scannedDocuments || []}
                    multiple={true}
                    label="Weitere Dokumente"
                    description="Wählen Sie Dokumente zum Hochladen aus."
                    path="scannedDocuments"
                    form={form}
                    withAsterisk={false}
                    fileType={FILE_TYPE_PDF}
                    storagePrefix="pdfs"
                    fileProtectionLevel={PRIVATE_PROTECTION_LEVEL}
                    identityId={animalhomeId}
                    pt="xs"
                />

                <Group position="right" mt="md">
                    <Button type="submit">Speichern</Button>
                </Group>
            </form>
        </Stack>
    )
}
