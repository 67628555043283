// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const EntityStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const ActionType = {
  "FOLLOW_UP_CHECK": "followUpCheck",
  "TEMPORARILY_ADOPT": "temporarilyAdopt",
  "PERMANENTLY_ADOPT": "permanentlyAdopt",
  "BRING_IN": "bringIn",
  "SEND_EMAIL": "sendEmail",
  "DOWNLOAD_PDF": "downloadPdf",
  "CASTRATION": "castration",
  "VACCINATION": "vaccination",
  "DOCTOR_APPOINTMENT": "doctorAppointment",
  "ANIMAL_DEATH": "animalDeath",
  "TREATMENT_START": "treatmentStart",
  "TREATMENT_END": "treatmentEnd",
  "DONATION_RECEIVED": "donationReceived"
};

const { AnimalHome, AnimalHomeUser, SinglePersonEntity, PersonGroupEntity, PersonGroupIndividual, FormCheckAnimal, FormAdoptTemporarily, FormAdoptPermanently, FormBringInAnimal, Animals, AnimalsDocuments, Donations, Actions, AnimalBreeds, AnimalRaces, ApiToken } = initSchema(schema);

export {
  AnimalHome,
  AnimalHomeUser,
  SinglePersonEntity,
  PersonGroupEntity,
  PersonGroupIndividual,
  FormCheckAnimal,
  FormAdoptTemporarily,
  FormAdoptPermanently,
  FormBringInAnimal,
  Animals,
  AnimalsDocuments,
  Donations,
  Actions,
  AnimalBreeds,
  AnimalRaces,
  ApiToken,
  EntityStatus,
  ActionType
};