import { Accordion, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { DogBowl, Plus } from "tabler-icons-react";
import { ANIMAL, ANIMALS, CREATE } from "../../helpers/Constants";
import { ROUTE_ANIMALHOME_ANIMALS_CREATE_ANIMAL, ROUTE_ANIMALHOME_ANIMALS_EDIT_ANIMAL, ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL } from "../../helpers/Routes";
import ListingTemplate from "../../components/ListingTemplate";
import { useUserState } from "../../helpers/GlobalUserState";
import { PREDICATE_EQUALS } from "../../helpers/Predicates";
import { Animals } from "../../models";
import { SortDirection } from "aws-amplify";
import { animalsDataStructure } from "../../components/ListingDataStructures";

export default function PageAnimalHomeAnimals() {
    const navigate = useNavigate();
    const user = useUserState();

    const filterPredicates = [
      { key: "owner", value: user.animalHomeId, type: PREDICATE_EQUALS },
    ]
  
    const sortFieldInputData = [
      { value: 'createdAt', label: 'Erstellzeit' },
      { value: 'updatedAt', label: 'Aktualisierungszeit' },
    ]

    return (
        <Stack>
            <Group position="apart">
                <Title>{ANIMALS}</Title>
                <Group>
                    <Button
                        color="green"
                        leftIcon={<Plus />}
                        onClick={() => navigate(ROUTE_ANIMALHOME_ANIMALS_CREATE_ANIMAL)}
                    >
                        {ANIMAL} {CREATE}
                    </Button>
                </Group>
            </Group>
            <Divider />

            <Accordion variant="separated" defaultValue="animals">
                <Accordion.Item value="animals">
                    <Accordion.Control icon={<DogBowl />}>Tiere</Accordion.Control>
                    <Accordion.Panel>
                    <ListingTemplate
                        animalhomeId={user.animalHomeId}
                        dataStructure={animalsDataStructure}
                        editRoute={ROUTE_ANIMALHOME_ANIMALS_EDIT_ANIMAL}
                        filterPredicates={filterPredicates}
                        listTypeModel={Animals}
                        showRoute={ROUTE_ANIMALHOME_ANIMALS_VIEW_ANIMAL}
                        sortDirection={SortDirection.DESCENDING}
                        sortField="createdAt"
                        filterFields={['name', 'raceID', 'breedID']}
                        sortFieldInputData={sortFieldInputData}
                    />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Stack>
    );
}
