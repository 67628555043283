import { useParams } from "react-router-dom";
import { ROUTE_ANIMALHOME_FORMULARS_EDIT_BRING_IN_ANIMAL } from "../../helpers/Routes";
import { FormBringInAnimal } from "../../models";
import FormViewTemplate from "../../components/FormViewTemplate";
import { FORM_BRING_IN_ANIMAL } from "../../helpers/Constants";
import { fieldsForm } from "../../helpers/FormFieldsProcessor";

/**
 * Page used to View the details of an existing Bring in Animal form
 * @returns JSX
 */
export default function PageAnimalHomeFormsViewBringInAnimal() {

  const { id } = useParams();

  const formBringInAnimalFields = fieldsForm(FormBringInAnimal);

  return (
    <FormViewTemplate
      id={id}
      formModel={FormBringInAnimal}
      formName={FORM_BRING_IN_ANIMAL}
      formFields={formBringInAnimalFields}
      editFormRoute={ROUTE_ANIMALHOME_FORMULARS_EDIT_BRING_IN_ANIMAL}
    />
  );
}
